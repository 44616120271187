const Swal = require('sweetalert2');
const axios = require('axios');
const envConfig = require('../config/envConfig');

exports.apiRequest = async (method, url, data, source = 'web', showLoader = true) => {
	return await doApiRequest(method, url, data, source, showLoader);
}

const doApiRequest = async (method, url, data, source = 'web', showLoader = true) => {
	var loader = document.getElementById('loader');
	var root = document.getElementById('root');
	if (showLoader) {
		loader.style.display = 'flex';
		root.style.display = 'none';
	}

	let headers = {};
	if ((localStorage.getItem('token') && localStorage.getItem('token') != "undefined") || (localStorage.getItem('delivery_token') && localStorage.getItem('delivery_token') != "undefined")) {
		headers = {
			"x-access-token" : source == 'web' ? localStorage.getItem('token') : localStorage.getItem('delivery_token'),
			"source": source
		}
		data.userId = source == 'web' ? localStorage.getItem('userId') : localStorage.getItem("delivery_userId");
	}
	return await axios({
		method: method,
		url: envConfig.api_url + url,
		data: data,
		headers: headers
	}).then((result) => {
		if (showLoader) {
			// Hide the loader after the API request completes
			loader.style.display = 'none';
			root.style.display = 'block';
		}

		if (source == "web" && result['headers']['access-token'] && localStorage.getItem('token') && localStorage.getItem('token') != result['headers']['access-token']) {
			localStorage.setItem('token', result['headers']['access-token']);
		} else if (source != "web" && result['headers']['access-token'] && localStorage.getItem('delivery_token') && localStorage.getItem('delivery_token') != result['headers']['access-token']) {
			localStorage.setItem('delivery_token', result['headers']['access-token']);	
		}
		if (result['data']['code'] == "failed") {
			Swal.fire({
				text: result['data']['error'],
				icon: 'error',
				confirmButtonText: 'OK'
			});
		}
		return result;
	}).catch((err) => {
		if (showLoader) {
			// Hide the loader in case of error
			loader.style.display = 'none'; // Hide loader
			root.style.display = 'block';
		}
		
		if (err.response && (err.response.status == 403 || err.response.status == 401)) {
			let path = '/login';
			Swal.fire({
				text: err.response.statusText,
				icon: 'error',
				confirmButtonText: 'OK'
			});
			if (source == "delivery") {
				localStorage.removeItem('delivery_token');
				localStorage.removeItem('delivery_userId');
				localStorage.removeItem('delivery_userType');
				path = '/delivery/login';
			} else {
				localStorage.removeItem('token');
				localStorage.removeItem('userId');
				localStorage.removeItem('userType');
				localStorage.removeItem('cartItems');
			}
			window.location.href = path;
		} else if (err.message) {
			return err.message;
		}
		return err;
	});
}

exports.getSystemConfig = async (key, source = 'web', noAuth = false) => {
	let data = {
		'action': 'read',
		'key': key,
		'noAuth': noAuth
	}
	var response = await doApiRequest('post', '/admin/crud-system-config', data, source);
	if (response.status == 200) {
		if (response.data.code == 'success') {
			return response.data.data ? response.data.data[0]['value'] : null;
		} else {
			Swal.fire({
				text: response.data.error,
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return null;
		}
	} else {
		Swal.fire({
			text: response.data.error,
			icon: 'error',
			confirmButtonText: 'OK'
		});
		return null;
	}
}
exports.getInvoiceData = async (sellerUserId, orderId, invoiceType,isReturn=0) => {
	let data = {
		"user_id": sellerUserId,
		"order_id": orderId,
		"invoice_type": invoiceType,
		"is_return": isReturn
	}
	var response = await doApiRequest('post', '/orders/generate-invoice', data);
	if (response.status == 200) {
		if (response.data.code == 'success') {
			return response.data.data ? response.data.data : []
		} else {
			Swal.fire({
				text: response.data.error,
				icon: 'error',
				confirmButtonText: 'OK'
			});
			return [];
		}
	} else {
		Swal.fire({
			text: response.data.error,
			icon: 'error',
			confirmButtonText: 'OK'
		});
		return [];
	}
}

exports.alert = (message, type = 'success') => {
	Swal.fire({
		text: message,
		icon: type,
		confirmButtonText: 'OK'
	});
}

exports.parseValue = (value, type) => {
	if (value === null || value === undefined || value === '') {
	  return 0.00;
	}
	const stringValue = typeof value === 'string' ? value : String(value);
  
	if (type === "float") {
	  return parseFloat(parseFloat(stringValue.replace(",","")).toFixed(2));
	}
	else if (type === "int") {
	  return parseInt(parseInt(stringValue.replace(",","")).toFixed(2));	
	}
	else if (isNaN(value)) {
	  return 0.00;
	}
	else {
	  return value;
	}
}

exports.uploadOrderImages = async (imagesToUpload, orderItemId, uploadVia, type, showMessageAlert = 0) => {
	if (imagesToUpload.length > 0) {
		var fileData = new FormData();
		for (const file of imagesToUpload) {
			fileData.append('order_images', file);
		}
		fileData.append('order_item_id', orderItemId);
		fileData.append('type', type);
		fileData.append('uploaded_via', uploadVia);
		let response = await doApiRequest('post', '/orders/upload-update-order-images', fileData, 'deliver');
		if (showMessageAlert) {
			Swal.fire({
				text: response?.message || 'Image Uploaded',
				icon: 'success',
				confirmButtonText: 'OK'
			});
		}
	} else {
		Swal.fire({
			text: 'No file selected',
			icon: 'error',
			confirmButtonText: 'OK'
		});
	}
}

exports.fetchOrderImages = async (orderItemId) => {
	let data = {
		'order_item_id': orderItemId
	}
	return await doApiRequest('post', '/orders/fetch-order-images', data);
}