import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import './business.css';
import { ToWords } from 'to-words';
import { parseValue } from '../utils/Common';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

function GenerateInvoice() {
    const [invoiceData, setInvoiceData] = useState([]);
    const location = useLocation();
    var counter = 0;

    useEffect(() => {
        setInvoiceData(location.state?.invoiceData || {});
    }, [location.state]);

    const downloadPdfDocument = (invoice) => {
        const input = document.getElementById(`invoice-${invoice.invoice_no}`);
        if (!input) return;
    
        const a4Width = 595.28;
        const a4Height = 841.89;
        const padding = 0;
    
        html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: true,
            windowWidth: (a4Width + padding) * 2,
            windowHeight: a4Height * 2
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('p', 'pt', 'a4');
    
            const imgWidth = a4Width - padding * 2;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
            if (imgHeight <= a4Height) {
                pdf.addImage(imgData, 'JPEG', padding, 0, imgWidth, imgHeight);
            } else {
                let y = 0;
                while (y < imgHeight) {
                    pdf.addImage(imgData, 'JPEG', padding, -y, imgWidth, imgHeight);
                    y += a4Height;
                    if (y < imgHeight) pdf.addPage();
                }
            }
    
            pdf.save(`invoice-${invoice.invoice_no}.pdf`);
        });
    }


    const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        }
    });

    const convertToWords = (amount) => {
        const numAmount = parseFloat(amount);
        if (isNaN(numAmount)) return '';
        return toWords.convert(numAmount, { currency: true });
    };
    if (invoiceData && invoiceData.length > 0) {
        return (
            <div id='invoice' className='mb-5'>
            {
                invoiceData.map((invoiceData,index) => {
                    counter = 0;
                    if (invoiceData?.invoice?.order?.user) {
                        return (
                            <>
                            <div key={index} className='mb-5' id={`invoice-${invoiceData.invoice.invoice_no}`}>
                                <div className='bill-customer' id="testId">
                                    <div className='invoice'>
                                        <div className='invoice-head col-md-12'>
                                        <h2>{"Tax Invoice / Bill of Supply"}</h2>
                                        </div>
                                        <div className='customer-details'>
                                            <div className='customer-details-head'>
                                                <h2>Customer Details:</h2>
                                            </div>
                                            <div className='bill-details row'>
                                                <div className='billing-address col-md-6'>
                                                    <div className='billing-address-head'><h4>Billing Address :</h4></div>
                                                    <div className='billing-address-details'>
                                                        <div className="user-address row d-flex align-items-center">
                                                        <label className="col-4  col-md-6">Name :</label>
                                                        <div className="col-8  col-md-6 text-truncate">{invoiceData.invoice.order.user.first_name + " " + invoiceData.invoice.order.user.last_name}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Address :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.user_address?.address || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Pincode :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.user_address?.pincode || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>State :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.user_address?.state || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Pan No :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.user?.pan_no || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Email :</label>
                                                            <div className= ' col-8 col-md-6 text-break word-wrap'>{invoiceData.invoice.order.user.email || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Mobile No :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.user.mobile_phone || "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='shipping-address  col-md-6'>
                                                    <div className='shipping-address-head'><h4>Shipping Address :</h4></div>
                                                    <div className='shipping-address-details'>
                                                        <div className="user-address row d-flex align-items-center">
                                                        <label className="col-4  col-md-6">Name :</label>
                                                        <div className="col-8  col-md-6 text-truncate">{invoiceData.invoice.order.user.first_name + " " + invoiceData.invoice.order.user.last_name}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Address :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.user_address?.address || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Pincode :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.user_address?.pincode || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>State :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.user_address?.state || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Email :</label>
                                                            <div className= ' col-8 col-md-6 text-break word-wrap'>{invoiceData.invoice.order.user.email || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Mobile No :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.user.mobile_phone || "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='customer-details'>
                                            <div className='billing-address-head'>
                                                <h4>Supply by:</h4>
                                            </div>
                                            <div className='bill-details row'>
                                                <div className='billing-address   col-md-6'>
                                                    <div className='billing-address-details'>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Name :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData?.invoice.seller?.user_business?.business_name}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Address :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData?.invoice.invoice_type == "logistics" ? invoiceData?.invoice.logistics_order?.seller_service?.seller_address?.address || invoiceData.invoice.seller?.seller_addresses[0]?.address : invoiceData.orderItems[0]?.product_item?.product?.seller_address?.address || invoiceData.orderItems[0]?.seller_service?.seller_address?.address || invoiceData.orderItems[0]?.offline_sale_product_datum?.offline_sale?.seller_address?.address || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Pincode :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData?.invoice.invoice_type == "logistics" ? invoiceData?.invoice.logistics_order?.seller_service?.seller_address?.pincode || invoiceData.invoice.seller?.seller_addresses[0]?.pincode : invoiceData.orderItems[0]?.product_item?.product?.seller_address?.pincode || invoiceData.orderItems[0]?.seller_service?.seller_address?.pincode || invoiceData.orderItems[0]?.offline_sale_product_datum?.offline_sale?.seller_address?.pincode || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>GSTIN :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData?.invoice.invoice_type == "logistics" ? invoiceData?.invoice.logistics_order?.seller_service?.seller_address?.gst_no || invoiceData.invoice.seller?.seller_addresses[0]?.gst_no : invoiceData.orderItems[0]?.product_item?.product?.seller_address?.gst_no || invoiceData.orderItems[0]?.seller_service?.seller_address?.gst_no || invoiceData.orderItems[0]?.offline_sale_product_datum?.offline_sale?.seller_address?.gst_no || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>State :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData?.invoice.invoice_type == "logistics" ? invoiceData?.invoice.logistics_order?.seller_service?.seller_address?.state || invoiceData.invoice.seller?.seller_addresses[0]?.state : invoiceData.orderItems[0]?.product_item?.product?.seller_address?.state || invoiceData.orderItems[0]?.seller_service?.seller_address?.state || invoiceData.orderItems[0]?.offline_sale_product_datum?.offline_sale?.seller_address?.state || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Pan No :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData?.invoice.invoice_type == "logistics" ? invoiceData?.invoice.logistics_order?.seller_service?.user?.user_business?.pan_no || invoiceData.invoice.seller?.user_business?.pan_no : invoiceData.invoice.seller?.user_business?.pan_no || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Place of supply :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData?.invoice.invoice_type == "logistics" ? invoiceData?.invoice.logistics_order?.for_seller?.state : invoiceData.orderItems[0]?.product_item?.product?.seller_address?.state || invoiceData.orderItems[0]?.seller_service?.seller_address?.state || invoiceData.orderItems[0]?.offline_sale_product_datum?.offline_sale?.seller_address?.state || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Place of delivery :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.user_address?.state || "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='shipping-address   col-md-6'>
                                                    <div className='shipping-address-details'>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Invoice Number :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.invoice_no || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Invoice Date :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.created_at || "N/A"}</div>
                                                        </div>
                                                         
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Order ID (SRRR Number) :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.order.order_id || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Mode of Payment  :</label>
                                                            <div className= ' col-8 col-md-6'>Online</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Amount received  :</label>
                                                            <div className= ' col-8 col-md-6'>Rs. {parseValue(invoiceData.invoice.price, 'float') || 0}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className= ' col-4 col-md-6'>Date & Time :</label>
                                                            <div className= ' col-8 col-md-6'>{invoiceData.invoice.created_at || "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='invoice-table'>
                                            <div className='invoice-table-head'>
                                                <h4>Goods wise Details:</h4>
                                            </div>
                                            <div className='invoice-table-body'>
                                                <table className="orders-table report-margin table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Product</th>
                                                            <th>HSN/SAC Code</th>
                                                            <th>Qty</th>
                                                            <th>Rate</th>
                                                            <th>Sale Amount</th>
                                                            <th>Disc</th>
                                                            <th>Taxable Supply</th>
                                                            <th>IGST
                                                                <th>Rate %</th>
                                                                <th>Amount</th>
                                                            </th>
                                                            <th>CGST
                                                                <th>Rate %</th>
                                                                <th>Amount</th>
                                                            </th>
                                                            <th>SGST / UTGST
                                                                <th>Rate %</th>
                                                                <th>Amount</th>
                                                            </th>
                                                            <th>TOTAL
                                                                <th>GST</th>
                                                                <th>Invoice Amount</th>
                                                                <th>R. Off</th>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            invoiceData?.invoice.invoice_type == "logistics" || invoiceData?.invoice.invoice_type == "logistics_seller_specific_item" ?
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>Logistics</td>
                                                                        <td>{invoiceData?.invoice.logistics_order?.seller_service?.service_sku || "NA"}</td>
                                                                        <td>NA</td>
                                                                        <td>{parseValue(invoiceData?.invoice.logistics_order?.order_price || 0,'float')}</td>
                                                                        <td>{parseValue(invoiceData?.invoice.logistics_order?.order_price || 0,'float')}</td>
                                                                        <td>{parseValue(0,'float')}</td>
                                                                        <td>{parseValue(invoiceData?.invoice.logistics_order?.taxable_value || 0,'float')}</td>
                                                                        <td>
                                                                            <td>{parseValue(invoiceData?.invoice.logistics_order?.igst_percent || 0,'float')}</td>
                                                                            <td>{parseValue(invoiceData?.invoice.logistics_order?.igst || 0,'float')}</td>
                                                                        </td>
                                                                        <td>
                                                                            <td>{parseValue(invoiceData?.invoice.logistics_order?.cgst_percent || 0,'float')}</td>
                                                                            <td>{parseValue(invoiceData?.invoice.logistics_order?.cgst || 0,'float')}</td>
                                                                        </td>
                                                                        <td>
                                                                            <td>{parseValue(invoiceData?.invoice.logistics_order?.sgst_percent || invoiceData?.invoice.logistics_order?.utgst_percent || 0,'float')}</td>
                                                                            <td>{parseValue(invoiceData?.invoice.logistics_order?.sgst || invoiceData?.invoice.logistics_order?.utgst || 0,'float')}</td>
                                                                        </td>
                                                                        <td>
                                                                            <td>
                                                                            {
                                                                                parseValue(invoiceData?.invoice.logistics_order?.igst || 0, 'float') +
                                                                                parseValue(invoiceData?.invoice.logistics_order?.cgst || 0, 'float') +
                                                                                parseValue(invoiceData?.invoice.logistics_order?.sgst || invoiceData?.invoice.logistics_order?.utgst || 0, 'float')
                                                                            }
                                                                            </td>    
                                                                            <td>{parseValue(invoiceData?.invoice.logistics_order?.order_price || 0,'float')}</td>
                                                                            <td>
                                                                                {
                                                                                    parseValue(
                                                                                    parseValue(invoiceData?.invoice.logistics_order?.order_price || 0,'float') - 
                                                                                    parseValue(invoiceData?.invoice.logistics_order?.taxable_value || 0,'float') - 
                                                                                    parseValue(invoiceData?.invoice.logistics_order?.igst || 0,'float') -
                                                                                    parseValue(invoiceData?.invoice.logistics_order?.cgst || 0,'float') -
                                                                                    parseValue(invoiceData?.invoice.logistics_order?.sgst || invoiceData?.invoice.logistics_order?.utgst || 0,'float'), 'float')
                                                                                }
                                                                            </td>
                                                                        </td>
                                                                    </tr>
                                                            :
                                                                invoiceData.orderItems.map((data) => {
                                                                    counter++;
                                                                    return (
                                                                            <tr key={counter}>
                                                                            <td>{counter}</td>
                                                                            <td>{data.product_item ? data.product_item.product?.name || "N/A" : data.seller_service ? data.seller_service?.service_name || "N/A" : data.offline_sale_product_datum?.product_description || "N/A"}</td>
                                                                            <td>{data.product_item?.hsn_code || data.seller_service?.hsn_code || data.offline_sale_product_datum?.hsn_code || "N/A"}</td>
                                                                            <td>{data.offline_sale_product_datum?.quantity || data.order_quantity || "N/A"}</td>
                                                                            <td>{parseValue(data.product_item?.price || data.seller_service?.price || data.offline_sale_product_datum?.price || 0, 'float')}</td>
                                                                            <td>{parseValue(data.order_price || data.offline_sale_product_datum?.sale_amount || 0,'float')}</td>
                                                                            <td>{parseValue(data.offline_sale_product_datum?.discount || 0,'float')}</td>
                                                                            <td>{parseValue( data.taxable_amount || data.offline_sale_product_datum?.taxable_supply || 0,'float')}</td>
                                                                            <td>
                                                                                <td>{parseValue( data.igst_percent || data.offline_sale_product_datum?.igst_rate,'float')}</td>
                                                                                <td>{parseValue( data.igst || data.offline_sale_product_datum?.igst_amount,'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{parseValue( data.cgst_percent  || data.offline_sale_product_datum?.cgst_rate,'float')}</td>
                                                                                <td>{parseValue(data.cgst  || data.offline_sale_product_datum?.cgst_amount,'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{ parseValue(data.sgst_percent || data.offline_sale_product_datum?.sgst_utgst_rate,'float')}</td>
                                                                                <td>{parseValue( data.sgst || data.offline_sale_product_datum?.sgst_utgst_amount,'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                            <td>
                                                                            {parseValue(
                                                                                parseValue(data?.igst || 0, 'float') +
                                                                                parseValue(data?.cgst || 0, 'float') +
                                                                                parseValue(data?.sgst || 0, 'float') +
                                                                                parseValue(data?.utgst || 0, 'float'),
                                                                                'float'
                                                                            )}
                                                                            </td>
                                                                            <td>
                                                                            <td>{parseValue(data.order_price || data.offline_sale_product_datum?.sale_amount || 0,'float')}</td>
                                                                            <td>
                                                                                    {
                                                                                        parseValue(parseValue(data.order_price || 0, 'float')-
                                                                                        parseValue(data.taxable_amount || 0, 'float')-
                                                                                        parseValue(data.igst || 0, 'float')-
                                                                                        parseValue(data.cgst || 0, 'float')-
                                                                                        parseValue(data.sgst || data.utgst || 0, 'float'), 'float')
                                                                                    } 
                                                                            </td>
                                                                            </td>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                        <tr>
                                                            <td colSpan="7"></td>
                                                            <td colSpan="10">
                                                                <tr>
                                                                    <td><b>Total</b></td>
                                                                    <td>Rs. {parseValue(invoiceData.invoice.price|| 0,  'float')}</td>
                                                                    <td>{convertToWords(parseValue(invoiceData.invoice.price || 0, 'float'))}</td>

                                                                </tr>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='footer-data row'>
                                            <b className='col-md-6'>Whether GST payable under reverse charge  ?</b>
                                            <h6 className='col-md-6'>No</h6>
                                        </div>
                                        <div className='footer-data row'>
                                            <div className='col-md-12 row'>
                                                <h5 className='col-md-6'>Remarks:</h5>
                                                <div className='col-md-6 row'>
                                                    <label className='col-md-2'>for</label>
                                                    <div className='col-md-4'>
                                                        <b>
                                                            {invoiceData?.invoice.invoice_type == "logistics" ? invoiceData?.invoice.seller?.first_name + " " + invoiceData?.invoice.seller?.middle_name + " " + invoiceData?.invoice.seller?.last_name: invoiceData.invoice.seller?.user_business?.business_name || "N/A"}
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='footer-data row'>
                                            <div className='col-md-12 row'>
                                                <h5 className='col-md-6'>Remarks:</h5>
                                                <div className='col-md-6'>
                                                    <div className='Sign-image-vendor'>Signed</div>
                                                    <div className='Authorised-Signatory'>Authorised Signatory</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => downloadPdfDocument(invoiceData?.invoice)} className="btn btn-primary mt-3">Download Invoice</button>

                            </>
                        );
                    } else {
                        return 'No Invoice';
                    }
                })
            }
            {/* <button onClick={downloadPdfDocument}>Download Pdf</button> */}
            </div>
        )
    }
}

export default GenerateInvoice;
