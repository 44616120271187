import React, { useState, useEffect, useCallback } from 'react';
import { useCSVReader, useCSVDownloader } from 'react-papaparse';
import { useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';
import {useDropzone} from 'react-dropzone';
import * as XLSX from 'xlsx';
// import servicesExcel from '../../../public/services.xlsx';
const common = require('../../utils/Common');

const SellerAddServices = () => {
    let navigate = useNavigate();
    const [fileName, setFileName] = useState("");
    const { CSVReader } = useCSVReader();
    const { CSVDownloader, Type } = useCSVDownloader();
    const [upload, setUpload] = useState(0);
    const [finalData, setFinalData] = useState([]);
    const [serviceCategory, setServiceCategory] = useState([]);
    const [serviceSubCategory, setServiceSubCategory] = useState([]);
    const [userAddress, setUserAddress] = useState([]);
    const [showStates, setShowStates] = useState(false);
    const [panIndia, setPanIndia] = useState(false);
    const [panIndiaPincodes, setPanIndiaPincodes] = useState(null);
    const [showPincodes, setShowPincodes] = useState(false);
    const [pincodes, setPincodes] = useState({
        allPincodes: [],
        filterPincodes: [],
        msgPincode: "Type first 3 letters to search",
        selectedPincodes: []
    });
    const [states, setStates] = useState({
        allStates: [],
        selectedStates: [],
        msgStates: "No States Found"
    });
    
    const uploadData = async () => {
        const serviceType = $('#service_type').val();
        const serviceCategory = $('#service_category').val();
        const servicesubcategory = $('#service_sub_category').val();
        const gstNo = $('#gst_no').val();
        const deliveryType = $('#delivery_type').val();
    
        if (!serviceType || !serviceCategory || !servicesubcategory || !gstNo || !deliveryType || (showStates && states.selectedStates.length == 0) || (showPincodes && pincodes.selectedPincodes.length == 0)) {
            common.alert("Please fill above fields first.", 'error');
            return;
        }
        let data = {
            'action': 'create',
            'finalData': finalData
        }
        let response = await common.apiRequest('post', '/seller/crud-seller-services', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            navigate('/seller/services');
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const getServiceCategories = async (parent_category_id = 0) => {
		var parameters = {
            'action': 'read',
            'parent_category_id': parent_category_id
        };
        let response = await common.apiRequest('post', '/admin/crud-seller-service-category', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        if (parent_category_id == 0) {
            setServiceCategory(data);
        } else {
            setServiceSubCategory(data);
        }
    }

    const searchPincode = async (value) => {
        if (value.length > 2) {
          var parameters = {
            'action': 'read',
            'value': value
          }
          let response = await common.apiRequest('post', '/admin/crud-pincodes', parameters, 'web', false);
          let data = (response.data && response.data.data) ? response.data.data : [];
          const items = data;
          if (items.length == 0) {
              setPincodes({...pincodes, filterPincodes: [], msgPincode: "No pincode found"});
          } else {
              setPincodes({...pincodes, filterPincodes: items, msgPincode: "Type first 3 letters to search"});
          }
        } else {
          setPincodes({...pincodes, filterPincodes: [], msgPincode: "Type first 3 letters to search"});
        }
    }

    const getAllStatesAndPincodes = async (type) => {
        var parameters = {
          'action': 'read',
          'type': type
        }
        let response = await common.apiRequest('post', '/admin/crud-pincodes', parameters, 'web', false);
        let data = (response.data && response.data.data) ? response.data.data : [];
        if (type == "pan_india") {
            const dataArray = data.replace(/^[-]+|[-]+$/g, '').split('--');
            setPanIndiaPincodes(dataArray);
        } else {
          if (data.length == 0) {
              setStates({...states, allStates: [], msgPincode: "No pincode found", selectedStates: []});
          } else {
              setStates({...states, allStates: data, msgPincode: null, selectedStates: []});
          }
        }
    }

    const selectPincode = (selectedList, selectedItem) => {
        var selectedPincodes = pincodes.selectedPincodes;
        selectedPincodes.push(selectedItem.id);
        setPincodes({...pincodes, selectedPincodes: selectedPincodes});
    }

    const removePincode = (selectedList, removedItem) => {
        var selectedPincodes = pincodes.selectedPincodes;
        selectedPincodes.splice(selectedPincodes.indexOf(removedItem.id));
        // selectedPincodes.splice(selectedPincodes.indexOf(removedItem.id), 1);
        setPincodes({...pincodes, selectedPincodes: selectedPincodes});
    }

    const selectState = (selectedList, selectedItem) => {
        var selectedStates = states.selectedStates;
        let ids = selectedItem.id.split(',');
        ids.forEach(id => {
          selectedStates.push(id);
        });
        setStates({...states, selectedStates: selectedStates});
    }

    const removeState = (selectedList, removedItem) => {
        var selectedStates = states.selectedStates;
        let ids = removedItem.id.split(',');
        ids.forEach(id => {
        selectedStates.splice(selectedStates.indexOf(id));
        });
        setStates({...states, selectedStates: selectedStates});
    }

    const getBusinessUserAddress = async () => {
        var parameters = {
            'action': 'read',
            'seller_id': localStorage.getItem('userId'),
        };
        let response = await common.apiRequest('post', '/users/seller-address', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        if (data.length == 0) {
            common.alert("Please add seller business address first", 'error');
            navigate('/seller/Seller_Address');
        }
        setUserAddress(data);
    }

    const selectDeliveryType = async () => {
        let type = $('#delivery_type').val();
        if (type == "") {
          return;
        }
        if (type == "state_wise") {
          // call state wise api
          await getAllStatesAndPincodes('states');
          setShowStates(true);
          setShowPincodes(false);
          setPanIndia(false);
        } else if (type == "pincode_wise") {
          setPincodes({...pincodes, selectedPincodes: []});
          setShowPincodes(true);
          setShowStates(false);
          setPanIndia(false);
        } else if (type == "pan_india") {
          await getAllStatesAndPincodes('pan_india');
          setPanIndia(true);
          setShowPincodes(false);
          setShowStates(false);
        }
    }

    useEffect(()=>{
        getServiceCategories();
        getBusinessUserAddress();
    },[]);

    const onDrop = useCallback(acceptedFiles => {
        const serviceType = $('#service_type').val();
        const serviceCategory = $('#service_category').val();
        const servicesubcategory = $('#service_sub_category').val();
        const gstNo = $('#gst_no').val();
        const deliveryType = $("#delivery_type").val();
    
        if (!serviceType || !serviceCategory || !servicesubcategory || !gstNo || !deliveryType) {
            common.alert("Please fill above fields first.", 'error');
            return;
        }

        if (deliveryType == "state_wise" && (!states.selectedStates || states.selectedStates.length === 0)) {
            common.alert('Please select a state.', 'error');
            return;
        }
          
        // Check if pincodes are required and selected
        if (deliveryType == "pincode_wise" && (!pincodes.selectedPincodes || pincodes.selectedPincodes.length === 0)) {
            common.alert('Please select a pincode.', 'error');
            return;
        }
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (evt) => {
                /* Parse data */
                var data = evt.target.result;
                const wb = XLSX.read(data, {type:'array'});
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                data = XLSX.utils.sheet_to_json(ws);
                let hasEmpty = false;
                if ($('#service_type').val() == 'normal') {
                    let mandatoryFields = ['service_name', 'service_description', 'service_sku', 'service_by', 'hsn_code', 'gst_rate', 'price', 'terms_of_parts_no_extra_cost', 'legal_disclaimer', 'warranty_or_gurantee', 'use_utility_of_services', 'after_sale_services'];
                    data.forEach(obj => {
                        const keys = Object.keys(obj);
                        mandatoryFields.map(field => {
                            if (!keys.includes(field)) {
                                hasEmpty = true;
                                common.alert("Please fill value for " + field, 'error');
                                return;
                            }
                        });
                        obj.taxable_value = obj.price && obj.gst_rate ? ((obj.price/(obj.gst_rate + 100)) * 100).toFixed(2) : 0;
                    });
                }
                if (!hasEmpty) {
                    /* Update state */
                    setFinalData({seller_id: localStorage.getItem('userId'), service_type: $('#service_type').val(), service_category: $('#service_category').val(),seller_address_gst:$('#gst_no').val(), service_sub_category: $('#service_sub_category').val(), selectedPincodes : ($("#delivery_type").val() == "pan_india" ? panIndiaPincodes : ($("#delivery_type").val() == "state_wise" ? states.selectedStates : pincodes.selectedPincodes)), 'servicesData' : data});
                    setUpload(1);
                } else {
                    return;
                }
            }
            reader.readAsArrayBuffer(file)
        })
    }, [states, pincodes, panIndiaPincodes, showStates, showPincodes]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: false});

    return (
        <>
        <div className='service-upload-section'>
        <div className='row mt-5'>
                <div className='col-md-12'>
                    <h1><center>Add New Services</center></h1>
                    <h5 className='text-danger mt-5'>*****Please read below instructions before importing services*****</h5>
                    <ol className='pl-3'>
                    <li>Download normal services excel file by clicking on
                            &nbsp; <a href='../../../services.xlsx' download="services.xlsx" style={{'color' : 'blue', 'cursor' : 'pointer'}}>Download</a>
                    </li>
                    <li>Download logistics services excel file by clicking on
                            &nbsp; <a href='../../../logistics_services.xlsx' download="logistics_services.xlsx" style={{'color' : 'blue', 'cursor' : 'pointer'}}>Download</a>
                    </li>
                    <li>Do not remove headers from excel files.</li>
                    <li>To download all the instructions please click this <a href="#">service instructions</a></li>
                    </ol>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 mt-3'>
                    <select id="service_type" className='form-control'>
                        <option value="">Service Type</option>
                        <option value="normal">Normal</option>
                        <option value="logistics">Logistics</option>
                    </select>
                </div>
                <div className='col-md-12 mt-3'>
              <select id="gst_no" className='form-control'>
                  <option value="">Select Gst</option>
                  {
                      userAddress.map((address) => {
                          return (
                              <>
                                  <option value={address.id}>{address.gst_no}</option>
                              </>
                          );
                      })
                  }
              </select>
          </div>
                <div className='col-md-12 mt-3'>
                    <select id="service_category" className='form-control' onChange={(e) => getServiceCategories(e.target.value)}>
                        <option value="">Service Category</option>
                        {
                            serviceCategory.map((category) => {
                                return (
                                    <>
                                        <option value={category.id}>{category.category_name}</option>
                                    </>
                                );
                            })
                        }
                    </select>
                </div>
                {
                    serviceSubCategory.length == 0 ? "" :
                        <div className='col-md-12 mt-3'>
                            <select id="service_sub_category" className='form-control'>
                                <option value="">Service Sub Category</option>
                                {
                                    serviceSubCategory.map((category) => {
                                        return (
                                            <>
                                                <option value={category.id}>{category.category_name}</option>
                                            </>
                                        );
                                    })
                                }
                            </select>
                        </div>
                }
                <div className='col-md-12 mt-3'>
                    <select id="delivery_type" className='form-control' onChange={selectDeliveryType}>
                        <option value="">Delivery Type ?</option>
                        <option value="pan_india">PAN India</option>
                        <option value="state_wise">State Wise</option>
                        <option value="pincode_wise">Pincode Wise</option>
                    </select>
                </div>
                {
                    showStates ? 
                    <div className='col-md-12 mt-3'>
                        {states ?
                            <Multiselect
                            options={states.allStates} // Options to display in the dropdown
                            displayValue="name"
                            showCheckbox="true"
                            onSelect={selectState}
                            onRemove={removeState}
                            placeholder="Select State"
                        />: <div className='loaader'></div>
                        }
                    </div>
                    : ""
                }
                {
                    showPincodes ? 
                    <div className='col-md-12 mt-3'>
                        {pincodes?
                            <Multiselect
                            options={pincodes.filterPincodes} // Options to display in the dropdown
                            displayValue="name"
                            showCheckbox="true"
                            onSearch={searchPincode}
                            onSelect={selectPincode}
                            onRemove={removePincode}
                            emptyRecordMsg={pincodes.msgPincode}
                            placeholder="Select Pincodes"
                        />: <div className='loaader'></div>
                        }
                            
                    </div>
                    : ""
                }
            </div>
            <br/><br/>
            <div {...getRootProps()} className="dropzone mb-5">
                <input {...getInputProps()} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                {
                    isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div>
            {upload ?
                <>
                <div className='button-container'>

                    <div className='text-center mb-5'>
                    <button className='btn btn-success' onClick={uploadData}>Upload</button>
                    </div>
                    </div>
                </> : '' 
            }
            <br/><br/><br/><br/>
        </div>
          
        </>
    );
};

export default SellerAddServices;