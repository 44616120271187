import React from 'react';
import MenuCard from './MenuCard';
import common from '../../../utils/Common';
import moment from 'moment';

class MainView extends React.Component {
    constructor(props) {
        super(props);
        this.productData = this.productData.bind(this);
        this.addressAddAlert = this.addressAddAlert.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            products: [],
            offset: 0,
            limit: 10,
            loading: false,
            hasMore: true,
            initialLoad: true
        };
    }

    componentDidMount() {
        this.productData();
        if (this.state.offset == 0) {
            this.addressAddAlert();
        }
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    addressAddAlert = async () => {
        if (localStorage.getItem("userId") && (!localStorage.getItem("address_alert_date") || localStorage.getItem("address_alert_date") !== moment().format("YYYY-MM-DD"))) {
            let data = { "user_id": localStorage.getItem('userId') };
            let response = await common.apiRequest('post', '/users/get-address', data,'web', false);
            if (response.status === 200) {
                if (response.data.code === 'success' && response.data.data.length === 0) {
                    common.alert("Please add home address", 'info');
                    localStorage.setItem("address_alert_date", moment().format("YYYY-MM-DD"));
                    window.location.href = '/my-account/address';
                }
            }
        }
    };
    
    handleScroll = () => {
        const { loading, hasMore, initialLoad } = this.state;
        
        if (initialLoad) return;
        
        const scrollTop = (document.documentElement || document.body.parentNode || document.body).scrollTop;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );
        
        const buffer = 50;
        
        if (
            windowHeight + scrollTop >= documentHeight - buffer
            && !loading
            && hasMore
        ) {
            this.setState(
                prevState => ({ 
                    offset: prevState.offset + this.state.limit 
                }),
                () => this.productData(true)
            );
        }
    }
    
    productData = async (isLoadMore = false) => {
        if (this.state.loading) return;

        this.setState({ loading: true });

        let parameters = {
            'user_id': localStorage.getItem('userId'),
            'limit': this.state.limit,
            'offset': this.state.offset
        };

        try {
            let response = await common.apiRequest('post', '/products/get-products', parameters, 'web', this.state.offset == 0 ? true : false);
            let data = (response.data && response.data.data) ? response.data.data : [];
            let filteredProducts = [];
            if (data.products && data.products.length > 0) {
              filteredProducts = data.products.filter(product => {
                return product.seller_address && product.seller_address.delete_address === false;
              });
            }     
               this.setState(prevState => ({
                products: isLoadMore ? [...prevState.products, ...filteredProducts] : filteredProducts,
                loading: false,
                hasMore: data.products.length === this.state.limit,
                initialLoad: false
            }));
        } catch (error) {
            this.setState({ loading: false });
            console.error('Error fetching products:', error);
        }
    }

    render() {
        return (
            <>
                <MenuCard 
                    products={this.state.products} 
                    isLoading={this.state.loading}
                />
                {this.state.loading && this.state.initialLoad && (
                    <div className="text-center p-4">
                        Loading products...
                    </div>
                )}
                {this.state.loading && !this.state.initialLoad && (
                    <div className="text-center p-4">
                        Loading more products...
                    </div>
                )}
            </>
        );
    }   
}

export default MainView;