import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import { ModuleRegistry } from '@ag-grid-community/core';
const common = require('../../utils/Common');
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const RefferUsers = () => {
    const [referralData, setReferralData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    console.log(referralData)
    useEffect(() => {
        UserReffer();
    }, []);

    const UserReffer = async () => {
        try {
            setLoading(true);
            const parameters = {
                'user_id': localStorage.getItem('userId'),
            };
            const response = await common.apiRequest('post', '/users/get-reffer-data', parameters);
            const data = (response.data && response.data.data) ? response.data.data : [];            
            setReferralData(data);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError(err.message);
            setLoading(false);
        }
    };

    const [columnDefs] = useState([
        {
            headerName: 'S.no', valueGetter: "node.rowIndex + 1"
        },
        {
            headerName: 'Reg Id', 
            field: 'reg_id',
            filter: 'agTextColumnFilter',
            sortable: true,
            width: 120
        },
        { 
            headerName: 'First Name', 
            field: 'first_name',
            filter: 'agTextColumnFilter',
            sortable: true,
            width: 120
        },
        { 
            headerName: 'Middle name', 
            field: 'middle_name',
            filter: 'agTextColumnFilter',
            sortable: true,
            width: 120
        },
        { 
            headerName: 'Last Name', 
            field: 'last_name',
            filter: 'agTextColumnFilter',
            sortable: true,
            width: 120
        },
        {
            headerName: 'Email', 
            field: 'email',
            filter: 'agTextColumnFilter',
            sortable: true,
            width: 120
        },
        {
            headerName: 'Mobile No', 
            field: 'mobile_phone',
            filter: 'agTextColumnFilter',
            sortable: true,
            width: 120
        },
        {
            headerName: 'Business Name', 
            field: 'user_business.business_name',
            filter: 'agTextColumnFilter',
            sortable: true,
            width: 120
        },
        {
            headerName: 'Business Nature', 
            field: 'user_business.nature_of_business',
            filter: 'agTextColumnFilter',
            sortable: true,
            width: 120
        },
        {
            headerName: 'Business Added',
            field: 'user_type',
            filter: 'agTextColumnFilter',
            sortable: true,
            width: 120,
            valueGetter: (params) => {
                return params.data.user_type === 'seller' ? 'Yes' : 'No';
            }
        }


    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);

    if (loading) {
        return <div className="p-4">Loading...</div>;
    }

    if (error) {
        return <div className="p-4 text-red-500">Error: {error}</div>;
    }
    const data = referralData.map((data) => ({
        "S.no": data.id,
        "Reg Id": data.reg_id,
        "First Name": data.first_name,
        "Middle name": data.middle_name,
        "Last Name": data.last_name,
        "Email": data.email,
        "Mobile No": data.mobile_phone,
        "Business Name": data.user_business.business_name,
        "Business Nature": data.user_business.nature_of_business,
        "Business Added": data.user_type === 'seller' ? 'Yes' : 'No'
    }));
    const handleExportCSV = () => {
        exportToCSV(data, 'your orders');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'your orders');
    }
    
    const handleExportPDF = () => {
        exportToPDF(data, 'your orders');
    }
    return (
        <div className="w-full p-4">
            <div className="text-2xl font-bold mb-4">Referral Users</div>
            <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
            {referralData.length === 0 ? (
                <div className="p-4">No referral data available</div>
            ) : (
                <div 
                    className="ag-theme-alpine" 
                    style={{
                        height: '500px',
                        width: '100%'
                    }}
                >
                    <AgGridReact
                        rowData={referralData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 25, 50]}
                    />
                </div>
            )}
        </div>
    );
};

export default RefferUsers;