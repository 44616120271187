import React, { useState, useEffect, useMemo } from 'react';
import { Modal, ModalBody, ModalHeader, Input, FormGroup, Button, Label, Form } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import moment from 'moment';
import $ from 'jquery';
import { parseValue } from '../../utils/Common';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

export default function SellerLogisticsOrders() {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    console.log(orders)
    const [deliveryUsers, setDeliveryUsers] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);
    const [orderImagesData, setOrderImagesData] = useState([]);
    const [showOrderImagesModal, setShowOrderImagesModal] = useState(false);
    const [showTest, setShowTest] = useState(false);

    useEffect(() => {
        getOrders();
        getDeliveryUsers();
    }, []);

    const getOrders = async () => {
        let data = {
            'seller_id': localStorage.getItem('sellerId'),
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
            'logistics': 1
        }
        let response = await common.apiRequest('post', '/seller/get-orders', data);
        if (response.data.code === 'success') {
            setOrders(response.data.data);
            setOrderStatuses(await common.getSystemConfig('product_service_order_status'));
        } else {
            setOrders([]);
        }
    }

    const getDeliveryUsers = async () => {
        let data = {
            'action': 'read',
            'deliver_manager_id': localStorage.getItem('sellerId')
        }
        let response = await common.apiRequest('post', '/delivery/crud-delivery-users', data);
        if (response.data.code === 'success') {
            setDeliveryUsers(response.data.data);
        } else {
            setDeliveryUsers([]);
        }
    }

    const assignDeliver = async (orderItemId, deliverId, columnName, sellerId, orderId, sellerAddressId, selfDeliver = 0) => {
        let data = {
            'action': 'update',
            'id': orderItemId,
            [columnName]: parseInt(deliverId),
            'seller_id': sellerId,
            'order_id': orderId,
            'seller_address_id': sellerAddressId,
            'self_deliver': selfDeliver
        }
        let response = await common.apiRequest('post', '/delivery/assign-deassign-orders', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            getOrders();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const changeOrderStatus = async (orderId, orderStatus, orderPrice, userId, previousStatus, selfDeliver, sellerAddressId, noReturn = 0) => {
        if (Number(noReturn) == 1) {
            common.alert('Status cannot be changed now', 'error');
            return;
        }
        if (previousStatus == "delivered") {
            common.alert('Status cannot be changed now', 'error');
            return;
        }
        if (orderStatus == "process_amount_to_wallet" && previousStatus != "cancelled" && previousStatus != "returned") {
            common.alert('First please change the status to cancelled or returned', 'error');
            return;
        }
        if (previousStatus === "process_amount_to_wallet") {
            common.alert('Status cannot be changed now', 'error');
            return;
        }
        let data = {
            'action': 'update',
            'order_id': orderId,
            'order_status': orderStatus,
            'order_price': orderPrice,
            'user_id': userId,
            'self_deliver': Number(selfDeliver),
            'seller_address_id': sellerAddressId
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message.replaceAll("_", " "));
            getOrders();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const generateInvoice = async (sellerId, orderId, invoiceType) => {
        let response = await common.getInvoiceData([sellerId], orderId, invoiceType);
        if (response.length === 0) {
            common.alert('No invoice', 'error');
            return;
        }
        navigate(
            '/generate-invoice',
            {
                state: {
                    invoiceData: response
                }
            }
        )
    }

    const generateReturnReplaceCancelInvoice = async (orderId, sellerIds, type, invoiceType) => {
        let response = await common.getInvoiceData(sellerIds, orderId, type, 1);
        navigate(
            '/generate-return-invoice',
            {
                state: {
                    invoiceData: response,
                    invoiceType: invoiceType
                }
            }
        )
    }

    const uploadCancelReplaceReturnImages = async (event, orderItemId) => {
        const files = event?.target?.files;
        if (files.length > 0) {
          await common.uploadOrderImages(files, orderItemId, 'seller', 'seller_order', 1);
        } else {
          common.alert("No images to upload", "error");
        }
        $('#order_images').val(null);
    }

    const showOrderImages = async (orderItemId) => {
        let response = await common.fetchOrderImages(orderItemId);
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            setOrderImagesData(response.data.data);
            setShowTest(true);
            setShowOrderImagesModal(true);
        } else {
            setShowTest(true);
            setOrderImagesData([]);
            setShowOrderImagesModal(false);
            common.alert("No Order Images Found", "info");
        }
    }

    // if (logisticsOrder.type === "replace") {
    //     replaceLogisticsDetails = {
    //         "Replace Logistics Invoice No": logisticsInvoice?.invoice_no || 0,
    //         "Replace Shipment ID": orderItem.shipment_id || 0,
    //         "Replace Tracking No": orderItem.tracking_no || 0,
    //         "Replace Logistics GST Rate": logisticsOrder.gst_rate || 0,
    //         "Replace Logistics Taxable Value": parseValue(logisticsOrder.taxable_value || 0, 'float'),
    //         "Replace Logistics IGST %": logisticsOrder.igst_percent || 0,
    //         "Replace Logistics IGST": parseValue(logisticsOrder.igst || 0, 'float'),
    //         "Replace Logistics CGST %": logisticsOrder.cgst_percent || 0,
    //         "Replace Logistics CGST": parseValue(logisticsOrder.cgst || 0, 'float'),
    //         "Replace Logistics SGST %": logisticsOrder.sgst_percent || 0,
    //         "Replace Logistics SGST": parseValue(logisticsOrder.sgst || 0, 'float'),
    //         "Replace Logistics UTGST %": logisticsOrder.utgst_percent || 0,
    //         "Replace Logistics UTGST": parseValue(logisticsOrder.utgst || 0, 'float')
    //     };
    // }

    const data = orders.map(data => ({
        order_type: data.type?.toUpperCase() || "NA",
        orderId: data.order_item?.order?.order_id || "NA",
        order: data.product_item ? "Online Product Sale" : 
               data.seller_service ? "Online Service Sale" : 
               "Offline Product Sale",
        created_at: data.created_at,
        productName: data.order_item?.product_item?.product?.name || 
                    data.order_item?.seller_service?.service_name || 
                    data.order_item?.offline_sale_product_datum?.product_description || 
                    "NA",
        productDescription: data.order_item?.product_item?.product?.description || 
                           data.order_item?.seller_service?.service_description || 
                           data.order_item?.offline_sale_product_datum?.product_description || 
                           "NA",
        sellerInvoiceNo: data.invoice?.invoice_no || "NA",
        order_price: parseValue(data.order_price || 0, 'float'),
        taxable_value: parseValue(data.taxable_value || 0, 'float'),
        gst_rate: parseValue(data.gst_rate || 0, 'float'),
        cgst: parseValue(data.cgst || 0, 'float'),
        cgst_percent: data.cgst_percent || 0,
        igst: parseValue(data.igst || 0, 'float'),
        igst_percent: data.igst_percent || 0,
        sgst: parseValue(data.sgst || 0, 'float'),
        sgst_percent: data.sgst_percent || 0,
        utgst: parseValue(data.utgst || 0, 'float'),
        utgst_percent: data.utgst_percent || 0,
        irn: data.irn || '',
        irn_date: data.irn_date || '',
        orderQuantity: data.order_item?.order_quantity,
        orderStatus: data.order_item?.order_status,
        order_completed_on: data.order_item?.order_completed_on,
        order_cancelled_on: data.order_item?.order_cancelled_on,
        cancel_taxable_value: data?.order_item?.order_status === 'process_amount_to_wallet' 
        ? -parseValue(data.taxable_value || 0, 'float') 
        : 0,
        cancel_gst_rate: data?.order_item?.order_status === 'process_amount_to_wallet' 
            ? -parseValue(data.gst_rate || 0, 'float') 
            : 0,
            cancel_cgst: data?.order_item?.order_status === 'process_amount_to_wallet' 
            ? -parseValue(data.cgst || 0, 'float') 
            : 0,
            cancel_cgst_percent: data?.order_item?.order_status === 'process_amount_to_wallet' 
            ? -data.cgst_percent || 0 
            : 0,
            cancel_igst: data?.order_item?.order_status === 'process_amount_to_wallet' 
            ? -parseValue(data.igst || 0, 'float') 
            : 0,
            cancel_igst_percent: data?.order_item?.order_status === 'process_amount_to_wallet' 
            ? -data.igst_percent || 0 
            : 0,
            cancel_sgst: data?.order_item?.order_status === 'process_amount_to_wallet' 
            ? -parseValue(data.sgst || 0, 'float') 
            : 0,
            cancel_sgst_percent: data?.order_item?.order_status === 'process_amount_to_wallet' 
            ? -data.sgst_percent || 0 
            : 0,
            cancel_utgst: data?.order_item?.order_status === 'process_amount_to_wallet' 
            ? -parseValue(data.utgst || 0, 'float') 
            : 0,
            cancel_utgst_percent: data?.order_item?.order_status === 'process_amount_to_wallet' 
            ? -data.utgst_percent || 0 
            : 0,

        // deliverId: data.type === "order" ? data.order_item?.deliver_id :data.type === "replace" ? data.order_item?.replace_deliver_id : data.type === "return" ? data.order_item?.return_deliver_id : 0
    }));
    
    const handleExportCSV = () => {
        exportToCSV(data, 'logistics orders.csv');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'logistics orders.xlsx');
    }

    const handleExportPDF = () => {
        exportToPDF(data, 'logistics orders.pdf');
    }

    const columnDefs = useMemo(() => [
        { 
            field: 'order_type', 
            headerName: 'Order Type',
            valueGetter: params => 
                params?.data?.type.toUpperCase() ?? "NA"
        },
        { field: 'order_item.order.order_id', headerName: 'Order ID'},
        { 
            field: 'order', 
            headerName: 'Order',
            valueGetter: params => 
                params.data?.product_item ? "Online Product Sale" : 
                params.data?.seller_service ? "Online Service Sale" : 
                "Offline Product Sale"
        },
        {
            field: 'created_at',
            headerName: 'Order Date',
        },
        { 
            field: 'productName', 
            headerName: 'Product Name',
            valueGetter: params => 
                params.data.order_item?.product_item ? params.data.order_item?.product_item.product.name : 
                params.data.order_item?.seller_service ? params.data.order_item?.seller_service.service_name : 
                params.data.order_item?.offline_sale_product_datum ? params.data?.order_item?.offline_sale_product_datum.product_description : 
                "NA"
        },
        { 
            field: 'productDescription', 
            headerName: 'Product Description',
            valueGetter: params => 
                params.data.order_item?.product_item ? params.data.order_item?.product_item.product.description : 
                params.data.order_item?.seller_service ? params.data.order_item?.seller_service.service_description : 
                params.data.order_item?.offline_sale_product_datum ? params.data?.order_item?.offline_sale_product_datum.product_description : 
                "NA"
        },
        { 
            field: 'sellerInvoiceNo', 
            headerName: 'Seller Invoice No',
            valueGetter: params => {
                return params?.data?.invoice?.invoice_no ?? "NA";
            }
        },
        {
            field: 'order_price',
            headerName: 'Order Price',
            valueGetter: params => parseValue(params.data.order_price || 0,'float')
        },
        {
            field: 'taxable_value',
            headerName: 'Taxable Value',
            valueGetter: params => parseValue(params.data.taxable_value || 0,'float')
        },
        {
            field: 'gst_rate',
            headerName: 'GST Rate',
            valueGetter: params => parseValue(params.data.gst_rate || 0,'float')
        },
        {
            field: 'cgst',
            headerName: 'CGST Amount',
            valueGetter: params => parseValue(params.data.cgst || 0,'float')
        },
        {
            field: 'cgst_percent',
            headerName: 'CGST Rate',
            valueGetter: params => params.data.cgst_percent || 0
        },
        {
            field: 'igst',
            headerName: 'IGST Amount',
            valueGetter: params => parseValue(params.data.igst || 0,'float')
        },
        {
            field: 'igst_percent',
            headerName: 'IGST Rate',
            valueGetter: params => params.data.igst_percent || 0
        },
        {
            field: 'sgst',
            headerName: 'SGST Amount',
            valueGetter: params => parseValue(params.data.sgst || 0,'float')
        },
        {
            field: 'sgst_percent',
            headerName: 'SGST Rate',
            valueGetter: params => params.data.sgst_percent || 0
        },
        {
            field: 'utgst',
            headerName: 'UTGST Amount',
            valueGetter: params => parseValue(params.data.utigst || 0,'float')
        },
        {
            field: 'utgst_percent',
            headerName: 'UTGST Rate',
            valueGetter: params => params.data.utigst_percent || 0
        },
        {
            field: 'irn',
            headerName: 'IRN',
            valueGetter: params => params.data.irn || ''
        },
        {
            field: 'irn_date',
            headerName: 'IRN Date',
            valueGetter: params => params.data.irn_date || ''
        },
        { field: 'order_item.order_quantity', headerName: 'Order Quantity' },
        { 
            field: 'order_status', 
            headerName: 'Order Status',
            cellRenderer: params => {
                const getStatusOptions = () => {
                    if (params.data.order_item.product_item ) {
                        if (orderStatuses.normal_online.includes(params.data.order_item?.order_status)) {
                            return orderStatuses.online_product.normal[0];
                        } else if (orderStatuses.return_online.includes(params.data.order_item?.order_status)) {
                            return orderStatuses.online_product.return[0];
                        } else if (orderStatuses.cancel_online.includes(params.data.order_item?.order_status)) {
                            return orderStatuses.online_product.cancel[0];
                        } else if (orderStatuses.replace_online.includes(params.data.order_item?.order_status)) {
                            return orderStatuses.online_product.replace[0];
                        }
                        return {};
                    } else if (params.data.order_item.offline_sale_product_datum) {
                        if (orderStatuses.normal_offline.includes(params.data.order_item?.order_status)) {
                            return orderStatuses.offline_product.normal[0];
                        }
                        return {};
                    } else if (params.data.order_item.seller_service) {
                        if (orderStatuses.normal_service.includes(params.data.order_item?.order_status)) {
                            return orderStatuses.service.normal[0];
                        }
                        return {};
                    }
                    return {};
                };

                const handleStatusChange = (role, selectedValue) => {
                    const statusOptions = getStatusOptions();
                    const currentStatus = statusOptions[selectedValue];
                    const canChange = currentStatus && currentStatus[`${role}_can_change`];
                    
                    if (!canChange) {
                        common.alert(`You are not liable to change to this status.`, 'error');
                    } else if (!currentStatus.can_move_from.includes(params.data.order_item?.order_status)) {
                        common.alert(`Status cannot move to this stage right now`, 'error');
                        return;
                    } else {
                        changeOrderStatus(
                            params.data.order_item.id,
                            selectedValue,
                            params.data.order_item.order_price,
                            params.data.order_item.order.user_id,
                            params.data.order_item.order_status,
                            params?.data?.order_item?.product_item?.self_deliver,
                            params?.data?.order_item?.product_item?.product?.seller_address_gst,
                            params?.data?.order_item?.no_return
                        );
                    }
                };

                return (
                    <select 
                        id="order_status" 
                        value={params.data.order_item?.order_status} 
                        className="form-control" 
                        onChange={(e) => {handleStatusChange('logistics', e.target.value)}} 
                        style={{width: '130px'}}
                    >
                        {Object.entries(getStatusOptions()).map(([key, value]) => (
                            <option key={key} value={key}>{value.status}</option>
                        ))}
                    </select>
                );
            }
        },
        { 
            headerName: 'Upload Order Images',
            cellRenderer: params => {
                return (
                    <input type='file' id="order_images" onChange={(e) => uploadCancelReplaceReturnImages(e, params.data.order_item.id)} multiple/>
                );
            }
        },
        { 
            headerName: 'View Order Images',
            cellRenderer: params => (
                <button type='button' className='form-control btn btn-info' onClick={() => showOrderImages(params.data.order_item.id)}>View Order Images</button>
            )
        },
        { field: 'order_item.order_completed_on', headerName: 'Order Completed On' },
        { field: 'order_item.order_cancelled_on', headerName: 'Order Cancelled On' },
        {
            field: 'order_price',
            headerName: 'Cancel Order Price',
            valueGetter: params => {
                const value = parseValue(params.data.order_price || 0, 'float');
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            field: 'taxable_value',
            headerName: 'Cancel Taxable Value',
            valueGetter: params => {
                const value = parseValue(params.data.taxable_value || 0, 'float');
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            field: 'gst_rate',
            headerName: 'Cancel GST Rate',
            valueGetter: params => {
                const value = parseValue(params.data.gst_rate || 0, 'float');
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            field: 'cgst',
            headerName: 'Cancel CGST Amount',
            valueGetter: params => {
                const value = parseValue(params.data.cgst || 0, 'float');
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            field: 'cgst_percent',
            headerName: 'Cancel CGST Rate',
            valueGetter: params => {
                const value = params.data.cgst_percent || 0;
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            field: 'igst',
            headerName: 'Cancel IGST Amount',
            valueGetter: params => {
                const value = parseValue(params.data.igst || 0, 'float');
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            field: 'igst_percent',
            headerName: 'Cancel IGST Rate',
            valueGetter: params => {
                const value = params.data.igst_percent || 0;
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            field: 'sgst',
            headerName: 'Cancel SGST Amount',
            valueGetter: params => {
                const value = parseValue(params.data.sgst || 0, 'float');
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            field: 'sgst_percent',
            headerName: 'Cancel SGST Rate',
            valueGetter: params => {
                const value = params.data.sgst_percent || 0;
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            field: 'utgst',
            headerName: 'Cancel UTGST Amount',
            valueGetter: params => {
                const value = parseValue(params.data.utigst || 0, 'float');
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            field: 'utgst_percent',
            headerName: 'Cancel UTGST Rate',
            valueGetter: params => {
                const value = params.data.utigst_percent || 0;
                return params?.data?.order_item?.order_status === 'process_amount_to_wallet' ? -value : 0;
            }
        },
        {
            headerName: 'Assign Delivery Person',
            cellRenderer: params => {
                if (params.data.order_item?.deliver_id && (params.data.type == "order" || params.data.type == "cancel")) {
                    let user = deliveryUsers.find((user) => user.id == params.data.order_item?.deliver_id);
                    return user ? user.first_name + " " + user.last_name : "NA";
                } else if (params.data.order_item?.return_deliver_id && (params.data.type == "return")) {
                    let user = deliveryUsers.find((user) => user.id == params.data.order_item?.return_deliver_id);
                    return user ? user.first_name + " " + user.last_name : "NA";
                } else if (params.data.order_item?.replace_deliver_id && (params.data.type == "replace")) {
                    let user = deliveryUsers.find((user) => user.id == params.data.order_item?.replace_deliver_id);
                    return user ? user.first_name + " " + user.last_name : "NA";
                } else if (params.data.order_item.order_status == "in_progress" || params.data.order_item.order_status == "pick_up_assigned" || params.data.order_item.order_status == "return_requested" || params.data.order_item.order_status == "re_delivery_assigned") {
                    return (
                        <select 
                            value={params.data.type == "order" ? params.data.order_item?.deliver_id : params.data.type == "replace" ? params.data.order_item?.replace_deliver_id : params.data.type == "return" ? params.data.order_item?.return_deliver_id : 0 || 0} 
                            onChange={e => assignDeliver(params.data.order_item.id, e.target.value, params.data.type == "order" ? 'deliver_id' : params.data.type == "replace" ? 'replace_deliver_id' : params.data.type == "return" ? 'return_deliver_id' : '', params.data.order_item.seller_id, params.data.order_item.order_id, params?.data?.order_item?.product_item?.product?.seller_address_gst, params?.data?.order_item?.product_item?.self_deliver)}
                            className='form-control'
                        >
                            <option value="0">Select Delivery User</option>
                            {deliveryUsers.map(user => (
                                <option key={user.id} value={user.id}>
                                    {`${user.first_name} ${user.last_name} (${user.reg_id})`}
                                </option>
                            ))}
                        </select>
                    );
                } else {
                    return "NA";
                }
            }
        },
        {
            headerName: 'Generate Invoice',
            cellRenderer: params => (
                <>
                    <button className="btn btn-outline-success btn-sm usercardbtn1" onClick={() => generateInvoice(params.data.seller_id, params.data.order_id, ['logistics'])}>
                        Logistics Invoice
                    </button>
                </>
            )
        },
        {
            headerName: 'Generate Cancel Invoice',
            cellRenderer: params => (
                <>
                    {params.data.type == "cancel" ?
                    <button className="btn btn-outline-success btn-sm usercardbtn1" onClick={() => generateReturnReplaceCancelInvoice(params.data.order_item_id, [params.data.seller_id], ['cancel_order_logistics'], 'Cancel')}>
                        Cancel Logistics Invoice
                    </button>: null}
                </>
            )
        },
        {
            headerName: 'Generate Return Invoice',
            cellRenderer: params => (
                <>
                    {params.data.type == "return" ?
                    <button className="btn btn-outline-success btn-sm usercardbtn1" onClick={() => generateReturnReplaceCancelInvoice(params.data.order_item_id, [params.data.order_item.seller_id], ['return_order_logistics'], 'Return')}>
                        Return Logistics Invoice
                    </button>: null}
                </>
            )
        },
        {
            headerName: 'Generate Replace Invoice',
            cellRenderer: params => (
                <>
                    {params.data.type == "replace" ?
                    <button className="btn btn-outline-success btn-sm usercardbtn1" onClick={() => generateReturnReplaceCancelInvoice(params.data.order_item_id, [params.data.order_item.seller_id], ['replace_order_logistics'], 'Replace')}>
                        Replace Logistics Invoice
                    </button>: null}
                </>
            )
        }
    ], [deliveryUsers, orderStatuses]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);

    return (
        <>
            <div className="row">
                <div className='col-md-6'>
                    <h3>Logistics Orders</h3>
                </div>
                <div className='col-md-6'>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                </div>
            </div>
            <Form className='row ml-2'>
                <FormGroup className='col-md-4 row'>
                    <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                    <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getOrders} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                </FormGroup>
                <FormGroup className='col-md-4 row'>
                    <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                    <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getOrders} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                </FormGroup>
            </Form>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                        <AgGridReact
                            rowData={orders}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[10, 25, 50]}
                        />
                    </div>
                </div>
            </div>

            <Modal isOpen={showOrderImagesModal} toggle={() => setShowOrderImagesModal(!showOrderImagesModal)} size="lg">
                <ModalHeader toggle={() => setShowOrderImagesModal(!showOrderImagesModal)}>Order Images</ModalHeader>
                <ModalBody>
                    {
                        orderImagesData && orderImagesData.length > 0 ?
                        <table>
                            <thead>
                                <th>Photo</th>
                                <th>Uploaded By</th>
                                <th>Type</th>
                                <th>Upload Date Time</th>
                            </thead>
                            <tbody>
                            {
                                orderImagesData.map((imageData) => {
                                    return (
                                    <>
                                        <tr>
                                            <td><img src={envConfig.api_url + imageData.photo} style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></td>
                                            <td>{imageData.uploaded_via}</td>
                                            <td>{imageData.type}</td>
                                            <td>{imageData.created_at}</td>
                                        </tr>
                                    </>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        :
                        <h4>No Order Images</h4>
                    }
                </ModalBody>
            </Modal>
        </>
    )
}