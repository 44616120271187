import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
const common = require('../../utils/Common');

const AddDeliveryUsers = () => {
    let navigate = useNavigate();
    const [deliveryUsers, setDeliveryUsers] = useState([]);
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState(null);

    const toggleAddModal = () => setModal(!modal);
    
    const handleEditShow = (data) => {
        setEditData(data);
        setEditModal(true);
    };

    const handleEditClose = () => {
        setEditModal(false);
        setEditData(null);
    };
    const addDetail = async (id) => {
        if($('#first_name').val() == "" || $('#last_name').val() == "" || $('#email').val() == ""){
            common.alert("Please fill in all fields", 'error');
            return;
        }
        var parameters = {
          'action': 'update',
          'id': id,
          'first_name': $('#first_name').val(),
          'last_name': $('#last_name').val(),
          'email': $('#email').val(),
        };
        let response = await common.apiRequest('post', '/delivery/crud-delivery-users', parameters);
        if (response.status == 200) {
          if (response.data.code == "success") {
            common.alert(response.data.message);
            navigate('/seller/delivery-users');
          } else {
            common.alert(response.data.error, 'error');
          }
        }
      }
      const handleActive = async (id, currentActiveStatus) => {
        var parameters = {
            'action': 'update',
            'id': id,
            'deliver_active': currentActiveStatus ? 0 : 1  // Toggle the active status
        };
        
        let response = await common.apiRequest('post', '/delivery/crud-delivery-users', parameters);
        if (response.status == 200) {
            if (response.data.code == "success") {
                common.alert(response.data.message);
                // Refresh the user list instead of navigating
                crudAddDeliveryUsers('read', 0);
            } else {
                common.alert(response.data.error, 'error');
            }
        }
    }
      const handleDelete = async (id) => {
        var parameters = {
          'action': 'delete',
          'id': id,
        };
        let response = await common.apiRequest('post', '/delivery/crud-delivery-users', parameters);
          if (response.data.code === "success") {
            common.alert(response.data.message);
            window.location.reload()
        }
        else {
            common.alert(response.data.error, 'error');
          }
      }
    const crudAddDeliveryUsers = async (action, id) => {
        if($('#first_name').val() == "" || $('#last_name').val() == "" || $('#email').val() == "" || $('#password').val() == ""){
            common.alert("Please fill in all fields", 'error');
            return;
        }
        let data = {
            "action": action,
            "deliver_manager_id": parseInt(localStorage.getItem('userId'))
        }
        if (action === "create") {
            data.first_name = $('#first_name').val();
            data.last_name = $('#last_name').val();
            data.email = $('#email').val();
            data.password = $('#password').val();
            data.reg_id = "USRDELIVER" + Date.now();
            data.pan_no = "USRDELIVER";
            data.mobile_phone = "USRDELIVER";
            data.user_type = "deliver";
            data.deliver_active = 1;
            data.active=1;
        }
        let response = await common.apiRequest('post', '/delivery/crud-delivery-users', data);
        if (response.status === 200) {
            if (response.data.code === 'success') {
                if (action === "create" || action === "delete" || action === "update") {
                    common.alert(response.data.message);
                    crudAddDeliveryUsers('read', 0);
                    if (action === "create" || action === "update") {
                        toggleAddModal();
                    }
                } else if (action === "read") {
                    setDeliveryUsers(response.data.data)
                }
            } else {
                common.alert(response.data.error, 'error');
            }
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/login');
        }
        crudAddDeliveryUsers('read', 0);
    }, [navigate]);

    return (
        <>
            <h1>All Delivery Users</h1><br/>
            <Button color="primary" style={{float: 'right', marginRight: '50px', marginBottom: '20px'}} onClick={toggleAddModal}>Add New User</Button>
            
            <Modal isOpen={modal} toggle={toggleAddModal}>
                <ModalHeader toggle={toggleAddModal}>Add New User</ModalHeader>
                <ModalBody>
                    <Input type='text' id="first_name" placeholder='Enter First Name' />
                    <Input type='text' id="last_name" placeholder='Enter Last Name' />
                    <Input type='email' id="email" placeholder='Enter Email' />
                    <Input type='password' id="password" placeholder='Enter Password' />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => crudAddDeliveryUsers('create', 0)}>
                        Save
                    </Button>{' '}
                    <Button color="danger" onClick={toggleAddModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

            <Table striped>
                <thead>
                    <tr className='table-primary'>
                        <th>Reg ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Active Status</th>
                        <th>Edit details</th>
                        <th>Active/Inactive</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {deliveryUsers.length === 0 ? (
                        <tr>
                            <td align="center" colSpan="6">
                                <center>No Data Available</center>
                            </td>
                        </tr>
                    ) : (
                        deliveryUsers.map((deliveryUser) => (
                            <tr key={deliveryUser.reg_id}>
                                <td>{deliveryUser.reg_id}</td>
                                <td>{deliveryUser.first_name}</td>
                                <td>{deliveryUser.last_name}</td>
                                <td>{deliveryUser.email}</td>
                                <td>{deliveryUser.deliver_active ? "Active" : "Not Active"}</td>
                                <td>
                                    <Button onClick={() => handleEditShow(deliveryUser)} className='submit'>Change user details</Button>
                                </td>
                                <th>
                                <Button 
                                        onClick={() => handleActive(deliveryUser.id, deliveryUser.deliver_active)} 
                                        className='submit'
                                        >
                                         {deliveryUser.deliver_active ? "Deactivate" : "Activate"}
                                </Button>                                
                                </th>
                                <th>
                                    <Button onClick={() => handleDelete(deliveryUser.id)} className='submit'>Delete</Button>
                                </th>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>

            <Modal isOpen={editModal} toggle={handleEditClose}>
                <ModalHeader toggle={handleEditClose}>
                    Select here to change
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="name">Enter a new Name</Label>
                        <Input
                            type='text'
                            id="first_name"
                            defaultValue={editData?.first_name || ''}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastName">Enter a new Last Name</Label>
                        <Input
                            type='text'
                            id="last_name"
                            defaultValue={editData?.last_name || ''}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Enter a new email</Label>
                        <Input
                            type='email'
                            id="email"
                            defaultValue={editData?.email || ''}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Link to="/seller/SellerChangePass" state={{ editPaas: editData }} className='submit'>
                        Change Password
                    </Link>
                    <Button color="primary" onClick={() => addDetail(editData.id)}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AddDeliveryUsers;