import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import './business.css';
import { ToWords } from 'to-words';
import { parseValue } from '../utils/Common';

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

function GenerateReturnInvoice() {
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoiceType, setInvoiceType] = useState(null);
    const location = useLocation();
    var counter = 0;
    var minus = null;
    let invoiceStatusToMatch = ['return_order', 'return_order_seller', 'cancel_order', 'cancel_order_seller', 'return_order_logistics_user', 'cancel_order_logistics_user', 'cancel_order_logistics'];
    useEffect(() => {
        setInvoiceData(location.state?.invoiceData || []);
        setInvoiceType(location.state?.invoiceType || null);
    }, [location.state]);

    const downloadPdfDocument = () => {
        const input = document.getElementById("invoice");
        if (!input) return;

        const a4Width = 595.28;
        const a4Height = 941.89;
        const padding = 0;
        html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: true,
            windowWidth: (a4Width + padding) * 2,
            windowHeight: a4Height * 2
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('p', 'pt', 'a4');
            const imgWidth = a4Width - padding * 2;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            if (imgHeight <= a4Height) {
                pdf.addImage(imgData, 'JPEG', padding, 0, imgWidth, imgHeight);
            } else {
                let y = 0;
                while (y < imgHeight) {
                    pdf.addImage(imgData, 'JPEG', padding, -y, imgWidth, imgHeight);
                    y += a4Height;
                    if (y < imgHeight) pdf.addPage();
                }
            }

            pdf.save('invoice.pdf');
        });
    }



    const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        }
    });

    const convertToWords = (amount) => {
        const numAmount = parseFloat(amount);
        if (isNaN(numAmount)) return '';
        return toWords.convert(numAmount, { currency: true });
    };
    if (Array.isArray(invoiceData) && invoiceData.length > 0) {
        return (
            <>
                <div id='invoice'>
                    {
                        invoiceData.map((invoiceData) => {
                            counter = 0;
                            minus = invoiceData?.invoice.invoice_type == 'return_order_logistics' || invoiceData?.invoice.invoice_type == 'replace_order_logistics' ? null : '-';
                            if (invoiceData) {
                                return (
                                    <>
                                        <div className='bill-customer' id="testId">
                                            <div className='invoice'>
                                                <div className='invoice-head col-md-12'>
                                                    <h2>{invoiceType} Invoice</h2>
                                                </div>
                                                <div className='customer-details'>
                                                    <div className='customer-details-head'>
                                                        <h2>Customer Details:</h2>
                                                    </div>
                                                    <div className='bill-details row'>
                                                        <div className='billing-address col-md-6'>
                                                            <div className='billing-address-head'><h4>Billing Address :</h4></div>
                                                            <div className='billing-address-details'>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Name :</label>
                                                                    <div className='col-md-6'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user?.first_name + " " + invoiceData?.orderItems[0]?.order?.user?.last_name
                                                                            :
                                                                            invoiceData?.invoice?.seller?.first_name + " " + invoiceData?.invoice?.seller?.last_name
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Address :</label>
                                                                    <div className='col-md-6'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user_address?.address
                                                                            :
                                                                            invoiceData?.invoice?.seller?.seller_addresses[0]?.address
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Pincode :</label>
                                                                    <div className='col-md-6'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user_address?.pincode
                                                                            :
                                                                            invoiceData?.invoice?.seller?.seller_addresses[0]?.pincode
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>State :</label>
                                                                    <div className='col-md-6'>
                                                                    {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user_address?.state
                                                                            :
                                                                            invoiceData?.invoice?.seller?.seller_addresses[0]?.state
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Pan No :</label>
                                                                    <div className='col-md-6'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user?.pan_no
                                                                            :
                                                                            invoiceData?.invoice?.seller?.pan_no
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Email :</label>
                                                                    <div className='col-md-6 text-break word-wrap'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user?.email
                                                                            :
                                                                            invoiceData?.invoice?.seller?.email
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Mobile No :</label>
                                                                    <div className='col-md-6'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user?.mobile_phone
                                                                            :
                                                                            invoiceData?.invoice?.seller?.mobile_phone
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='shipping-address col-md-6'>
                                                            <div className='shipping-address-head'><h4>Shipping Address :</h4></div>
                                                            <div className='shipping-address-details'>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Name :</label>
                                                                    <div className='col-md-6'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user?.first_name + " " + invoiceData?.orderItems[0]?.order?.user?.last_name
                                                                            :
                                                                            invoiceData?.invoice?.seller?.first_name + " " + invoiceData?.invoice?.seller?.last_name
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Address :</label>
                                                                    <div className='col-md-6'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user_address?.address
                                                                            :
                                                                            invoiceData?.invoice?.seller?.seller_addresses[0]?.address
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Pincode :</label>
                                                                    <div className='col-md-6'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user_address?.pincode
                                                                            :
                                                                            invoiceData?.invoice?.seller?.seller_addresses[0]?.pincode
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>State :</label>
                                                                    <div className='col-md-6'>
                                                                    {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user_address?.state
                                                                            :
                                                                            invoiceData?.invoice?.seller?.seller_addresses[0]?.state
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Email :</label>
                                                                    <div className='col-md-6 text-break word-wrap'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user?.email
                                                                            :
                                                                            invoiceData?.invoice?.seller?.email
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Mobile No :</label>
                                                                    <div className='col-md-6'>
                                                                        {
                                                                            invoiceStatusToMatch.includes(invoiceData?.invoice.invoice_type) ?
                                                                            invoiceData?.orderItems[0]?.order?.user?.mobile_phone
                                                                            :
                                                                            invoiceData?.invoice?.seller?.mobile_phone
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='customer-details'>
                                                    <div className='customer-details-head'>
                                                        <h4>Supply by:</h4>
                                                    </div>
                                                    <div className='bill-details row'>
                                                        <div className='billing-address col-md-6'>
                                                            <div className='billing-address-details'>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Name :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice?.invoice_type == "return_order_logistics" || invoiceData?.invoice?.invoice_type == "return_order_logistics_user" || invoiceData?.invoice?.invoice_type == "replace_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics_user" ? invoiceData?.invoice?.logistics_order?.logistics_seller?.first_name + " " + invoiceData?.invoice?.logistics_order?.logistics_seller?.last_name : invoiceData?.orderItems[0]?.seller?.user_business?.business_name}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Address :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice?.invoice_type == "return_order_logistics" || invoiceData?.invoice?.invoice_type == "return_order_logistics_user" || invoiceData?.invoice?.invoice_type == "replace_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics_user" ? invoiceData?.invoice?.logistics_order?.logistics_seller?.seller_addresses[0]?.address : invoiceData?.orderItems[0]?.seller?.seller_addresses?.[0]?.address || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Pincode :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice?.invoice_type == "return_order_logistics" || invoiceData?.invoice?.invoice_type == "return_order_logistics_user" || invoiceData?.invoice?.invoice_type == "replace_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics_user" ? invoiceData?.invoice?.logistics_order?.logistics_seller?.seller_addresses[0]?.pincode : invoiceData?.orderItems[0]?.seller?.seller_addresses?.[0]?.pincode || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>GSTIN :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice?.invoice_type == "return_order_logistics" || invoiceData?.invoice?.invoice_type == "return_order_logistics_user" || invoiceData?.invoice?.invoice_type == "replace_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics_user" ? invoiceData?.invoice?.logistics_order?.logistics_seller?.seller_addresses[0]?.gst_no : invoiceData?.orderItems[0]?.seller?.seller_addresses?.[0]?.gst_no || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>State :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice?.invoice_type == "return_order_logistics" || invoiceData?.invoice?.invoice_type == "return_order_logistics_user" || invoiceData?.invoice?.invoice_type == "replace_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics_user" ? invoiceData?.invoice?.logistics_order?.logistics_seller?.seller_addresses[0]?.state : invoiceData?.orderItems[0]?.seller?.seller_addresses?.[0]?.state || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Pan No :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice?.invoice_type == "return_order_logistics" || invoiceData?.invoice?.invoice_type == "return_order_logistics_user" || invoiceData?.invoice?.invoice_type == "replace_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics" || invoiceData?.invoice?.invoice_type == "cancel_order_logistics_user" ? invoiceData?.invoice?.logistics_order?.logistics_seller?.pan_no : invoiceData?.orderItems[0]?.seller?.user_business?.pan_no || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Place of supply :</label>
                                                                    <div className='col-md-6'>{invoiceData?.orderItems[0]?.seller?.seller_addresses?.[0]?.state || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Place of delivery :</label>
                                                                    <div className='col-md-6'>{invoiceData?.orderItems[0]?.order?.user_address?.state || "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='shipping-address col-md-6'>
                                                            <div className='shipping-address-details'>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Invoice Number :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice?.invoice_no || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Invoice Date :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice?.created_at || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Order ID (SRRR Number) :</label>
                                                                    <div className='col-md-6'>{invoiceData?.orderItems[0]?.order?.order_id || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Mode of Payment  :</label>
                                                                    <div className='col-md-6'>Online</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Amount received  :</label>
                                                                    <div className='col-md-6'>Rs. {parseValue(invoiceData?.invoice?.price, 'float') || 0}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Date & Time :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice?.created_at || "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className='invoice-table'>
                                                    <div className='invoice-table-head'>
                                                        <h4>Goods wise Details:</h4>
                                                    </div>
                                                    <div className='invoice-table-body'>
                                                        <table className="orders-table report-margin table-responsive">
                                                            <thead>
                                                                <tr>
                                                                    <th>S.No.</th>
                                                                    <th>Product Name</th>
                                                                    <th>HSN/SAC Code</th>
                                                                    <th>Qty</th>
                                                                    <th>Rate</th>
                                                                    <th>Sale Amount</th>
                                                                    <th>Disc</th>
                                                                    <th>Taxable Supply</th>
                                                                    <th>IGST
                                                                        <th>Rate %</th>
                                                                        <th>Amount</th>
                                                                    </th>
                                                                    <th>CGST
                                                                        <th>Rate %</th>
                                                                        <th>Amount</th>
                                                                    </th>
                                                                    <th>SGST / UTGST
                                                                        <th>Rate %</th>
                                                                        <th>Amount</th>
                                                                    </th>
                                                                    <th>Total Invoice
                                                                        <th>GST</th>
                                                                        <th>Amount</th>
                                                                        <th>R. Off</th>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    invoiceData?.invoice.invoice_type == 'return_order_logistics_user' || invoiceData?.invoice.invoice_type == 'cancel_order_logistics_user' || invoiceData?.invoice.invoice_type == 'return_order_logistics' || invoiceData?.invoice.invoice_type == 'cancel_order_logistics' || invoiceData?.invoice.invoice_type == 'replace_order_logistics' ?
                                                                        <tr>
                                                                            <td>1</td>
                                                                            <td>Logistics</td>
                                                                            <td>NA</td>
                                                                            <td>NA</td>
                                                                            <td>{parseValue(invoiceData?.invoice.logistics_order?.order_price || 0, 'float')}</td>
                                                                            <td>{minus}{parseValue(invoiceData?.invoice.logistics_order?.order_price || 0, 'float')}</td>
                                                                            <td>{minus}{parseValue(0, 'float')}</td>
                                                                            <td>{minus}{parseValue(invoiceData?.invoice.logistics_order?.taxable_value || 0, 'float')}</td>
                                                                            <td>
                                                                                <td>{parseValue(invoiceData?.invoice.logistics_order?.igst_percent || 0, 'float')}</td>
                                                                                <td>{minus}{parseValue(invoiceData?.invoice.logistics_order?.igst || 0, 'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{parseValue(invoiceData?.invoice.logistics_order?.cgst_percent || 0, 'float')}</td>
                                                                                <td>{minus}{parseValue(invoiceData?.invoice.logistics_order?.cgst || 0, 'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{parseValue(invoiceData?.invoice.logistics_order?.sgst_percent || invoiceData?.logistics_order?.utgst_percent || 0, 'float')}</td>
                                                                                <td>{minus}{parseValue(invoiceData?.invoice.logistics_order?.sgst || invoiceData?.logistics_order?.utgst || 0, 'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{minus}
                                                                                    {
                                                                                        parseValue(invoiceData?.invoice.logistics_order?.igst || 0, 'float') +
                                                                                        parseValue(invoiceData?.invoice.logistics_order?.cgst || 0, 'float') +
                                                                                        parseValue(invoiceData?.invoice.logistics_order?.sgst || invoiceData?.invoice.logistics_order?.utgst || 0, 'float')
                                                                                    }
                                                                                </td>
                                                                                <td>{minus}{parseValue(invoiceData?.invoice.logistics_order?.order_price || 0, 'float')}</td>
                                                                                <td>{minus}
                                                                                    {
                                                                                        parseValue(
                                                                                            parseValue(invoiceData?.invoice.logistics_order?.order_price || 0, 'float') -
                                                                                            parseValue(invoiceData?.invoice.logistics_order?.taxable_value || 0, 'float') -
                                                                                            parseValue(invoiceData?.invoice.logistics_order?.igst || 0, 'float') -
                                                                                            parseValue(invoiceData?.invoice.logistics_order?.cgst || 0, 'float') -
                                                                                            parseValue(invoiceData?.invoice.logistics_order?.sgst || invoiceData?.logistics_order?.utgst || 0, 'float'), 'float')
                                                                                    }
                                                                                </td>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        invoiceData.orderItems.map((data) => {
                                                                        counter++;
                                                                        return (
                                                                            <tr key={counter}>
                                                                            <td>{counter}</td>
                                                                            <td>{data.product_item ? data.product_item.product?.name || "N/A" : data.seller_service ? data.seller_service?.service_name || "N/A" : data.offline_sale_product_datum?.product_description || "N/A"}</td>
                                                                            <td>{data.product_item?.hsn_code || data.seller_service?.hsn_code || data.offline_sale_product_datum?.hsn_code || "N/A"}</td>
                                                                            <td>{data.offline_sale_product_datum?.quantity || data.order_quantity || "N/A"}</td>
                                                                            <td>-{parseValue(data.product_item?.price || data.seller_service?.price || data.offline_sale_product_datum?.price || 0, 'float')}</td>
                                                                            <td>-{parseValue(data.order_price || data.offline_sale_product_datum?.sale_amount || 0,'float')}</td>
                                                                            <td>{parseValue(data.offline_sale_product_datum?.discount || 0,'float')}</td>
                                                                            <td>-{parseValue( data.taxable_amount || data.offline_sale_product_datum?.taxable_supply || 0,'float')}</td>
                                                                            <td>
                                                                                <td>{parseValue( data.igst_percent || data.offline_sale_product_datum?.igst_rate,'float')}</td>
                                                                                <td>-{parseValue( data.igst || data.offline_sale_product_datum?.igst_amount,'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{parseValue( data.cgst_percent  || data.offline_sale_product_datum?.cgst_rate,'float')}</td>
                                                                                <td>-{parseValue(data.cgst  || data.offline_sale_product_datum?.cgst_amount,'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{ parseValue(data.sgst_percent || data.offline_sale_product_datum?.sgst_utgst_rate,'float')}</td>
                                                                                <td>-{parseValue( data.sgst || data.offline_sale_product_datum?.sgst_utgst_amount,'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                            <td>-
                                                                            {parseValue(
                                                                                parseValue(data?.igst || 0, 'float') +
                                                                                parseValue(data?.cgst || 0, 'float') +
                                                                                parseValue(data?.sgst || 0, 'float') +
                                                                                parseValue(data?.utgst || 0, 'float'),
                                                                                'float'
                                                                            )}
                                                                            </td>
                                                                            <td>
                                                                            <td>-{parseValue(data.order_price || data.offline_sale_product_datum?.sale_amount || 0,'float')}</td>
                                                                            <td>-
                                                                                    {
                                                                                        parseValue(parseValue(data.order_price || 0, 'float')-
                                                                                        parseValue(data.taxable_amount || 0, 'float')-
                                                                                        parseValue(data.igst || 0, 'float')-
                                                                                        parseValue(data.cgst || 0, 'float')-
                                                                                        parseValue(data.sgst || data.utgst || 0, 'float'), 'float')
                                                                                    } 
                                                                            </td>
                                                                            </td>
                                                                            </td>
                                                                        </tr>
                                                                        )
                                                                })
                                                                }
                                                                <tr>
                                                                    <td colSpan="7"></td>
                                                                    <td colSpan="10">
                                                                        <tr>
                                                                            <td><b>Total</b></td>
                                                                            <td>Rs. {minus}{parseValue(invoiceData.invoice.price || 0, 'float')}</td>
                                                                            <td>{convertToWords(parseValue(invoiceData.invoice.price || 0, 'float'))}</td>

                                                                        </tr>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='footer-data row'>
                                                    <b className='col-md-6'>Whether GST payable under reverse charge  ?</b>
                                                    <h6 className='col-md-6'>No</h6>
                                                </div>
                                                <div className='footer-data row'>
                                                    <div className='col-md-12 row'>
                                                        <h5 className='col-md-6'>Remarks:</h5>
                                                        <div className='col-md-6 row'>
                                                            <label className='col-md-2'>for</label>
                                                            <div className='col-md-4'>
                                                                <b>{invoiceData?.invoice?.invoice_type == "cancel_order_logistics_user" || invoiceData?.invoice?.invoice_type == "replace_order_logistics" || invoiceData?.invoice?.invoice_type == "return_order_logistics" ? invoiceData.invoice?.logistics_order?.logistics_seller?.first_name + " " + invoiceData.invoice?.logistics_order?.logistics_seller?.middle_name + " " + invoiceData.invoice?.logistics_order?.logistics_seller?.last_name || "N/A" : invoiceData?.orderItems[0]?.seller?.first_name + " " + invoiceData?.orderItems[0]?.seller?.middle_name + " " + invoiceData?.orderItems[0]?.seller?.last_name}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='footer-data row'>
                                                    <div className='col-md-12 row'>
                                                        <h5 className='col-md-6'>Remarks:</h5>
                                                        <div className='col-md-6'>
                                                            <div className='Sign-image-vendor'>Signed</div>
                                                            <div className='Authorised-Signatory'>Authorised Signatory</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            } else {
                                return 'No Invoice';
                            }
                        })
                    }
                </div>
                <button onClick={downloadPdfDocument}>Download Pdf</button>
            </>


        )
    }
}

export default GenerateReturnInvoice;
