import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const Search = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchString, setSearchString] = useState("");
    const [allProducts, setAllProducts] = useState([]);
    const [allServices, setAllServices] = useState([]);
    const [allFilters, setAllFilters] = useState([]);
    const [nearBy, setNearBy] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState({
        variations: [],
        variationOptions: []
    });

    const searchProduct = async (filtersToUse = null) => {
        let searchString = $('#search').val()?.trim() || "";
            var parameters = {
                'queryString': searchString,
                'filters': filtersToUse || appliedFilters
            };
            console.log(parameters,"parameters");
            try {
                var response = await common.apiRequest('post', '/products/search-product', parameters);
                
                // Safely access products and filter them
                let filteredProducts = [];
                if (response?.data?.data?.products && Array.isArray(response.data.data.products)) {
                    filteredProducts = response.data.data.products.filter(product => {
                        return product.seller_address && product.seller_address.delete_address === false;          
                    });
                }
                console.log(filteredProducts, "filtered products");
                setAllProducts(filteredProducts);
                
                if (response?.data?.data?.filters) {
                    setAllFilters(response.data.data.filters);
                } else {
                    setAllFilters([]);
                }
                
                setSearchString(searchString);
            } catch (error) {
                console.error("Error in search product:", error);
                setAllProducts([]);
                setAllFilters([]);
            }
    };

    // const addToFilters = async (variationId, variationOptionId, e) => {
    //     let previousVariations = [...appliedFilters.variations];
    //     let previousVariationOptions = [...appliedFilters.variationOptions];
    //     if (e.target.checked) {
    //         previousVariations.push(variationId);
    //         previousVariationOptions.push(variationOptionId);
    //     } else {
    //         let index = previousVariations.indexOf(variationId);
    //         if (index > -1) {
    //             previousVariations.splice(index, 1);
    //         }
    //         index = previousVariationOptions.indexOf(variationOptionId);
    //         if (index > -1) {
    //             previousVariationOptions.splice(index, 1);
    //         }
    //     }
        
    //     // setAppliedFilters(prev => ({
    //     //     ...prev, 
    //     //     variations: previousVariations,
    //     //     variationOptions: previousVariationOptions
    //     // }));
        
    //     // searchProduct();
    //     const newFilters = {
    //         variations: previousVariations,
    //         variationOptions: previousVariationOptions
    //     };
    //     setAppliedFilters(newFilters);

    //     await searchProduct(newFilters);
    // };

    const addToFilters = async (variationId, variationOptionId, e) => {
        let previousVariations = [...appliedFilters.variations];
        let previousVariationOptions = [...appliedFilters.variationOptions];
        
        if (e.target.checked) {
            previousVariations.push(variationId);
            previousVariationOptions.push(variationOptionId);
        } else {
            if (previousVariations.length === 1 && previousVariations[0] === variationId) {
                if (location.state && location.state.data && location.state.data.products) {
                    const filteredProducts = location.state.data.products.filter(product => {
                        return product.seller_address && product.seller_address.delete_address === false;
                    });
                    setAllProducts(filteredProducts);
                    setAppliedFilters({
                        variations: [],
                        variationOptions: []
                    });
                    return;
                }
            }
            let index = previousVariations.indexOf(variationId);
            if (index > -1) {
                previousVariations.splice(index, 1);
            }
            index = previousVariationOptions.indexOf(variationOptionId);
            if (index > -1) {
                previousVariationOptions.splice(index, 1);
            }
        }
        
        const newFilters = {
            variations: previousVariations,
            variationOptions: previousVariationOptions
        };
        setAppliedFilters(newFilters);
    
        if (previousVariations.length > 0) {
            await searchProduct(newFilters);
        } else {
            if (location.state && location.state.data && location.state.data.products) {
                const filteredProducts = location.state.data.products.filter(product => {
                    return product.seller_address && product.seller_address.delete_address === false;
                });
                setAllProducts(filteredProducts);
            }
        }
    };


    useEffect(() => {
        if (location.state && location.state.data) {
            if (location.state.nearBy) {
                setNearBy(true);
                if (location.state.data.data.products && Array.isArray(location.state.data.data.products)) {
                    setAllProducts(location.state.data.data.products);
                } else {
                    setAllServices([]);
                }
                if (location.state.data.data.services && Array.isArray(location.state.data.data.services)) {
                    setAllServices(location.state.data.data.services);
                } else {
                    setAllServices([]);
                }
            } else if (location.state.data.data) {
                setNearBy(false);
                if (location.state.data.data && location.state.data.data.products && Array.isArray(location.state.data.data.products)) {
                    setAllProducts(location.state.data.data.products);
                } else {
                    setAllProducts([]);
                }
                if (location.state.data.data.services && Array.isArray(location.state.data.data.services)) {
                    setAllServices(location.state.data.data.services);
                } else {
                    setAllServices([]);
                }
            } else {
                setNearBy(false);
                if (location.state.data && location.state.data.products && Array.isArray(location.state.data.products)) {
                    setAllProducts(location.state.data.products);
                } else {
                    setAllProducts([]);
                }
                if (location.state.data.services && Array.isArray(location.state.data.services)) {
                    setAllServices(location.state.data.services);
                } else {
                    setAllServices([]);
                }
            }
            
            // Handle filters
            if (location.state.data.filters) {
                setAllFilters(location.state.data.filters);
            }
            
            // Handle search string
            if (location.state.data.searchString) {
                setSearchString(location.state.data.searchString);
            }
        }
    }, [location.state, navigate]);

    return (
        <>
            <div className='row'>
                <div className='col-md-2 mt-5'>
                    {!nearBy && allProducts.length > 0 && allFilters && allFilters.length !== 0 ? (
                        <div className="w-full">
                        {allFilters.map((filter) => (
                            <div key={filter.name} className="p-4">
                                <label className="block text-lg font-bold">{filter.name}</label>
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {filter.value.split(',').map((option, index) => {
                                        let variationOptionIds = filter.variation_option_ids.split(',');
                                        let variationIds = filter.variation_ids.split(',');
                    
                                        return (
                                            <label key={index} className="flex items-center space-x-2">
                                                <input 
                                                    type="checkbox" 
                                                    className="w-4 h-4 ml-1"
                                                    onClick={(e) => addToFilters(variationIds[index], variationOptionIds[index], e)}
                                                />
                                                <span className="text-sm ml-1">{option}</span>
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                    
                    ) : null}
                </div>
                <div className='col-md-10'>
                    <div className="container-fluid bg-trasparent my-4 p-3" style={{ position: "relative", maxWidth: "1400px" }}>
                        <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3">
                        {!nearBy ? (allProducts && allProducts.length > 0 ?
                        allProducts.map((product) => (
                            product && product.product_items && Array.isArray(product.product_items) && 
                            product.product_items.map((item) => (
                                <div className="col hp mt-5" key={`${product.id}-${item.id}`}>
                                    <Link className="text-white" to={`/product/${product.id}/${item.id}`}>
                                        <div className="card h-100 shadow-sm">
                                            <Link to={`/product/${product.id}/${item.id}`}>
                                                {item.product_image && item.product_image.split(',')[0] && (
                                                    <img src={envConfig.api_url + item.product_image.split(',')[0]} className="card-img-top mt-3" alt="product img" />
                                                )}
                                            </Link>
                                            <div className="label-top shadow-sm">
                                                <Link className="text-white" style={{
                                                    wordWrap: 'break-word',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: 'block',
                                                    maxWidth: '100px',
                                                }} to={`/product/${product.id}/${item.id}`}>
                                                    {product.name}
                                                </Link>
                                            </div>
                                        
                                            <div className="card-body">
                                                <div className="clearfix mb-3">
                                                    <span className="float-start badge rounded-pill bg-warning">Rs. {item.price}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        )) : null) : null}

                        {!nearBy ? (allServices && allServices.length > 0 ?
                            allServices.map((service) => (
                                service && 
                                    <div className="col hp mt-5" key={`${service.id}`}>
                                        <Link className="text-white" to={`/service/details/${service.id}`}>
                                            <div className="card h-100 shadow-sm">
                                                <Link to={`/service/details/${service.id}/`}>
                                                    {service.service_image && service.service_image && (
                                                        <img src={envConfig.api_url + service.service_image} className="card-img-top mt-3" alt="product img" />
                                                    )}
                                                </Link>
                                                <div className="label-top shadow-sm">
                                                    <Link className="text-white" style={{
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        display: 'block',
                                                        maxWidth: '100px',
                                                    }} to={`/service/details/${service.id}`}>
                                                        {service.service_name}
                                                    </Link>
                                                </div>
                                            
                                                <div className="card-body">
                                                    <div className="clearfix mb-3">
                                                        <span className="float-start badge rounded-pill bg-warning">Rs. {service.price}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                            )) : null) : null}
                        {nearBy && allProducts && allProducts.length > 0 && allProducts[0]?.address ? 
                            allProducts.map((product, index) => (
                                <div className="col hp mt-5" key={index}>
                                    <div className="card h-100 shadow-sm">
                                        <h2>{product?.user?.user_business?.business_name || 'NA'}</h2>
                                        <h4>{product?.user?.user_business?.nature_of_business || 'NA'}</h4>
                                        <h4>{product?.address || 'NA'}</h4>
                                    </div>
                                </div>
                            ))
                        : null}
                        
                        {nearBy && allServices && allServices.length > 0 && allServices[0]?.address ? 
                            allServices.map((service, index) => (
                                <div className="col hp mt-5" key={index}>
                                    <div className="card h-100 shadow-sm">
                                        <h2>{service?.user?.user_business?.business_name || 'NA'}</h2>
                                        <h4>{service?.user?.user_business?.nature_of_business || 'NA'}</h4>
                                        <h4>{service?.address || 'NA'}</h4>
                                    </div>
                                </div>
                            ))
                        : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Search;