import React, { useState, useEffect, useMemo } from 'react';
import { Modal, ModalBody, ModalHeader, Input, FormGroup, Button, Label, Form } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import moment from 'moment';
import $ from 'jquery';
import { parseValue } from '../../utils/Common';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

export default function SellerOrders() {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    console.log(orders)
    const [deliveryUsers, setDeliveryUsers] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);
    const [orderImagesData, setOrderImagesData] = useState([]);
    const [showOrderImagesModal, setShowOrderImagesModal] = useState(false);
    const [showLogisticsVendor, setShowLogisticsVendor] = useState({
        show: false,
        orderItemId: 0,
        orderId: 0,
        type: null,
        sellerAddressId: 0,
        sellerId: 0,
        self_deliver: 0,
        order_status: null
    });
    const [supplyVendors, setSupplyVendors] = useState({
        vendors: [],
        productItemData: [],
        thirdPartyVendors: []
    });

    useEffect(() => {
        getOrders();
        getDeliveryUsers();
    }, []);

    const getOrders = async () => {
        let data = {
            'seller_id': localStorage.getItem('sellerId'),
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        }
        let response = await common.apiRequest('post', '/seller/get-orders', data);
        if (response.data.code === 'success') {
            setOrders(response.data.data);
            setOrderStatuses(await common.getSystemConfig('product_service_order_status'));
        } else {
            setOrders([]);
        }
    }

    const getDeliveryUsers = async () => {
        let data = {
            'action': 'read',
            'deliver_manager_id': localStorage.getItem('sellerId')
        }
        let response = await common.apiRequest('post', '/delivery/crud-delivery-users', data);
        if (response.data.code === 'success') {
            setDeliveryUsers(response.data.data);
        } else {
            setDeliveryUsers([]);
        }
    }

    const changeOrderStatus = async (orderId, orderStatus, orderPrice, userId, previousStatus, returnLogisticsVendor = 0, replaceLogisticsVendor = 0, selfDeliver = 0, sellerAddressId = 0, offlineSale = 0, noReturn = 0, allData = {}) => {
        if (Number(noReturn) == 1) {
            common.alert('Status cannot be changed now', 'error');
            return;
        }
        if (previousStatus == "delivered") {
            common.alert('Status cannot be changed now', 'error');
            return;
        }
        if (!offlineSale && selfDeliver == 0 && orderStatus == "return_assigned" && returnLogisticsVendor == 0) {
            common.alert('Please select return logistics vendor first', 'error');
            return;
        }
        if (selfDeliver == 0 && orderStatus == "pick_up_assigned" && replaceLogisticsVendor == 0) {
            common.alert('Please select replace logistics vendor first', 'error');
            return;
        }
        if (!offlineSale && selfDeliver == 0 && orderStatus == "return_assigned" && returnLogisticsVendor && !allData.return_deliver_id) {
            common.alert('Please select return delivery user first', 'error');
            return;
        }
        if (!offlineSale && selfDeliver == 0 && orderStatus == "pick_up_assigned" && returnLogisticsVendor && !allData.return_deliver_id) {
            common.alert('Please select return delivery user first', 'error');
            return;
        }
        if (!offlineSale && selfDeliver == 0 && orderStatus == "re_delivery_assigned" && replaceLogisticsVendor && !allData.replace_deliver_id) {
            common.alert('Please select replace delivery user first', 'error');
            return;
        }
        if ((orderStatus == "returned" || orderStatus == "process_amount_to_wallet") && previousStatus == "replace_requested") {
            common.alert('Status cannot be changed to ' + orderStatus, 'error');
            return;
        }
        if (orderStatus == "process_amount_to_wallet" && previousStatus != "cancelled" && previousStatus != "returned") {
            common.alert('First please change the status to cancelled or returned', 'error');
            return;
        }
        if (previousStatus === "process_amount_to_wallet") {
            common.alert('Status cannot be changed now', 'error');
            return;
        }
        let data = {
            'action': 'update',
            'order_id': orderId,
            'order_status': orderStatus,
            'order_price': orderPrice,
            'user_id': userId,
            'self_deliver': Number(selfDeliver),
            'seller_address_id': Number(sellerAddressId)
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message.replaceAll("_", " "));
            getOrders();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const generateInvoice = async (sellerId, orderId, invoiceType) => {
        let response = await common.getInvoiceData([sellerId], orderId, invoiceType);
        if (response.length === 0) {
            common.alert('No invoice', 'error');
            return;
        }
        navigate(
            '/generate-invoice',
            {
                state: {
                    invoiceData: response
                }
            }
        )
    }

    const generateReturnReplaceCancelInvoice = async (orderId, sellerIds, type, invoiceType) => {
        let response = await common.getInvoiceData(sellerIds, orderId, type, 1);
        navigate(
            '/generate-return-invoice',
            {
                state: {
                    invoiceData: response,
                    invoiceType: invoiceType
                }
            }
        )
    }

    const uploadCancelReplaceReturnImages = async (event, orderItemId) => {
        const files = event?.target?.files;
        if (files.length > 0) {
          await common.uploadOrderImages(files, orderItemId, 'seller', 'seller_order', 1);
        } else {
          common.alert("No images to upload", "error");
        }
        $('#order_images').val(null);
    }

    const showOrderImages = async (orderItemId) => {
        let response = await common.fetchOrderImages(orderItemId);
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            setOrderImagesData(response.data.data);
            setShowOrderImagesModal(true);
        } else {
            setOrderImagesData([]);
            setShowOrderImagesModal(false);
            common.alert("No Order Images Found", "info");
        }
    }

    const downloadManifestOrLabel = (labelUrlOrAwb, viaAPI = false, orderItemId = 0, columnName = null) => {
        if (!labelUrlOrAwb) {
            common.alert("Something went wrong", "error");
            return;
        }
        if (!viaAPI) {
            const link = document.createElement('a');
            link.href = labelUrlOrAwb;
            link.target = '_blank';
            link.download = labelUrlOrAwb.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            if (labelUrlOrAwb) {
                let data = {
                    awb: labelUrlOrAwb,
                    id: orderItemId,
                    columnName: columnName
                }
                common.apiRequest('post', '/orders/download-ecom-label', data)
                .then(response => {
                    const link = document.createElement('a');
                    link.href = envConfig.api_url + response.data.url;
                    link.target = '_blank';
                    link.download = labelUrlOrAwb+'.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.error('Error downloading label:', error);
                    common.alert('Error downloading label: ' + error.message, "error");
                });
            } else {
                common.alert('AWB is not available.', "error");
            }
        }
    };

    const assignDeliver = async (orderId, deliverId, columnName, sellerId = 0, mainOrderId = 0, selfDeliver = 0, sellerAddressId = 0) => {
        let data = {
            'action': 'update',
            'id': orderId,
            'seller_id': sellerId,
            'order_id': mainOrderId,
            [columnName]: parseInt(deliverId),
            'self_deliver': selfDeliver,
            'seller_address_id': sellerAddressId
        }
        let response = await common.apiRequest('post', '/delivery/assign-deassign-orders', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            getOrders();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const updateReturnReplaceLogisticsVendor = async (updateDb, data, type) => {
        if (updateDb) {
            let price = 0, isThirdParty = 0, thirdPartyName = null, thirdPartyData = null;
            if (type == -2) {
                price = Number(data.total_price);
                isThirdParty = 1;
                thirdPartyName = 'ecomexpress';
                thirdPartyData = data.data[0]['vendors']
            } else if (type != -1) {
                price = Number(data.total_price[type]);
                isThirdParty = 1;
                thirdPartyName = 'shiprocket';
                thirdPartyData = data.data[0]['vendors'][type];
            } else {
                price = data?.seller_service?.price;
            }
            let params = {
                "order_item_id": showLogisticsVendor.orderItemId,
                "order_id": showLogisticsVendor.orderId,
                "type": showLogisticsVendor.type,
                "is_third_party_logistics": isThirdParty,
                "third_party_logistics_name": thirdPartyName,
                "third_party_logistics_data": thirdPartyData,
                "seller_address_id": showLogisticsVendor.sellerAddressId,
                "seller_id": showLogisticsVendor.sellerId,
                "self_deliver": showLogisticsVendor.self_deliver,
                "order_status": showLogisticsVendor.order_status,
                "logistic_order": {
                    "type": showLogisticsVendor.type,
                    "order_item_id": showLogisticsVendor.orderItemId,
                    "seller_id": data?.seller_service?.seller_id || 0,
                    "service_id": data?.seller_service?.id || 0,
                    "order_price": price,
                    "zone": supplyVendors.productItemData[0]['zone'],
                    "distance": supplyVendors.productItemData[0]['distance'],
                    "tax_type": supplyVendors.productItemData[0]['type'],
                    "order_id": showLogisticsVendor.orderId
                },
                "order_item": {
                    [showLogisticsVendor.type + "_logistics_vendor"]: data?.seller_service?.seller_id || 0
                }
            }
            setShowLogisticsVendor({
                show: false,
                orderItemId: 0,
                orderId: 0,
                type: null,
                sellerAddressId: 0,
                sellerId: 0,
                self_deliver: 0,
                order_status: null
            });
            setSupplyVendors({
                vendors: [],
                productItemData: []
            });
            let response = await common.apiRequest('post', '/orders/update-return-replace-logistics-vendors', params);
            if (response.data.code === 'success') {
                common.alert(response.data.message);
            } else {
                common.alert(response.data.error, 'error');
            }
            getOrders();
        } else {
            let userAddressId = data.order.address_id;
            let params = {
                'seller_id': Number(data.seller_id),
                'order_item_id': Number(data.id),
                'order_id': Number(data.order.id),
                'user_address_id': userAddressId,
                'product_item_data': [],
                "logistics_delivery_type": ["Normal Delivery", "Express Delivery"],
                "is_return": type == "return" ? 1 : 0,
                "is_replace": type == "replace" ? 1: 0,
                "declared_value": 0,
                "self_deliver": data.product_item.self_deliver,
                "seller_address_id": data.product_item.product.seller_address_gst
            }
            let response = await common.apiRequest('post', '/products/check-product-delivery-vendors', params);
            if (response.data.code === 'success') {
                setShowLogisticsVendor({
                    show: true,
                    orderItemId: data?.id,
                    orderId: data.order?.id,
                    type: type,
                    sellerAddressId: Number(data.product_item.product.seller_address_gst),
                    sellerId: Number(data.seller_id),
                    self_deliver: Number(data.product_item.self_deliver),
                    order_status: data.order_status
                });
                setSupplyVendors({
                    vendors: response.data.data.vendors,
                    productItemData: response.data.data.product_item_data,
                    thirdPartyVendors: response.data.data.third_party_vendors
                });
            } else {
                setShowLogisticsVendor({
                    show: false,
                    orderItemId: 0,
                    orderId: 0,
                    type: null,
                    sellerAddressId: 0,
                    sellerId: 0,
                    self_deliver: 0,
                    order_status: null
                });
                setSupplyVendors({
                    vendors: [],
                    productItemData: []
                });
                common.alert(response.data.error, 'error');
            }
        }
    }

    const data = orders.map(data => ({
        orderId: data.order?.order_id || "NA",
        orderType: data.product_item 
            ? "Online Product Sale" 
            : data.seller_service 
                ? "Online Service Sale" 
                : "Offline Product Sale",
        sellerLogisticsBothOrder: (() => {
            const isLogistics = data.logistics_vendor_id == localStorage.getItem('userId');
            const isSeller = data.seller_id == localStorage.getItem('userId');
            const isOfflineSale = data.offline_sale_product_datum?.offline_sale?.seller_id == localStorage.getItem("userId");
            
            if ((isLogistics && isSeller) || isOfflineSale) return "Both";
            if (isLogistics) return "Logistics";
            return "Seller";
        })(),
        "Product Name": data.product_item 
            ? data.product_item.product.name 
            : data.seller_service 
                ? data.seller_service.service_name 
                : data.offline_sale_product_datum?.product_description || "NA",
        "product Description": data.product_item 
            ? data.product_item.product.description 
            : data.seller_service 
                ? data.seller_service.service_description 
                : data.offline_sale_product_datum?.product_description || "NA",
        "User InvoiceNo": data.order?.invoices?.find(inv => inv.invoice_type === 'product')?.invoice_no || "NA",
        "seller InvoiceNo": data.order?.invoices?.find(inv => 
            inv.invoice_type === 'seller_product')?.invoice_no || "NA",
            "Logistics Seller InvoiceNo": (() => {
                if (data.logistics_order?.all_logistics_invoice?.length > 0) {
                    const logisticInvoice = data.logistics_order.all_logistics_invoice.find(
                        inv => inv.invoice_type === 'logistics'
                    );
                    return logisticInvoice ? logisticInvoice.invoice_no : "NA";
                }
                return "NA";
            })(),
            
            "Logistics Price": (() => {
                if (data.logistics_order?.all_logistics_invoice?.length > 0) {
                    const logisticInvoice = data.logistics_order.all_logistics_invoice.find(
                        inv => inv.invoice_type === 'logistics'
                    );
                    
                    if (logisticInvoice && logisticInvoice.price) {
                        const itemCount = data.logistics_order.order_item_id ? 
                            data.logistics_order.order_item_id.split(',').length : 1;
                        
                        const pricePerItem = parseFloat(logisticInvoice.price) / itemCount;
                        
                        return pricePerItem.toFixed(2);
                    }
                }
                return 0;
            })(),
            
            "Logistics Provider": (() => {
                if (data.logistics_order?.seller_service?.user?.user_business?.business_name) {
                    return data.logistics_order.seller_service.user.user_business.business_name;
                }
                
                if (data.order_id && data.logistics_vendor_id > 0) {
                    const allOrders = orders;
                    const relatedOrder = allOrders.find(
                        o => o.order_id === data.order_id && 
                            o.logistics_order?.seller_service?.user?.user_business?.business_name
                    );
                    
                    if (relatedOrder) {
                        return relatedOrder.logistics_order.seller_service.user.user_business.business_name;
                    }
                }
                
                return "NA";
            })(),
            "Order Price": data.order_price || 0,
            "Tax Rate": data.offline_sale_product_datum 
            ? (Number(data.offline_sale_product_datum.cgst_rate || 0) + 
               Number(data.offline_sale_product_datum.sgst_utgst_rate || 0) + 
               Number(data.offline_sale_product_datum.igst_rate || 0))
            : Number(data.tax_percent || 0),
                    "Taxable Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.taxable_supply || 0
            : data.taxable_amount || 0,
        "Igst Rate": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.igst_rate || 0
            : data.igst_percent || 0,
        "Igst Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.igst_amount || 0
            : data.igst || 0,
        "Sgst Rate": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.sgst_utgst_rate || 0
            : data.sgst_percent || 0,
        "Sgst Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.sgst_utgst_amount || 0
            : data.sgst || 0,
        "Cgst Rate": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.cgst_rate || 0
            : data.cgst_percent || 0,
        "Cgst Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.cgst_amount || 0
            : data.cgst || 0,
        "Unit Price": data.product_item?.price || data.seller_service?.price || data.offline_sale_product_datum?.price || 0,
        "Order Quantity": data.order_quantity || 0,
        "Order Status": data.order_status || "",
        "Order Date": data.order?.created_at,
        "Return CGST Amount": data.rcgst || 0,
        "Return CGST Rate": data.rcgst_percent || 0,
        "Return GST Rate": data.rgst_rate || 0,
        "Return IGST Amount": data.rigst || 0,
        "Return IGST Rate": data.rigst_percent || 0,
        "Return IRN": data.rirn || "",
        "Return IRN Date": data.rirn_date || "",
        "Credit Note Date": data.rnote_date || "",
        "Credit Note No": data.rnote_no || "",
        "Credit Note Supply Type": data.rnote_supply_type || "",
        "Credit Note Type": data.rnote_type || "",
        "Credit Note Value": data.rnote_value || 0,
        "Return SGST Amount": data.rsgst || 0,
        "Return SGST Rate": data.rsgst_percent || 0,
        "Return Taxable Value": data.rtaxable_value || 0,
        "Return UTGST Amount": data.rutgst || 0,
        "Return UTGST Rate": data.rutgst_percent || 0,
        "Cancel Note No": data.cancel_note_no || "",
        "Cancel Note Type": data.cancel_note_type || "",
        "Cancel Note Date": data.cancel_note_date || "",
        "Cancel Note Value": data.cancel_note_value || 0,
        "Cancel GST Rate": data.cancel_gst_rate || 0,
        "Cancel Taxable Value": data.cancel_taxable_value || 0,
        "Cancel IGST": data.cancel_igst || 0,
        "Cancel IGST %": data.cancel_igst_percent || 0,
        "Cancel CGST": data.cancel_cgst || 0,
        "Cancel CGST %": data.cancel_cgst_percent || 0,
        "Cancel SGST": data.cancel_sgst || 0,
        "Cancel SGST %": data.cancel_sgst_percent || 0,
        "Cancel UTGST": data.cancel_utgst || 0,
        "Cancel UTGST %": data.cancel_utgst_percent || 0,        
        "Cancel IRN": data.cancel_irn || 0,
    }));
    
    const handleExportCSV = () => {
        exportToCSV(data, 'orders.csv');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'orders.xlsx');
    }

    const handleExportPDF = () => {
        exportToPDF(data, 'orders.pdf');
    }

    const columnDefs = useMemo(() => [
        { field: 'order.order_id', headerName: 'Order ID', checkboxSelection: true },
        { 
            field: 'orderType', 
            headerName: 'Order Type',
            valueGetter: params => 
                params.data.product_item ? "Online Product Sale" : 
                params.data.seller_service ? "Online Service Sale" : 
                "Offline Product Sale"
        },
        {
            field: 'order.created_at',
            headerName: 'Order Date',
        },
        { 
            field: 'productName', 
            headerName: 'Product Name',
            valueGetter: params => 
                params.data.product_item ? params.data.product_item.product.name : 
                params.data.seller_service ? params.data.seller_service.service_name : 
                params.data.offline_sale_product_datum ? params.data.offline_sale_product_datum.product_description : 
                "NA"
        },
        { 
            field: 'productDescription', 
            headerName: 'Product Description',
            valueGetter: params => 
                params.data.product_item ? params.data.product_item.product.description : 
                params.data.seller_service ? params.data.seller_service.service_description : 
                params.data.offline_sale_product_datum ? params.data.offline_sale_product_datum.product_description : 
                "NA"
        },
        { 
            field: 'userInvoiceNo', 
            headerName: 'User Invoice No',
            valueGetter: params => {
                if (params.data.order && params.data.order.invoices) {
                    const invoice = params.data.order.invoices.find(inv => inv.invoice_type === 'product');
                    return invoice ? invoice.invoice_no : "NA";
                }
                return "NA";
            }
        },
        { 
            field: 'sellerInvoiceNo', 
            headerName: 'seller Invoice No',
            valueGetter: params => {
                if (params.data.order && params.data.order.invoices) {
                    const invoice = params.data.order.invoices.find(inv => inv.invoice_type === 'seller_product');
                    return invoice ? invoice.invoice_no : "NA";
                }
                return "NA";
            }
        },
        {
            field: 'rcgst',
            headerName: 'Return CGST Amount',
            valueGetter: params => parseValue(params.data.rcgst || 0,'float')
        },
        {
            field: 'rcgst_percent',
            headerName: 'Return CGST Rate',
            valueGetter: params => params.data.rcgst_percent || 0
        },
        {
            field: 'rgst_rate',
            headerName: 'Return GST Rate',
            valueGetter: params => parseValue(params.data.rgst_rate || 0,'float')
        },
        {
            field: 'rigst',
            headerName: 'Return IGST Amount',
            valueGetter: params => parseValue(params.data.rigst || 0,'float')
        },
        {
            field: 'rigst_percent',
            headerName: 'Return IGST Rate',
            valueGetter: params => params.data.rigst_percent || 0
        },
        {
            field: 'rirn',
            headerName: 'Return IRN',
            valueGetter: params => params.data.rirn || ''
        },
        {
            field: 'rirn_date',
            headerName: 'Return IRN Date',
            valueGetter: params => params.data.rirn_date || ''
        },
        {
            field: 'rnote_date',
            headerName: 'Credit Note Date',
            valueGetter: params => params.data.rnote_date || ''
        },
        {
            field: 'rnote_no',
            headerName: 'Credit Note No',
            valueGetter: params => params.data.rnote_no || ''
        },
        {
            field: 'rnote_supply_type',
            headerName: 'Credit Note Supply Type',
            valueGetter: params => params.data.rnote_supply_type || ''
        },
        {
            field: 'rnote_type',
            headerName: 'Credit Note Type',
            valueGetter: params => params.data.rnote_type || ''
        },
        {
            field: 'rnote_value',
            headerName: 'Credit Note Value',
            valueGetter: params => parseValue(params.data.rnote_value || 0,'float')
        },
        {
            field: 'rsgst',
            headerName: 'Return SGST Amount',
            valueGetter: params => parseValue(params.data.rsgst || 0,'float')
        },
        {
            field: 'rsgst_percent',
            headerName: 'Return SGST Rate',
            valueGetter: params => params.data.rsgst_percent || 0
        },
        {
            field: 'rtaxable_value',
            headerName: 'Return Taxable Value',
            valueGetter: params => parseValue(params.data.rtaxable_value || 0,'float')
        },
        {
            field: 'rutgst',
            headerName: 'Return UTGST Amount',
            valueGetter: params => parseValue(params.data.rutgst || 0,'float')
        },
        {
            field: 'rutgst_percent',
            headerName: 'Return UTGST Rate',
            valueGetter: params => params.data.rutgst_percent || 0
        },
        {
            field: 'LogisticsInvoiceNo',
            headerName: 'Logistics InvoiceNo',
            valueGetter: params => {
                if (params.data.logistics_order && params.data.logistics_order.all_logistics_invoice) {
                    const logisticInvoice = params.data.logistics_order.all_logistics_invoice.find(
                        inv => inv.invoice_type === 'logistics'
                    );
                    return logisticInvoice ? logisticInvoice.invoice_no : "NA";
                }
                return "NA";
            }
        },
        {
            field: 'LogisticsPrice',
            headerName: 'Logistics Price',
            valueGetter: params => {
                if (params.data.logistics_order && params.data.logistics_order.all_logistics_invoice) {
                    const logisticInvoice = params.data.logistics_order.all_logistics_invoice.find(
                        inv => inv.invoice_type === 'logistics'
                    );
                    
                    if (logisticInvoice && logisticInvoice.price) {
                        const itemCount = params.data.logistics_order.order_item_id ? 
                            params.data.logistics_order.order_item_id.split(',').length : 1;
                        
                        const pricePerItem = parseValue(logisticInvoice.price, "float") / itemCount;
                        
                        return pricePerItem.toFixed(2); 
                    }
                }
                return "NA";
            }
        },
        {
            field: 'LogisticsProvider',
            headerName: 'Logistics Provider',
            valueGetter: params => {
                if (params.data.logistics_order?.seller_service?.user?.user_business?.business_name) {
                    return params.data.logistics_order.seller_service.user.user_business.business_name;
                }
                
                if (params.data.order_id && params.data.logistics_vendor_id > 0) {
                    const allRows = params.api.getModel().rowsToDisplay;
                    const relatedRow = allRows.find(row => 
                        row.data?.logistics_order_id === params.data.logistics_order_id && 
                        row.data.logistics_order?.seller_service?.user?.user_business?.business_name
                    );
                    
                    if (relatedRow) {
                        return relatedRow.data.logistics_order.seller_service.user.user_business.business_name;
                    }
                }
                
                return "NA";
            }
        },
        {
            field: 'order_price', headerName:'Order Price', valueGetter: params => parseValue(params.data.order_price || 0,'float')
        },
        {
            field: 'tax_percent',
            headerName: 'Tax Rate',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    const total = Number(params.data.offline_sale_product_datum.cgst_rate || 0) +
                                 Number(params.data.offline_sale_product_datum.sgst_utgst_rate || 0) +
                                 Number(params.data.offline_sale_product_datum.igst_rate || 0);
                    return total;
                }
                return params.data.tax_percent || 0;
            }
        },
        {
            field: 'taxable_amount',
            headerName: 'Taxable Amount',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return parseValue(params.data.offline_sale_product_datum.taxable_supply || 0,'float');
                }
                return parseValue(params.data.taxable_amount || 0,'float');
            }
        },
        {
            field: 'igst_percent',
            headerName: 'IGST Rate',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return params.data.offline_sale_product_datum.igst_rate || 0;
                }
                return params.data.igst_percent || 0;
            }
        },
        {
            field: 'igst',
            headerName: 'IGST Amount',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return parseValue(params.data.offline_sale_product_datum.igst_amount || 0,'float');
                }
                return params.data.igst || 0;
            }
        },
        {
            field: 'sgst_percent',
            headerName: 'SGST/UTGST Rate',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return params.data.offline_sale_product_datum.sgst_utgst_rate || 0;
                }
                return params.data.sgst_percent || 0;
            }
        },
        {
            field: 'sgst',
            headerName: 'SGST/UTGST Amount',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return parseValue(params.data.offline_sale_product_datum.sgst_utgst_amount || 0,'float');
                }
                return parseValue(params.data.sgst || 0,'float');
            }
        },
        {
            field: 'cgst_percent',
            headerName: 'CGST Rate',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return parseValue(params.data.offline_sale_product_datum.cgst_rate || 0,'float');
                }
                return parseValue(params.data.cgst_percent || 0,'float');
            }
        },
        {
            field: 'cgst',
            headerName: 'CGST Amount',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return parseValue(params.data.offline_sale_product_datum.cgst_amount || 0,'float');
                }
                return parseValue(params.data.cgst || 0,'float');
            }
        },
        { field: 'unit_price', headerName: 'Unit Price' },
        { field: 'order_quantity', headerName: 'Order Quantity' },
        { 
            field: 'order_status', 
            headerName: 'Order Status',
            cellRenderer: params => {
                const getStatusOptions = () => {
                    if (params.data.product_item ) {
                        if (orderStatuses.normal_online.includes(params.data.order_status)) {
                            return orderStatuses.online_product.normal[0];
                        } else if (orderStatuses.return_online.includes(params.data.order_status)) {
                            return orderStatuses.online_product.return[0];
                        } else if (orderStatuses.cancel_online.includes(params.data.order_status)) {
                            return orderStatuses.online_product.cancel[0];
                        } else if (orderStatuses.replace_online.includes(params.data.order_status)) {
                            return orderStatuses.online_product.replace[0];
                        }
                        return {};
                    } else if (params.data.offline_sale_product_datum) {
                        if (orderStatuses.normal_offline.includes(params.data.order_status)) {
                            return orderStatuses.offline_product.normal[0];
                        }
                        return {};
                    } else if (params.data.seller_service) {
                        if (orderStatuses.normal_service.includes(params.data.order_status)) {
                            return orderStatuses.service.normal[0];
                        }
                        return {};
                    }
                    return {};
                };

                const handleStatusChange = (role, selectedValue) => {
                    const statusOptions = getStatusOptions();
                    const currentStatus = statusOptions[selectedValue];
                    const canChange = currentStatus && currentStatus[`${role}_can_change`];
                    
                    if (!canChange) {
                        common.alert(`You are not liable to change to this status.`, 'error');
                        return;
                    } else if (!currentStatus.can_move_from.includes(params.value)) {
                        common.alert(`Status cannot move to this stage right now`, 'error');
                        return;
                    } else {
                        changeOrderStatus(
                            params.data.id,
                            selectedValue,
                            params.data.order_price,
                            params.data.order.user_id,
                            params.value,
                            params.data.return_logistics_vendor,
                            params.data.replace_logistics_vendor,
                            params?.data?.product_item?.self_deliver ?? 0,
                            params?.data?.product_item?.product?.seller_address_gst ?? 0,
                            params?.data?.offline_sale_product_datum?.id,
                            params?.data?.no_return,
                            params.data
                        );
                    }
                };

                return (
                    <select 
                        id="order_status" 
                        value={params.value} 
                        className="form-control" 
                        onChange={(e) => {handleStatusChange('seller', e.target.value)}} 
                        style={{width: '130px'}}
                    >
                        {Object.entries(getStatusOptions()).map(([key, value]) => (
                            <option key={key} value={key}>{value.status}</option>
                        ))}
                    </select>
                );
            }
        },
        { 
            headerName: 'Upload Order Images',
            cellRenderer: params => {
                return (
                    <input type='file' id="order_images" onChange={(e) => uploadCancelReplaceReturnImages(e, params.data?.id)} multiple/>
                );
            }
        },
        { 
            headerName: 'View Order Images',
            cellRenderer: params => (
                <button type='button' className='form-control btn btn-info' onClick={() => showOrderImages(params.data?.id)}>View Order Images</button>
            )
        },
        { field: 'return_reason', headerName: 'Return Reason' },
        { field: 'replace_reason', headerName: 'Replace Reason' },
        { field: 'cancel_reason', headerName: 'Cancel Reason' },
        { field: 'order_completed_on', headerName: 'Order Completed On' },
        { field: 'order_cancelled_on', headerName: 'Order Cancelled On' },
        { 
            field: 'payment_id', 
            headerName: 'Payment ID',
            valueGetter: params => params.data.order.payment_detail ? params.data.order.payment_detail.razorpay_payment_id : ""
        },
        { 
            field: 'payment_status', 
            headerName: 'Payment Status',
            valueGetter: params => params.data.order.payment_detail || params.data.wallet_amount ? "Success" : "Failed"
        },
        { 
            field: 'cancel_note_no', 
            headerName: 'Cancel Note No',
        },
        { 
            field: 'cancel_note_type', 
            headerName: 'Cancel Note Type',
        },
        { 
            field: 'cancel_note_date', 
            headerName: 'Cancel Note Date',
        },
        { 
            field: 'cancel_note_value', 
            headerName: 'Cancel Note Value',
        },
        { 
            field: 'cancel_gst_rate', 
            headerName: 'Cancel GST Rate',
        },
        { 
            field: 'cancel_taxable_value', 
            headerName: 'Cancel Taxable Value',
        },
        { 
            field: 'cancel_igst', 
            headerName: 'Cancel IGST',
        },
        { 
            field: 'cancel_igst_percent', 
            headerName: 'Cancel IGST %',
        },
        { 
            field: 'cancel_cgst', 
            headerName: 'Cancel CGST',
        },
        { 
            field: 'cancel_cgst_percent', 
            headerName: 'Cancel CGST %',
        },        
        { 
            field: 'cancel_sgst', 
            headerName: 'Cancel SGST',
        },
        { 
            field: 'cancel_sgst_percent', 
            headerName: 'Cancel SGST %',
        },
        { 
            field: 'cancel_utgst', 
            headerName: 'Cancel UTGST',
        },
        { 
            field: 'cancel_utgst_percent', 
            headerName: 'Cancel UTGST %',
        },
        { 
            field: 'cancel_irn', 
            headerName: 'Cancel IRN',
        },
        {
            headerName: 'Assign Delivery Person',
            cellRenderer: params => {
                if ((params.data.service_id || (params.data.product_id && params.data?.product_item?.self_deliver)) && params.data.order_status == "in_progress") {
                    return (
                        <select 
                            value={params.data.deliver_id} 
                            onChange={e => assignDeliver(params.data.id, e.target.value, 'deliver_id', params.data.seller_id, params.data.order_id, params.data.product_item?.self_deliver, params.data.product_item?.product?.seller_address_gst)}
                            className='form-control'
                        >
                            <option value="0">Select Delivery User</option>
                            {deliveryUsers.map(user => (
                                <option key={user?.id} value={user?.id}>
                                    {`${user.first_name} ${user.last_name} (${user.reg_id})`}
                                </option>
                            ))}
                        </select>
                    );
                } else if (params.data.deliver_id) {
                    let user = deliveryUsers.find((user) => user.id == params.data.deliver_id);
                    return user ? user.first_name + " " + user.last_name : "NA";
                } else {
                    return "NA";
                }
            }
        },
        {
            headerName: 'Assign Return Delivery Person',
            cellRenderer: params => {
                if ((params.data.order_status == "return_requested" || params.data.order_status == "replace_requested") && params.data.product_id && params.data?.product_item?.self_deliver) {
                    return (
                        <select 
                            value={params.data.return_deliver_id} 
                            onChange={e => assignDeliver(params.data?.id, e.target.value, 'return_deliver_id', params.data.seller_id, params.data.order_id, params.data.product_item.self_deliver, params.data.product_item?.product?.seller_address_gst)}
                            className='form-control'
                        >
                            <option value="0">Select Delivery User</option>
                            {deliveryUsers.map(user => (
                                <option key={user?.id} value={user?.id}>
                                    {`${user.first_name} ${user.last_name} (${user.reg_id})`}
                                </option>
                            ))}
                        </select>
                    );
                } else if (params.data.return_deliver_id) {
                    let user = deliveryUsers.find((user) => user.id == params.data.deliver_id);
                    return user ? user.first_name + " " + user.last_name : "NA";
                } else {
                    return "NA";
                }
            }
        },
        {
            headerName: 'Assign Replace Delivery Person',
            cellRenderer: params => {
                if ((params.data.order_status == "item_handover_to_seller") && params.data.product_id && params.data?.product_item?.self_deliver) {
                    return (
                        <select 
                            value={params.data.replace_deliver_id} 
                            onChange={e => assignDeliver(params.data?.id, e.target.value, 'replace_deliver_id', params.data.seller_id, params.data.order_id, params.data.product_item?.self_deliver, params.data.product_item?.product?.seller_address_gst)}
                            className='form-control'
                        >
                            <option value="0">Select Delivery User</option>
                            {deliveryUsers.map(user => (
                                <option key={user?.id} value={user?.id}>
                                    {`${user.first_name} ${user.last_name} (${user.reg_id})`}
                                </option>
                            ))}
                        </select>
                    );
                } else if (params.data.replace_deliver_id) {
                    let user = deliveryUsers.find((user) => user.id == params.data.deliver_id);
                    return user ? user.first_name + " " + user.last_name : "NA";
                } else {
                    return "NA";
                }
            }
        },
        {
            headerName: 'Select Return Logistics Vendor',
            cellRenderer: params => (
                params.data.product_id && params.data?.product_item?.self_deliver == 0 ? (
                params.data.return_logistics_vendor ? 
                params.data.return_logistics_seller.first_name + " " + params.data.return_logistics_seller.last_name || "NA"
                :
                params.data.order_status == "return_requested" || params.data.order_status == "replace_requested"?
                <>
                    <button className="btn btn-outline-primary btn-sm" onClick={() => updateReturnReplaceLogisticsVendor(0, params.data, 'return')}>
                        Select Return Logistics Vendor
                    </button>
                </> : "NA") : "NA"
            )
        },
        {
            headerName: 'Select Replace Logistics Vendor',
            cellRenderer: params => (
                params.data.product_id && params.data?.product_item?.self_deliver == 0 ? (
                params.data.replace_logistics_vendor ? 
                params.data.replace_logistics_seller.first_name + " " + params.data.replace_logistics_seller.last_name || "NA" :
                params.data.order_status == "item_handover_to_seller" ?
                <>
                    <button className="btn btn-outline-primary btn-sm" onClick={() => updateReturnReplaceLogisticsVendor(0, params.data, 'replace')}>
                        Select Replace Logistics Vendor
                    </button>
                </> : "NA") : "NA"
            )
        },
        {
            headerName: 'Download Manifest',
            cellRenderer: params => (
                params.data.third_party_logistics_partner_name && params.data.third_party_logistics_partner_name == "shiprocket" ?
                    <>
                        <button 
                                className="btn btn-outline-success btn-sm usercardbtn1" 
                                onClick={() => downloadManifestOrLabel(params?.data?.manifest_url || params?.data?.replace_manifest_url || null)} 
                        >
                            Download
                        </button>
                    </>
                :
                "NA"
            )
        },
        {
            headerName: 'Download Label',
            cellRenderer: params => (
                params.data.third_party_logistics_partner_name ? (
                    params.data.third_party_logistics_partner_name === "shiprocket" && params.data.label_url ? (
                        <>
                            <button 
                                className="btn btn-outline-success btn-sm usercardbtn1" 
                                onClick={() => downloadManifestOrLabel(params.data.label_url || params.data.label_url || null)} 
                            >
                                Download
                            </button>
                        </>
                    ) : params.data.third_party_logistics_partner_name === "ecomexpress" && params.data.awb ? (
                        <>
                            <button 
                                className="btn btn-outline-success btn-sm usercardbtn1" 
                                onClick={() => downloadManifestOrLabel(params?.data?.awb || null, true, params.data.id, 'label_url')}
                            >
                                Download
                            </button>
                        </>
                    ) : "NA"
                ) : "NA"
            )
        },
        {
            headerName: 'Download Replace Label',
            cellRenderer: params => (
                params.data.third_party_logistics_partner_name ? (
                    params.data.third_party_logistics_partner_name === "shiprocket" && params.data.replace_label_url ? (
                        <>
                            <button 
                                className="btn btn-outline-success btn-sm usercardbtn1" 
                                onClick={() => downloadManifestOrLabel(params.data.replace_label_url || null)} 
                            >
                                Download
                            </button>
                        </>
                    ) : params.data.third_party_logistics_partner_name === "ecomexpress" && params.data.replace_awb ? (
                        <>
                            <button 
                                className="btn btn-outline-success btn-sm usercardbtn1" 
                                onClick={() => downloadManifestOrLabel(params?.data?.replace_awb || null, true, params.data.id, 'replace_label_url')}
                            >
                                Download
                            </button>
                        </>
                    ) : "NA"
                ) : "NA"
            )
        },
        {
            headerName: 'Generate Invoice',
            cellRenderer: params => (
                <>
                    <button className="btn btn-outline-success btn-sm usercardbtn1" onClick={() => generateInvoice(params.data.seller_id, params.data.order?.id, ['seller_product'])}>
                        Invoice Generate
                    </button>
                </>
            )
        },
        {
            headerName: 'Generate Return Invoice',
            cellRenderer: params => (
                <>
                    { params.data.order_returned_on ? 
                    <button className="btn btn-outline-success btn-sm" onClick={() => generateReturnReplaceCancelInvoice(params.data.id, [params.data.seller_id, params.data.order.user_id], ['return_order', 'return_order_logistics'], 'Return')}>
                    Return Invoice Generate
                    </button> : null}
                </>
            )
        },
        {
            headerName: 'Generate Replace Invoice',
            cellRenderer: params => (
                <>
                    { params.data.order_replaced_on ? 
                    <button className="btn btn-outline-success btn-sm" onClick={() => generateReturnReplaceCancelInvoice(params.data.id, [params.data.seller_id], ['replace_order_logistics', 'return_order_logistics'], 'Replace')}>
                    Replace Invoice Generate
                    </button> : null}
                </>
            )
        },
        {
            headerName: 'Generate Cancel Invoice',
            cellRenderer: params => (
                <>
                    { params.data.order_cancelled_on ? 
                    <button className="btn btn-outline-success btn-sm" onClick={() => generateReturnReplaceCancelInvoice(params.data.id, [params.data.seller_id], ['cancel_order_seller'], 'Cancel')}>
                    Cancel Invoice Generate
                    </button> : null}
                </>
            )
        }
    ], [deliveryUsers, orderStatuses]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);

    return (
        <>
            <div className="row">
                <div className='col-md-6'>
                    <h3>Orders</h3>
                </div>
                <div className='col-md-6'>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                </div>
            </div>
                    <Form className='row ml-2'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getOrders} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getOrders} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                        <AgGridReact
                            rowData={orders}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[10, 25, 50]}
                        />
                    </div>
                </div>
            </div>

            <Modal isOpen={showOrderImagesModal} toggle={() => setShowOrderImagesModal(!showOrderImagesModal)} size="lg">
                <ModalHeader toggle={() => setShowOrderImagesModal(!showOrderImagesModal)}>Order Images</ModalHeader>
                <ModalBody>
                    {
                        orderImagesData && orderImagesData.length > 0 ?
                        <table>
                            <thead>
                                <th>Photo</th>
                                <th>Uploaded By</th>
                                <th>Type</th>
                                <th>Upload Date Time</th>
                            </thead>
                            <tbody>
                            {
                                orderImagesData.map((imageData) => {
                                    return (
                                    <>
                                        <tr>
                                            <td><img src={envConfig.api_url + imageData.photo} style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></td>
                                            <td>{imageData.uploaded_via}</td>
                                            <td>{imageData.type}</td>
                                            <td>{imageData.created_at}</td>
                                        </tr>
                                    </>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        :
                        <h4>No Order Images</h4>
                    }
                </ModalBody>
            </Modal>

            <Modal isOpen={showLogisticsVendor.show} toggle={() => setShowLogisticsVendor({show: !showLogisticsVendor.show, orderItemId: 0, type: null, orderId: 0, sellerAddressId: 0, sellerId: 0, self_deliver: 0, order_status: null})} size="lg">
                <ModalHeader toggle={() => setShowLogisticsVendor({show: !showLogisticsVendor.show, orderItemId: 0, type: null, orderId: 0, sellerAddressId: 0, sellerId: 0, self_deliver: 0, order_status: null})}>Logistics Vendors</ModalHeader>
                <ModalBody>
                    <div 
                        className="col-md-12" 
                        style={{ 
                            height: '250px',            
                            overflowY: 'scroll',        
                            border: '1px solid #ddd',   
                            marginBottom: '15px'
                        }}
                    >
                        {(supplyVendors.vendors && supplyVendors.vendors.length > 0) ? supplyVendors.vendors.map(vendor => (
                            <div className="row">
                                <div className="col-md-12">
                                    <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer',padding: '8px'}}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="logistics_vendor_id"
                                            onClick={() => updateReturnReplaceLogisticsVendor(1, vendor, -1)}
                                        />
                                        <span style={{ marginLeft: '8px' }}>Rs. {vendor.seller_service.price}</span>&nbsp;
                                        <span>
                                            Between {vendor.seller_service.min_delivery_time} and {vendor.seller_service.max_delivery_time} ({vendor.seller_service.logistics_delivery_type})
                                        </span>
                                    </label>
                                    <br />
                                </div>
                            </div>
                        )) : ""}
                        
                        {/* Render third-party vendors if they exist */}
                        {(supplyVendors.thirdPartyVendors && (supplyVendors.thirdPartyVendors.shiprocket?.data || supplyVendors.thirdPartyVendors.ecomexpress?.data)) && (
                            <>
                                {/* Render Ecomexpress vendors */}
                                {supplyVendors.thirdPartyVendors.ecomexpress?.data?.length > 0 && (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '8px' }}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="logistics_vendor_id"
                                                    onClick={() => updateReturnReplaceLogisticsVendor(1, supplyVendors.thirdPartyVendors.ecomexpress, -2)}
                                                />
                                                <span style={{ marginLeft: '8px' }}>Rs. {supplyVendors.thirdPartyVendors.ecomexpress.total_price} (Ecom Express)</span>&nbsp;
                                            </label>
                                            <br />
                                        </div>
                                    </div>
                                )}

                                {/* Render Shiprocket vendors */}
                                {supplyVendors.thirdPartyVendors.shiprocket?.data?.length > 0 && supplyVendors.thirdPartyVendors.shiprocket.total_price.map((price, index) => (
                                    <div className="row" key={index}>
                                        <div className="col-md-12">
                                            <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '8px' }}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="logistics_vendor_id"
                                                    onClick={() => updateReturnReplaceLogisticsVendor(1, supplyVendors.thirdPartyVendors.shiprocket, index)}
                                                />
                                                <span style={{ marginLeft: '8px' }}>Rs. {price}</span>&nbsp;
                                            </label>
                                            <br />
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}