import React, { useEffect, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';

const common = require('../../utils/Common');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const QueryView = () => {
    const [QueryData, setQueryData] = useState([]);
    const [replyData, setReplyData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalTwo, setModalTwo] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);  
    const [QueryReply, setQueryReply] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); 
    const [selectedDetails, setSelectedDetails] = useState(null);  
    const navigate = useNavigate();

    const toggle = () => setModal(!modal);
    const toggleAnother = () => setModalTwo(!modalTwo);
    const toggleDetails = () => setDetailsModal(!detailsModal); 

    useEffect(() => {
        getQuery();
    }, []);

    const getQuery = async () => {
        const parameters = {
            source: 'user',
            'user_id': localStorage.getItem('userId'),
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
        };
        let response = await common.apiRequest('post', '/users/QueryDetails', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setQueryData(data);
    };

    const invoiceId = replyData?.order_item?.order?.invoices?.[0]?.id;
    const invoiceNO = replyData?.order_item?.order?.invoices?.[0]?.invoice_no;

    const reply = async () => {
        const parameters = {
            query_id: replyData.id,
            query_reply: QueryReply,
            invoice_id: invoiceId,
            query_reply_by: "seller",
            source: "admin",
            active_status: 1
        };

        let response = await common.apiRequest('post', '/users/user-Query', parameters);
        if (response.status === 200) {
            if (response.data.code === "success") {
                alert(response.data.message);
            } else {
                alert(response.data.error);
            }
            window.location.reload();
        }
    };

    const replyQuery = (data) => {
        setReplyData(data);
        toggle();
    };

    const showQuery = (data) => {
        setReplyData(data);
        toggleAnother();
    };

    const showDetails = (data) => {
        setSelectedDetails(data);  
        toggleDetails();
    };

    const columnDefs = useMemo(() => [
        { headerName: "S.no", valueGetter: "node.rowIndex + 1", width: 80 },
        { 
            headerName: "Invoice No", 
            valueGetter: params => {
                const invoices = params.data?.order_item?.order?.invoices || [];
                const sellerProductInvoice = invoices.find(invoice => invoice.invoice_type === 'seller_product' && invoice.seller_id == params.data.seller_id);
                return sellerProductInvoice?.invoice_no || "N/A";
              },
                width: 150 
        },
        { field: "order_item.order.order_id", headerName: "Order Id", sortable: true, filter: true },
        { 
            headerName: "User Query", 
            field: "user_query", 
            width: 200 
        },
        { 
            headerName: "Date", 
            valueGetter: params => moment(params.data.created_at).format('YYYY-MM-DD') || "N/A", 
            width: 150 
        },
        { 
            headerName: "Category", 
            field: "type", 
            width: 150 
        },
        {
            headerName: "Response from vendor",
            field: "vendor_response",
            valueGetter: params => {
                const vendorResponses = params.data.querys_replys
                    .filter(reply => reply.query_reply_by === 'seller')
                    .map(reply => reply.query_reply);
                    
                if (vendorResponses.length === 0) return "No response";
                
                return vendorResponses.join('\n---\n');
            },
            wrapText: true,
            autoHeight: true,
            cellStyle: {
                'white-space': 'pre-wrap',
                'max-height': '200px',
                'overflow-y': 'auto'
            }
        },
        {
            headerName: "Date of response (Vendor)", 
            field: "vendor_response_date",
            valueGetter: params => {
                const vendorResponses = params.data.querys_replys
                    .filter(reply => reply.query_reply_by === 'seller')
                    .map(reply => reply.created_at);
                    
                if (vendorResponses.length === 0) return "N/A";
                
                return vendorResponses.join('\n---\n');
            },
            wrapText: true,
            autoHeight: true,
            cellStyle: {
                'white-space': 'pre-wrap',
                'max-height': '200px',
                'overflow-y': 'auto'
            }
        },
        {
            headerName: "Response from Admin",
            field: "admin_response",
            valueGetter: params => {
                const adminResponses = params.data.querys_replys
                    .filter(reply => reply.query_reply_by === 'Admin')
                    .map(reply => reply.query_reply);
                    
                if (adminResponses.length === 0) return "No response";
                
                return adminResponses.join('\n---\n');
            },
            wrapText: true,
            autoHeight: true,
            cellStyle: {
                'white-space': 'pre-wrap',
                'max-height': '200px',
                'overflow-y': 'auto'
            }
        },
        {
            headerName: "Date of response (Admin)", 
            field: "vendor_response_date",
            valueGetter: params => {
                const adminResponses = params.data.querys_replys
                    .filter(reply => reply.query_reply_by === 'Admin')
                    .map(reply => reply.created_at);
                    
                if (adminResponses.length === 0) return "N/A";
                return adminResponses.join('\n---\n');
            },
            wrapText: true,
            autoHeight: true,
            cellStyle: {
                'white-space': 'pre-wrap',
                'max-height': '200px',
                'overflow-y': 'auto'
            }
        },
    ], []);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);

    const rowData = QueryData.map((data, index) => {
        const vendorResponses = data.querys_replys?.filter(reply => reply.query_reply_by === 'seller') || [];
        const adminResponses = data.querys_replys?.filter(reply => reply.query_reply_by === 'Admin') || [];
    
        return {
            ...data,
            vendorResponses: vendorResponses.length > 0 
                ? vendorResponses.map(r => r.query_reply).join('\n---\n')
                : "No response",
            vendorResponseDates: vendorResponses.length > 0
                ? vendorResponses.map(r => r.created_at).join('\n')
                : "N/A",
            adminResponses: adminResponses.length > 0
                ? adminResponses.map(r => r.query_reply).join('\n---\n')
                : "No response",
            adminResponseDates: adminResponses.length > 0
                ? adminResponses.map(r => r.created_at).join('\n')
                : "N/A"
        };
    });
    
    const data = QueryData.map((data, index) => ({
        "Reg id": data.user.reg_id,
        "Invoice no": data.order_item?.order?.invoices?.find(invoice => invoice.invoice_type === 'seller_product' && invoice.seller_id == data.seller_id)?.invoice_no,
        "Order Id":data.order_item?.order?.order_id,
        "User Query": data.user_query,
        "Created at": data.created_at,
        "Type": data.type,
        "Vendor Response": rowData[index]?.vendorResponses,
        "Vendor Response Date": rowData[index]?.vendorResponseDates,
        "Admin Response": rowData[index]?.adminResponses,
        "Admin ResponseDate": rowData[index]?.adminResponseDates,
    }));

    const handleExportCSV = () => {
        exportToCSV(data, 'Query');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'Query');
    }
    
    const handleExportPDF = () => {
        exportToPDF(data, 'Query');
    }
    return (
        <>
          <div className="container-fluid">
                <div className="row">
                    <ol className="col-12 breadcrumb bg-transparent">
                        <li className="breadcrumb-item"><Link to="/" className="text-dark" id="bccap">Home</Link></li>
                        <li className="breadcrumb-item active"><Link to="/my-account" className="text-dark" id="bccap">user-account</Link></li>
                        <li className="breadcrumb-item active"><Link to="#" className="text-dark" id="bccap">Query</Link></li>
                    </ol>
                </div>
            </div>
            <div className='Query-component'>
                <div>
                    <div className='Query-header col-md-12'>
                        <h2>Users Query Replys</h2>
                    </div>
                    <div className="col-md-12">
                        <Form className='row'>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getQuery} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                            </FormGroup>
                            <FormGroup className='col-md-4 row'>
                                <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                                <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getQuery} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                            </FormGroup>
                        </Form>
                        <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
         <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
         <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                    </div>
                    <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={itemsPerPage}
                        />
                    </div>
                </div>
            </div>

            {/* Query Reply Modal */}
            <Modal isOpen={modal} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Query reply</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="reply">Reply Query</Label>
                            <Input
                                type='text'
                                id="Query_reply"
                                value={QueryReply}
                                onChange={(e) => setQueryReply(e.target.value)}
                            />
                        </FormGroup>
                    </Form>
                    <Button className='submit' onClick={reply}>
                        Submit
                    </Button>
                </ModalBody>
            </Modal>

            {/* Details Modal */}
            <Modal isOpen={detailsModal} toggle={toggleDetails} size="xl">
                <ModalHeader toggle={toggleDetails}>Details</ModalHeader>
                <ModalBody>
                    {selectedDetails && (
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Invoice No</th>
                                    <th>Item/Particular</th>
                                    <th>Online Shopping Taxable Amount</th>
                                    <th>Online Shopping Total Amount</th>
                                    <th>Offline Shopping Taxable Amount</th>
                                    <th>Offline Shopping Total Amount</th>
                                    <th>Online Sales Taxable Amount</th>
                                    <th>Online Sales Total Amount</th>
                                    <th>Offline Sales Taxable Amount</th>
                                    <th>Offline Sales Total Amount</th>
                                    <th>Commission Amount</th>
                                    <th>TDS</th>
                                    <th>Net Receivable</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{selectedDetails.sno || 'N/A'}</td>
                                    <td>{selectedDetails?.order_item?.order?.invoices?.[0]?.invoice_no || 'N/A'}</td>
                                    <td>{selectedDetails.item || 'N/A'}</td>
                                    <td>{selectedDetails.onlineShoppingTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.onlineShoppingTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineShoppingTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineShoppingTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.onlineSalesTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.onlineSalesTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineSalesTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineSalesTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.commissionAmount || 'N/A'}</td>
                                    <td>{selectedDetails.tds || 'N/A'}</td>
                                    <td>{selectedDetails.netReceivable || 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default QueryView;
