import React, { useEffect, useState,useCallback } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import './mainproduct.css'
import $ from 'jquery';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { setCartItems, addToCartRedux, removeFromCartRedux } from '../../../src/redux/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

function MainProductView() {
  let navigate = useNavigate();
  const attributes = useParams();
  const dispatch = useDispatch();
  var [productDesc, setProductDesc] = useState(0);
  var [addedToCart, setAddToCart] = useState(0);
  var [variantsName, setvariantsName] = useState([]);
  var [variantsValue, setvariantsValue] = useState([]);
  var [feedbackStars, setFeedbackStars] = useState(0);
  var [allReviews, setAllReviews] = useState([]);
  var [productImage, setProductImage] = useState("");
  var [sameSellerData, setSameSellerData] = useState("");
  var [newCat, setNewCat] = useState("");
  var [newimage, setNewimage] = useState("");
  var [deliveryVendors, setDeliveryVendors] = useState([]);
  var [isSelfDelivery, setIsSelfDelivery] = useState(false);
  var [quantityChanged, setQuantityChanged] = useState(0);

  useEffect(() => {
    fetchData();
    // initializeCartStatus();
  }, [newCat]);

  const fetchData = async (productId = 0, productItemId = 0) => {
    await getProductDesciption(productId, productItemId);
  }

  const redirectProduct = (productId, productItemId) => {
    navigate('/product/'+productId+'/'+productItemId);
    fetchData(productId, productItemId);
  }

  const getProductDesciption = async (productId, productItemId) => {
    var parameters = {
      "product_id": productId != 0 ? productId :attributes?.product_id,
      "product_item_id": productItemId != 0 ? productItemId : attributes?.product_item_id
    };
    let response = await common.apiRequest('post', '/products/get-product', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    let image = data.map((newImage) => {
      return (
        newImage.product_image
      )
    })
    setNewimage(image)

    setProductDesc(data[0]);
    setProductImage(newimage.length > 0 && newimage[0] ? newimage[0].split(',') : ["/images/offline_shopping.jpeg"]);
    let vName = data[0]?.variation_name ? data[0]?.variation_name.split('--') : [];
    let vValue = data[0]?.variation_value ? data[0]?.variation_value.split('--') : [];
    let desc = data[0]?.description ? data[0]?.description.split('.') : [];
    setNewCat(data[0]?.category_id)
    setvariantsName(vName);
    setvariantsValue(vValue);
    // setDescription(desc);
    // setSubProductsd(data);
    sameSellerItems();
    setDeliveryVendors(response.data.deliveryVendors);
    setIsSelfDelivery(response.data.isSelfDelivery);
    if (localStorage.getItem('token')) {
      checkCart();
    }
    getAllReviews();
  }
  const [mainImage, setMainImage] = useState();
  const handleThumbnailClick = (index) => {
    setMainImage(productImage[index]);
  };
  const sameSellerItems = async () => {
    var parameters = {
      "action": "read",
      "category_id": newCat,
      "seller_id": productDesc?.seller_id,
      "product_id": attributes.product_id,
      "product_item_id": attributes.product_item_id
    };
    let response = await common.apiRequest('post', '/products/same-Seller-Items', parameters);
    let data = (response.data) ? response.data.data : [];
    let filteredSameProducts = [];
    if (response.data.data && response.data.data.length > 0) {
      filteredSameProducts = response.data.data.filter(sameProduct => {
        return sameProduct.seller_address && sameProduct.seller_address.delete_address === false;
      });
    }     
    setSameSellerData(filteredSameProducts);
  }

//   const initializeCartStatus = useCallback(() => {
//     const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
//     dispatch(setCartItems(cartItems));

//     const cartProductIds = cartItems.map(item => item.product_item_id);
//     const newAddedToCart = {};
//     products.forEach(product => {
//         if (product.product_items && product.product_items.length > 0) {
//             const productItemId = product.product_items[0].id;
//             newAddedToCart[productItemId] = cartProductIds.includes(productItemId);
//         }
//     });
//     setAddToCart(newAddedToCart);
// }, [products]);


  const addToCart = async (showAlert = true) => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
      return;
    }
    var cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
    let newCartItem = {
      'product_id': Number(attributes.product_id),
      'product_item_id': Number(attributes.product_item_id),
      'quantity_purchased': parseInt(document.getElementById('quantity').value),
      'user_id': localStorage.getItem('userId'),
      'supply_pincode': productDesc.supply_pincode,
      'service_id': 0,
      'type': 'product',
      'action': 'create',
      'seller_id': productDesc?.seller_id,
      'seller_address_id': productDesc.seller_address_gst,
      'after_pack_item_weight': productDesc.after_pack_item_weight.replace('GRM', '').replace('grm', ''),
      'self_deliver': productDesc.self_deliver,
      'active': 0
    }
    let response = await common.apiRequest('post', '/products/add-remove-cart-items', newCartItem);
    let data = (response.data) ? response.data : [];
    newCartItem.cart_id = data.cart_id;
    delete newCartItem.user_id;
    delete newCartItem.action;
    cartItems.push(newCartItem);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    dispatch(addToCartRedux(newCartItem));
    setAddToCart(1);
    if (showAlert) {
      common.alert(data.message, 'success');
    }
    return;
  }

  const buyNow = async (e) => {
    if (!localStorage.getItem('token')) {
      navigate('/login');
      return;
    }
    if (checkCart(true) && quantityChanged) {
      $('#quantity').val(quantityChanged);
      await removeFromCart(false);
      await addToCart(false);
      navigate('/cart');
    } else if (checkCart(true)) {
      navigate('/cart');
    } else {
      await addToCart();
      navigate('/cart');
    }
  }

  const removeFromCart = async (showAlert = true) => {
    var cartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (cartItems) {
      const cartItemToRemove = cartItems.find(o => o.product_item_id == attributes.product_item_id);
      cartItemToRemove.action = "delete";
      let response = await common.apiRequest('post', '/products/add-remove-cart-items', cartItemToRemove);
      let data = (response.data) ? response.data : [];
      const updatedCartItems = cartItems.filter(item => {
        return item.product_item_id != attributes.product_item_id;
      });
      dispatch(removeFromCartRedux(cartItemToRemove.product_item_id));
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
      setAddToCart(0);
      if (showAlert) {
        common.alert(data.message, 'success');
      }
      return;
    }
  }

  const checkCart = (checkForBuyNow = false) => {
    var cartItems = JSON.parse(localStorage.getItem('cartItems'));
    let tempCartItem = cartItems.find(o => o.product_item_id == attributes.product_item_id);
    if (cartItems && tempCartItem) {
      setAddToCart(1);
      $('#quantity').val(tempCartItem.quantity_purchased);
      if (checkForBuyNow) {
        return true;
      }
    }
  }

  const changeFeedbackStars = (value) => {
    setFeedbackStars(value);
    for (let i = 1; i <= 5; i++) {
      document.getElementById(i + 'star').style.color = '#ddd';
    }
    for (let i = 1; i <= value; i++) {
      document.getElementById(i + 'star').style.color = '#ffd700';
    }
  }

  const postFeedback = async (e) => {
    e.preventDefault();
    const rating = feedbackStars;
    const review = document.getElementById('feedback').value;
    if (rating <= 0 || review.trim() === "") {
      alert("Please provide both rating and review");
      return;
    }
    var parameters = {
      "product_item_id": attributes.product_item_id,
      "rating": feedbackStars,
      "review": document.getElementById('feedback').value,
      "user_id": localStorage.getItem('userId')
    };
    let response = await common.apiRequest('post', '/products/post-product-review', parameters);
    common.alert(response.data.message);
    document.getElementById('feedback').value = "";
    changeFeedbackStars(0);
    getAllReviews();
  }

  const getAllReviews = async () => {
    var parameters = {
      "product_id": attributes.product_item_id
    };
    let response = await common.apiRequest('post', '/products/get-product-review', parameters);
    if (response?.data?.data) {
      var first = 0; var second = 0; var third = 0; var fourth = 0; var fifth = 0;
      response.data.data.map((review) => {
        if (review.rating == 5) {
          fifth++;
        } else if (review.rating == 4) {
          fourth++;
        } else if (review.rating == 3) {
          third++;
        } else if (review.rating == 2) {
          second++;
        } else if (review.rating == 1) {
          first++;
        }
      });
      let total = response.data.data.length;
      if (total != 0) {
        first = (first * 100) / total;
        second = (second * 100) / total;
        third = (third * 100) / total;
        fourth = (fourth * 100) / total;
        fifth = (fifth * 100) / total;
      }
      document.getElementById('total-five-star').style.width = fifth + '%';
      document.getElementById('total-four-star').style.width = fourth + '%';
      document.getElementById('total-three-star').style.width = third + '%';
      document.getElementById('total-two-star').style.width = second + '%';
      document.getElementById('total-one-star').style.width = first + '%';
      setAllReviews(response.data.data);
    }
  }

  const checkAvailability = async () => {
    if (!localStorage.getItem('token')) {
      common.alert("Please login to check", 'error');
      return;
    }
    if($('#pincode').val() == ""){
      common.alert("Please enter a pincode", 'error');
      return;
    }
    var pincodeArray;
    let length = 0;
    if (parseInt($('#pincode').val()) == productDesc.supply_pincode) {
      pincodeArray = [parseInt(productDesc.supply_pincode)];
      length = 1;
    } else {
      pincodeArray = [parseInt($('#pincode').val()), parseInt(productDesc.supply_pincode)];
      length = 2;
    }
    var data = {
      pincodes: pincodeArray,
      self_deliver: productDesc.self_deliver,
      product_id: productDesc.product_id,
      logistics_delivery_type: ['Normal Delivery', 'Express Delivery'],
      length: length
    }
    let response = await common.apiRequest('post', '/products/check-product-delivery-vendors', data);
    common.alert(response.data.code == "success" ? response.data.message : response.data.error, response.data.code == "success" ? "success" : "info");
  }

  return (
    <>

      <div className="container-fluid">


        <div className="row">
          <div className="col">
            <div className="breadcrumbs d-flex flex-row align-items-center">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li className="active"><i className="fa fa-angle-right" aria-hidden="true"></i>{productDesc?.name}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='py-5'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6'>

              <div className="border rounded-4 mb-3 d-flex justify-content-center">
                <Zoom>
                  <img style={{ maxWidth: "100%", maxHeight: "60vh", margin: "auto" }} className="rounded-4 fit" src={envConfig.api_url + (mainImage ? mainImage : productImage[0])} />

                </Zoom>

                <Link data-fslightbox="mygalley" className="rounded-4" target="_blank" data-type="image" to="">
                </Link>
              </div>
              <div className="d-flex justify-content-center mb-3">
                {productImage && productImage.length > 0 ? productImage.map((image, index) => (
                  <Zoom>
                    <img width="60" height="60" className="rounded-2" key={index} src={envConfig.api_url + image} onClick={() => handleThumbnailClick(index)} />

                  </Zoom>
                )) : ""}
              </div>



            </div>

            <div className='col-lg-6'>
              <div className="ps-lg-3">
                <h4 className="title text-dark">
                  {productDesc?.name}
                </h4>
                <div className="d-flex flex-row my-3">
                  <div className="text-warning star mb-1">
                    {(() => {
                      const totalRating = productDesc?.total_rating || 0;
                      const fullStars = Math.floor(Math.max(0, totalRating));
                      const halfStars = (totalRating % 1) >= 0.5 ? 1 : 0;
                      const emptyStars = Math.max(0, 5 - fullStars - halfStars);

                      const stars = [];
                      for (let i = 0; i < fullStars; i++) {
                        stars.push(<i key={`full-${i}`} className="fa fa-star"></i>);
                      }
                      if (halfStars > 0) {
                        stars.push(<i key="half" className="fas fa-star-half-alt"></i>);
                      }
                      for (let i = 0; i < emptyStars; i++) {
                        stars.push(<i key={`empty-${i}`} className="fa fa-star-o"></i>);
                      }

                      return (
                        <>
                          {stars}
                          <span className="ms-1">
                            &nbsp;{totalRating}
                          </span>
                        </>
                      );
                    })()}
                    <br />
                    <span className="text-muted star"><i className="fas fa-shopping-basket fa-sm mx-1"></i>{productDesc?.total_orders} orders</span>
                    {productDesc?.qty_in_stock - productDesc?.total_orders > 0 ? <span className="text-success ms-2">In stock</span> : <span className="text-danger ms-2">Out of stock</span>}
                  </div>

                </div>

                <div className="mb-3">
                  <span className="h5"><i className="fas fa-rupee-sign" />{common.parseValue(productDesc?.price,'float')}</span>
                  <span className="text-muted">/per Piece</span>
                </div>

                <p>
                  {productDesc?.description}
                </p>

                <div className="row">
                  <div className="col-3">Delivery by:</div>
                  <div className="col-9"
                  style={{ 
                    height: '200px',            
                    overflowY: 'scroll',        
                    border: '1px solid #ddd',   
                    marginBottom: '15px'
                  }}>
                  {
                    localStorage.getItem('token') && (deliveryVendors && deliveryVendors.length > 0 ?
                      deliveryVendors.map((vendor) => {
                        return (
                          vendor.min_delivery_time ? 
                            <>
                              <span className="col-9 text text-success">Can be delivered between {vendor.min_delivery_time} to {vendor.max_delivery_time} for Rs. {vendor.price}</span><br/>
                            </>
                          :
                            <>
                            < span className="col-9 text text-success">Can be delivered in Rs. {vendor.price}</span><br/>
                            </>
                        );
                      })
                      :
                      isSelfDelivery ? <span className='text text-success'>Free Delivery</span>
                        : <span className='text text-danger'>Not available for delivery at your default address</span>)
                  }
                  </div>
                </div>

                <hr />
                <div className="row mb-5 ml-1">
                  <div className="col-md-3">
                    <label className='mb-2 d-block' for="quantity">Quantity</label>
                    <select className="size-select border border-secondary" style={{ height: "35px" }} id="quantity" onClick={(e) => setQuantityChanged(e.target.value)}>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </select>
                  </div>
                  <div className='col-md-9 mt-4'>
                    <div className='row'>
                      <input className="col-md-4 size-select border border-secondary mr-1 mt-1" style={{ height: "40px" }} type="text" id="pincode" placeholder='Enter Pincode' onKeyDown={(e) => { if (e.key === 'Enter') { checkAvailability() } }} />
                      <button className="col-md-2 btn-check btn-warning shadow-0 mt-1" style={{ height: "40px" }} onClick={checkAvailability}>Check</button>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  {
                    productDesc?.qty_in_stock - productDesc?.total_orders > 0 ?
                      <>
                        <div className='col-md-4 mt-5 mr-3'>
                          {
                            !addedToCart ?
                              <Link to="#" className="btn1 btn-primary shadow-0" onClick={addToCart}> <i className="me-1 fa fa-shopping-basket"></i> Add to cart </Link>
                              :
                              <Link to="#" className="btn1 btn-primary shadow-0" onClick={removeFromCart}> <i className="me-1 fa fa-shopping-basket"></i> Remove From Cart</Link>
                          }
                        </div>
                        <div className='col-md-4 mt-5'>
                          <button  type='button' className="btn1 btn-warning shadow-0" onClick={buyNow}> Buy now </button>
                        </div>
                      </> :
                      <>
                        <div className='col-md-4 mt-5 mr-3'>
                          <button type='button' className="btn1 btn-danger shadow-0"> Out of stock </button>
                        </div>
                      </>
                  }
                  <div className='col-md-4 mt-5 mr-3'>
                    <Link to="/" className="btn1 btn-warning shadow-0"> <i className="me-1 fa fa-shopping-basket"></i> More shopping </Link>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light border-top py-4">
        <div className="container-fluid">
          <div className="row gx-4">
            <div className="col-lg-12 mb-4">
              <div className="border rounded-2 px-3 py-2 bg-white">
                <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
                  <li className="nav-item d-flex" role="presentation">
                    <a className="nav-link d-flex align-items-center justify-content-center w-100 active" id="ex1-tab-1" data-toggle="tab" href="#ex1-pills-1" role="tab" aria-controls="ex1-pills-1" aria-selected="true">Specification</a>
                  </li>
                  <li className="nav-item d-flex" role="presentation">
                    <a className="nav-link d-flex align-items-center justify-content-center w-100" id="ex1-tab-2" data-toggle="tab" href="#ex1-pills-2" role="tab" aria-controls="ex1-pills-2" aria-selected="false">Buyer Feedback</a>
                  </li>
            
                </ul>
                <div className="tab-content" id="ex1-content">
                  <div className="tab-pane fade show active" id="ex1-pills-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                    <div style={{
                        border: '1px solid #ddd',
                        padding: '15px',
                        borderRadius: '5px',
                        maxWidth: '100%',
                        margin: '10px auto'
                      }}>
                        <h4 style={{
                          color: '#333',
                          borderBottom: '1px solid #ccc',
                          paddingBottom: '10px',
                          marginBottom: '10px'
                        }}>
                          Product Details
                        </h4>
                        <ul style={{
                          listStyle: 'none',
                          padding: '0',
                          margin: '0'
                        }}>
                          <li style={{
                            display: 'flex',
                            marginBottom: '10px',
                            paddingBottom: '5px',
                            borderBottom: '1px solid #eee'
                          }}>
                            <strong style={{
                              marginRight: '10px',
                              minWidth: '120px'
                            }}>FAQ:</strong>
                            {productDesc.faq}
                          </li>
                          <li style={{
                            display: 'flex',
                            marginBottom: '10px',
                            paddingBottom: '5px',
                            borderBottom: '1px solid #eee'
                          }}>
                            <strong style={{
                              marginRight: '10px',
                              minWidth: '120px'
                            }}>HSN Code:</strong>
                            {productDesc.hsn_code}
                          </li>
                          <li style={{
                            display: 'flex',
                            marginBottom: '10px',
                            paddingBottom: '5px',
                            borderBottom: '1px solid #eee'
                          }}>
                            <strong style={{
                              marginRight: '10px',
                              minWidth: '120px'
                            }}>Use or Utility:</strong>
                            {productDesc.use_or_utility}
                          </li>
                          <li style={{
                            display: 'flex',
                            marginBottom: '10px',
                            paddingBottom: '5px',
                            borderBottom: '1px solid #eee'
                          }}>
                            <strong style={{
                              marginRight: '10px',
                              minWidth: '120px'
                            }}>After Sale Services:</strong>
                            {productDesc.after_sale_services}
                          </li>
                          <li style={{
                            display: 'flex',
                            marginBottom: '10px',
                            paddingBottom: '5px',
                            borderBottom: '1px solid #eee'
                          }}>
                            <strong style={{
                              marginRight: '10px',
                              minWidth: '120px'
                            }}>Warranty or Guarantee:</strong>
                            {productDesc.warranty_or_gurantee}
                          </li>
                          <li style={{
                            display: 'flex'
                          }}>
                            <strong style={{
                              marginRight: '10px',
                              minWidth: '120px'
                            }}>Product SKU:</strong>
                            {productDesc.product_sku}
                          </li>
                        </ul>
                      </div>
                    <div className="row mb-2">
                   
                    </div>
                    <table className="table border mt-3 mb-2">
                      {
                        variantsName.map((variation, index) => {
                          return (
                            <>
                            <div style={{
                        border: '1px solid #ddd',
                        padding: '15px',
                        borderRadius: '5px',
                        maxWidth: '100%',
                        margin: '10px auto'
                      }}>
                         <ul style={{
                          listStyle: 'none',
                          padding: '0',
                          margin: '0'
                        }}>
                          <li style={{
                            display: 'flex',
                            marginBottom: '10px',
                            paddingBottom: '5px',
                            borderBottom: '1px solid #eee'
                          }}>
                            <strong style={{
                              marginRight: '10px',
                              minWidth: '120px'
                            }}>{variation.charAt(0).toUpperCase() + variation.slice(1)}:</strong>
                            {variantsValue[index]}
                          </li>

                          </ul>
                      </div>
                              {/* <tr>
                                <th className="py-2">{variation.charAt(0).toUpperCase() + variation.slice(1)}</th>
                                <td className="py-2">{variantsValue[index]}</td>
                              </tr> */}
                            </>
                          );
                        })
                      }

                    </table>
                  </div>
                  <div className="tab-pane fade mb-2" id="ex1-pills-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                    <div className='container'>
                      <div className=" row">
                        <div className="col-md-12">
                          <h4 className="text-black front-weight-bold ">Customer Reviews</h4>
                          <div className="dropdown-divider"></div>
                        </div>
                        <div className="col-md-5">
                          <span className="rating">5 star rating</span>
                          <div className="progress">
                            <div className="progress-bar bg-warning" role="progressbar" id="total-five-star"></div>
                          </div>
                          <span className="rating">4 star rating</span>
                          <div className="progress">
                            <div className="progress-bar bg-warning" role="progressbar" id="total-four-star"></div>
                          </div>
                          <span className="rating">3 star rating</span>
                          <div className="progress">
                            <div className="progress-bar bg-warning" role="progressbar" id="total-three-star"></div>
                          </div>
                          <span className="rating">2 star rating</span>
                          <div className="progress">
                            <div className="progress-bar bg-warning" role="progressbar" id="total-two-star"></div>
                          </div>
                          <span className="rating">1 star rating</span>
                          <div className="progress">
                            <div className="progress-bar bg-warning" role="progressbar" id="total-one-star"></div>
                          </div>
                        </div>

                        <div className="col-md-7">
                          <div className='row'>
                            <div className='col-md-2'>
                              <label for="feedback" className="form-label font-weight-bold">Your Feedback :</label>
                            </div>
                            <div className='col-md-10'>
                            <ul className="list-inline rating-list">
                              <li><i className="fa fa-star pl-3" id="5star" onClick={(e) => { e.preventDefault(); changeFeedbackStars(5); }}></i></li>
                              <li><i className="fa fa-star pl-3" id="4star" onClick={(e) => { e.preventDefault(); changeFeedbackStars(4); }}></i></li>
                              <li><i className="fa fa-star pl-3" id="3star" onClick={(e) => { e.preventDefault(); changeFeedbackStars(3); }}></i></li>
                              <li><i className="fa fa-star pl-3" id="2star" onClick={(e) => { e.preventDefault(); changeFeedbackStars(2); }}></i></li>
                              <li><i className="fa fa-star" id="1star" onClick={(e) => { e.preventDefault(); changeFeedbackStars(1); }}></i></li>
                            </ul>

                              <textarea className="form-control" id="feedback" name="feedback" rows="6"></textarea>
                              <button type="button" className="mt-2 btn btn-primary" onClick={postFeedback}>Send Feedback</button>
                            </div>
                          </div>
                        </div>

                        <div className='col-md-12 mt-5'>
                          <h4 className="text-capitalize">Top Reviews</h4>
                          <h4 className="text-capitalize">Total Reviews {allReviews?.length?allReviews.length:0}</h4>
                          <div className="row mt-3">
                            {
                              allReviews.map((review) => {
                                let filledStars = [];
                                let unFilledStars = [];
                                for (let i = 1; i <= review.rating; i++) {
                                  filledStars.push(1);
                                }
                                if (review.rating != 5) {
                                  for (let i = review.rating; i < 5; i++) {
                                    unFilledStars.push(1);
                                  }
                                }
                                return (
                                  <>
                                    <div className="col-md-12">
                                      <Link to="#" className="text-dark" style={{ textDecoration: "none" }}><img src="/assets/img/empty-avatar.png" width="34"
                                        height="34" className="rounded-circle mr-2" />{review.user.first_name} {review.user.last_name}</Link>
                                      <br />
                                      <div className='mt-2'>
                                        {
                                          filledStars.map(() => {
                                            return (
                                              <i className='fa fa-star text-warning pr-2' style={{ fontSize: "22px" }}></i>
                                            )
                                          })
                                        }
                                        {
                                          unFilledStars.map(() => {
                                            return (
                                              <i className='fa fa-star-o text-warning pr-2' style={{ fontSize: "22px" }}></i>
                                            )
                                          })
                                        }
                                      </div>
                                      <p className='mt-2'>{review.review}</p>
                                    </div>
                                  </>
                                );
                              })
                            }
                          </div>
                          <div className="dropdown-divider mt-3"></div>

                        </div>
                      </div>


                    </div>
                  </div>
                  <div className="tab-pane fade mb-2" id="ex1-pills-3" role="tabpanel" aria-labelledby="ex1-tab-3">

                  </div>
                  <div className="tab-pane fade mb-2" id="ex1-pills-4" role="tabpanel" aria-labelledby="ex1-tab-4">

                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="px-0 border rounded-2 shadow-0">
              <div className="card-body">
                <h5 className="card-title">Same vendor other products</h5>
                {sameSellerData.length > 0 &&
                  <div className="row" style={{cursor: 'pointer'}}>
                    {sameSellerData.map((sameproduct, index) => {
                      if (sameproduct.product_items && sameproduct.product_items.length > 0) {
                        return (
                          <div key={index} className="col-lg-4 mb-3">
                            <div className="d-flex" onClick={() => redirectProduct(sameproduct.id, sameproduct.product_items[0].id)}>
                              <img src={envConfig.api_url + sameproduct.product_items[0].product_image} style={{ width: "96px", height: "96px", objectFit: "cover" }}
                                  className="img-md img-thumbnail" alt="Product" />
                              <div className="info">
                                {sameproduct?.name}
                                {sameproduct.product_items[0]?.price && (
                                  <strong className="text-dark mx-3"><i className="fas fa-rupee-sign" /> {common.parseValue(sameproduct.product_items[0]?.price,'float')}</strong>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}

                  </div>
                }
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}
export default MainProductView;
