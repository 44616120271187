import React, { Component } from 'react'
import { Link, useOutletContext } from 'react-router-dom';
import {Button,CardBody} from 'reactstrap';
import Carousel from './partials/Carousel';
import MainPageProduct from './partials/MainPageProduct';
import CarouselCard from './partials/CarouselCard';
import { Navigate } from 'react-router-dom';

function Home () {
	const [banners, setBanners] = useOutletContext();

	return (
		<div>
			<div className="container-fluid px-0 homeback">
              <Carousel />
			  {/* <CarouselCard/> */}
              <MainPageProduct/>
            </div>
		</div>
	);
}

export default Home;