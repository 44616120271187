import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const common = require('../../utils/Common');

function CheckoutAddress() {
    const [addresses, setAddresses] = useState([]);
    const [deliveryAddress, setFinalDeliveryAddress] = useState(0);
    const [supplyVendors, setSupplyVendors] = useState([]);
    const [logisticVendor, setLogisticVendor] = useState({});
    const [serviceAvailable, setServiceAvailable] = useState(false);
    const [zones, setZones] = useState([]);
    const [distances, setDistances] = useState([]);
    const [selfDeliver, setSelfDeliver] = useState(false);
    const [hasService, setHasService] = useState(false);
    const [hasProduct, setHasProduct] = useState(false);
    const [proceedPayment, setProceedPayment] = useState(false);
    const [thirdPartyVendors, setThirdPartyVendors] = useState([]);

    useEffect(() => {
        getAllAddresses();
    }, []);

    const getAllAddresses = async () => {
        let data = {
            "user_id": localStorage.getItem('userId')
        };
        let response = await common.apiRequest('post', '/users/get-address', data);
        if (response.status === 200) {
            if (response.data.code === 'success') {
                setAddresses(response.data.data);
                const activeAddress = response.data.data.find(address => address.active == true);
                if (activeAddress) {
                    setDeliveryAddress(activeAddress.id);
                }
            } else {
                common.alert(response.data.error, 'error');
            }
        } else {
            common.alert('Something went wrong' ,'error');
        }
    };

    const setDeliveryAddress = async (addressId) => {
        let productDelivery, serviceDelivery, selfProductDelivery = false;
        setHasProduct(false);
        setHasService(false);
        setSelfDeliver(false);
        setServiceAvailable(false);
        setLogisticVendor({});
        setSupplyVendors([]);
        setProceedPayment(false);
        var product, service, deliver = 0;
        setFinalDeliveryAddress(addressId);
        var cartItems = JSON.parse(localStorage.getItem('cartItems'));
        var productDatas = [];
        var serviceIds = [];
        var selfDeliverProductIds = [];
        cartItems.forEach(item => {
            if (!item.active) {
                if (item.service_id == 0 && !product && !item.self_deliver) {
                    setHasProduct(true);
                    product = 1;
                } else if (item.service_id > 0 && !service) {
                    setHasService(true);
                    service = 1;
                } else if (item.self_deliver && !deliver) {
                    setSelfDeliver(true);
                    deliver = 1;
                }
                if (!item.self_deliver) {
                    if (item.service_id == 0) {
                        let tempData = {
                            'product_item_id': item.product_item_id,
                            'seller_id': item.seller_id,
                            'quantity': item.quantity_purchased,
                            'after_pack_item_weight': item.after_pack_item_weight,
                            'seller_address_id': item.seller_address_id
                        }
                        productDatas.push(tempData);
                    } else {
                        serviceIds.push(item.service_id);
                    }
                } else {
                    selfDeliverProductIds.push(item.product_id);
                }
            }
        });

        let response = "";
        if (product) {
            var data = {
                user_address_id: addressId,
                product_item_data: productDatas,
                logistics_delivery_type: ['Normal Delivery', 'Express Delivery']
            }
            response = await common.apiRequest('post','/products/check-product-delivery-vendors',data);
            if (response.data.code === 'success') {
                if (response.data.data.length == 0 || (response.data.data.vendors.length == 0 && !response.data.data.third_party_vendors.shiprocket)) {
                    common.alert('Some product not available for delivery to this pincode', 'error');
                    setHasProduct(false);
                    setProceedPayment(false);
                } else {
                    productDelivery = true;
                    setSupplyVendors(response.data.data.vendors);
                    setThirdPartyVendors(response.data.data.third_party_vendors);
                    setDistances(response.data.data.product_item_data);
                }
            } else {
                setHasProduct(false);
                setProceedPayment(false);
                common.alert(response.data.error, 'error');
            }
        }

        if (service) {
            data = {
                user_address_id: addressId,
                product_ids: serviceIds
            }
            response = await common.apiRequest('post','/products/check-service-delivery',data);
            if (response.data.code === 'success') {
                if (!response.data.data) {
                    common.alert('Some Service not available for this pincode', 'error');
                    setHasService(false);
                    setProceedPayment(false);
                } else {
                    setServiceAvailable(true);
                    serviceDelivery = true;
                }
            } else {
                common.alert(response.data.error, 'error');
                setHasService(false);
                setProceedPayment(false);
            }
        }

        if (deliver && selfDeliverProductIds.length > 0) {
            data = {
                user_address_id: addressId,
                product_ids: selfDeliverProductIds,
                self_deliver: 1
            }
            response = await common.apiRequest('post','/products/check-product-delivery-vendors',data);
            if (response.data.code === 'success') {
                if (!response.data.data) {
                    common.alert('Some Product not available for this pincode', 'error');
                    setSelfDeliver(false);
                    setProceedPayment(false);
                    setSupplyVendors([]);
                    setThirdPartyVendors([]);
                    setDistances([]);
                } else {
                    setSelfDeliver(true);
                    selfProductDelivery = true;
                }
            } else {
                common.alert(response.data.error, 'error');
                setSelfDeliver(false);
                setProceedPayment(false);
            }
        }

        if ((product && service && deliver && productDelivery && serviceDelivery && selfProductDelivery) || (product && service && productDelivery && serviceDelivery) && (product && deliver && productDelivery && selfProductDelivery) || (service && deliver && serviceDelivery && selfProductDelivery)|| (product && productDelivery) || (service && serviceDelivery) || (deliver && selfProductDelivery))  {
            if (!product) {
                setProceedPayment(true);
            }
        } else {
            setProceedPayment(false);
        }
    };

    const setLogisticVendors = (vendor, index = -1) => {
        if (index == -2) {
            setDistances([]);
            setLogisticVendor({serviceId: 0, vendorId: 0, price: Number(vendor.total_price), distances: vendor.data, zoneFinal: '-', distance: '-', isThirdParty: 1, thirdPartyName: 'ecomexpress'});
        } else if (index != -1) {
            let totalPrice = 0;
            let tempVendor = vendor.map(vd => ({
                ...vd,
                vendors: [...vd.vendors]
            }));
            for (const vd of tempVendor) {
                totalPrice += vd.vendors[index]['rate'];
                vd.vendors = vd.vendors[index];
            }
            setDistances([]);
            setLogisticVendor({serviceId: 0, vendorId: 0, price: Number(totalPrice), distances: tempVendor, zoneFinal: '-', distance: '-', isThirdParty: 1, thirdPartyName: 'shiprocket'});
        } else {
            var tempDistance = distances;
            let zoneFinal = '-';
            let distanceFinal = '-';
            let zoneIndexes = vendor.seller_service.zone_price_index;
            for (let index = 0; index < tempDistance.length; index++) {
                let zonePrices = vendor[tempDistance[index]['zone']];
                zonePrices = zonePrices.split(',');
                let tempZoneIndex = zoneIndexes[index];
                tempDistance[index]['order_price'] = zonePrices[tempZoneIndex];
            }
            setDistances(tempDistance);
            setLogisticVendor({serviceId: vendor.seller_service.id, vendorId: vendor.seller_service.seller_id, price: parseInt(vendor.seller_service.price), distances: distances, zone: zoneFinal, distance: distanceFinal});
        }
        setProceedPayment(true);
    };

    return (
        <React.Fragment>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-8">
                        <h3>Choose Address</h3>
                        <hr />
                    </div>
                </div>
                <div className="row">
                    {addresses.length === 0 ? (
                        <div>
                            <h3>No address found</h3>
                            <Link to="/my-account/address">Add One</Link>
                        </div>
                    ) : (
                        <>
                        {
                            addresses.map(address => (
                                <div className="col-md-4">
                                    <div className="card addselpage1">
                                        <div className="card-body">
                                            <label style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
                                                <input
                                                    type="radio"
                                                    name="deliveryAddress"
                                                    defaultChecked={address.active ? true : false}
                                                    onChange={() => setDeliveryAddress(address.id)}
                                                    style={{ marginRight: '295px', marginBottom: '20px' }}
                                                />
                                                <h5 className="card-title font-weight-bold">
                                                    {address.address_type.toUpperCase()}
                                                </h5>
                                                <p className="my-0 py-0">{address.address}</p>
                                                <p className="my-0 py-0">{address.city}</p>
                                                <p className="my-0 py-0">{address.state}</p>
                                                <p className="my-0 py-0">{address.country}</p>
                                                <p className="my-0 py-0">{address.pincode}</p>
                                            </label>
                                        </div>
                                        <Link to="/my-account/add-edit-address" state={{editAddress : address}} className="btn btn-outline-primary btn-sm"><i className="fas fa-pencil-alt"></i></Link>
                                    </div>
                                </div>
                            ))
                        }
                        <Link to="/my-account/address">Add New</Link>
                        </>
                    )}
                </div>
                <br />
                <div 
                    className="col-md-12" 
                    style={{ 
                        height: '250px',            
                        overflowY: 'scroll',        
                        border: '1px solid #ddd',   
                        marginBottom: '15px'
                    }}
                >
                    {/* Render supplyVendors if they exist */}
                    {supplyVendors && supplyVendors.length > 0 ? supplyVendors.map((vendor, index) => (
                        <div className="row" key={index}>
                            <div className="col-md-12">
                                <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '8px' }}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="logistics_vendor_id"
                                        onClick={() => setLogisticVendors(vendor)}
                                    />
                                    <span style={{ marginLeft: '8px' }}>Rs. {vendor.seller_service.price}</span>&nbsp;
                                    <span>
                                        Between {vendor.seller_service.min_delivery_time} and {vendor.seller_service.max_delivery_time} ({vendor.seller_service.logistics_delivery_type})
                                    </span>
                                </label>
                                <br />
                            </div>
                        </div>
                    )) : null}

                    {/* Render third-party vendors if they exist */}
                    {(thirdPartyVendors && (thirdPartyVendors.shiprocket?.data || thirdPartyVendors.ecomexpress?.data)) && (
                        <>
                            {/* Render Ecomexpress vendors */}
                            {thirdPartyVendors.ecomexpress?.data?.length > 0 && (
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '8px' }}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="logistics_vendor_id"
                                                onClick={() => setLogisticVendors(thirdPartyVendors.ecomexpress, -2)}
                                            />
                                            <span style={{ marginLeft: '8px' }}>Rs. {thirdPartyVendors.ecomexpress.total_price} (Ecom Express)</span>&nbsp;
                                        </label>
                                        <br />
                                    </div>
                                </div>
                            )}

                            {/* Render Shiprocket vendors */}
                            {thirdPartyVendors.shiprocket?.data?.length > 0 && thirdPartyVendors.shiprocket.total_price.map((price, index) => (
                                <div className="row" key={index}>
                                    <div className="col-md-12">
                                        <label className="form-check-label" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '8px' }}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="logistics_vendor_id"
                                                onClick={() => setLogisticVendors(thirdPartyVendors.shiprocket.data, index)}
                                            />
                                            <span style={{ marginLeft: '8px' }}>Rs. {price}</span>&nbsp;
                                        </label>
                                        <br />
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>

                
                <br />
                <div className="row">
                    <div className="col-md-12">
                        {addresses.length > 0 && deliveryAddress && proceedPayment ? (
                            <Link
                                to="/cart/checkout/payment"
                                state={{ deliveryAddress: deliveryAddress, logisticVendor: logisticVendor }}
                            >
                                <button className="btn btn-primary">Proceed For Payment</button>
                            </Link>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default CheckoutAddress;
