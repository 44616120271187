import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './css/deliverydetails.css';
import { useNavigate, useLocation } from 'react-router-dom';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

const DeliveryDetails = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [nextStatus, setNextStatus] = useState(null);
  const [cannotChangeStatus, setCannotChangeStatus] = useState(false);

  useEffect(() => {
    checkOrders();
	},[]);

  const checkOrders = async () => {
    if (!location.state) {
        navigate('/delivery/products');
    }
    setData(location.state);
    const currentDeliverId = localStorage.getItem('delivery_userId');
    
    let orderStatuses = await common.getSystemConfig('product_service_order_status', 'delivery');
    let finalOrderStatuses = {};
    if (location.state.product_id != 0) {
      if (orderStatuses.normal_online.includes(location.state.order_status)) {
        finalOrderStatuses = orderStatuses.online_product.normal[0];
        location.state.deliver_id != currentDeliverId ? setCannotChangeStatus(true) : setCannotChangeStatus(false);
      } else if (orderStatuses.return_online.includes(location.state.order_status)) {
        finalOrderStatuses = orderStatuses.online_product.return[0];
        location.state.return_deliver_id != currentDeliverId ? setCannotChangeStatus(true) : setCannotChangeStatus(false);
      } else if (orderStatuses.cancel_online.includes(location.state.order_status)) {
        finalOrderStatuses = orderStatuses.online_product.cancel[0];
        location.state.deliver_id != currentDeliverId ? setCannotChangeStatus(true) : setCannotChangeStatus(false);
      } else if (orderStatuses.replace_online.includes(location.state.order_status)) {
        finalOrderStatuses = orderStatuses.online_product.replace[0];
        let returnStatuses = ["replace_requested", "pick_up_assigned", "item_handover_to_seller", "picked_back"];
        if (returnStatuses.includes(location.state.order_status)) {
          location.state.return_deliver_id != currentDeliverId ? setCannotChangeStatus(true) : setCannotChangeStatus(false);
        } else {
          location.state.replace_deliver_id != currentDeliverId ? setCannotChangeStatus(true) : setCannotChangeStatus(false);
        }
      }
    } else if (location.state.service_id != 0) {
      if (orderStatuses.normal_service.includes(location.state.order_status)) {
        finalOrderStatuses = orderStatuses.service.normal[0];
        location.state.deliver_id != currentDeliverId ? setCannotChangeStatus(true) : setCannotChangeStatus(false);
      }
    }
    setOrderStatuses(finalOrderStatuses);
  }

  const updateOrderStatus = async (orderId, orderType, selfDeliver = 0, userId, sellerAddressId, orderPrice, noReturn = 0) => {
    if (Number(noReturn) == 1) {
      common.alert('Status cannot be changed now', 'error');
      return;
    }
    if (nextStatus || $('#comment').val()) {
      let parameters = {
        'action': 'update',
        'order_id': orderId,
        'order_status': $('#delivery_status').val(),
        'order_remarks': $('#comment').val(),
        'order_price': orderPrice,
        'user_id': userId,
        'self_deliver': Number(selfDeliver),
        'seller_address_id': Number(sellerAddressId)
      }
      let response = await common.apiRequest('post', '/orders/change-order-status-operations', parameters, 'delivery');
      if (response.data.message) {
        common.alert(response.data.message);
        navigate('/delivery/products');
      } else {
        common.alert(response.data.error, 'error');
      }
    } else {
      common.alert('Please change status first', 'error');
    }
  }

  const uploadCancelReplaceReturnImages = async (event, orderItemId) => {
    const files = event?.target?.files;
    if (files.length > 0) {
      await common.uploadOrderImages(files, orderItemId, 'deliver', 'deliver_order', 1);
    } else {
      common.alert("No images to upload", "error");
    }
    $('#order_images').val(null);
  }

  const checkIfRoleCanChangeStatus = (status, role, currentStatus) => {
    // Example logic: Check the role permissions for a given status
    const statusDetails = orderStatuses[status]; // Access status details for the given role
    return statusDetails && statusDetails[`${role}_can_change`] && statusDetails.can_move_from.includes(currentStatus); // Check if the role can change the status
  };


  if (data.product_item || data.seller_service) {
    return (
      <>
       <div class="container">
          <div class="row">
            
            <div id="form-header" class="col-12">
              <h1 id="title">Delivery details</h1>
            </div>
          </div> 
         
         <div class="row">   
             <div id="form-tagline" class="col-md-4">
                 <div class="form-tagline">
                     <h2>{data.product_item ? data.product_item.product.product_names : data.seller_service.service_name}</h2>
                     <div className='row'>
                      {
                        data.product_item && data.product_item.product_image.split('|').map((image) => {
                          return (
                            <div className='col-md-4'>
                              <img className="img-fluid product_image" 
                              src={envConfig.api_url + image.split(',')[0] } 
                              alt="Product" />
                            </div>
                          )
                        })
                      }
                      {
                        data.seller_service && 
                        <img className="img-fluid product_image" 
                         src={envConfig.api_url + data.seller_service.service_image} 
                         alt="Product" />
                      }
                    </div>
                </div>
             </div>
            
             <div id="form-content" class="col-md-8">
                                   
                   <div class="row form-group">
                      <div class="col-sm-3">
                       <label id="name-label" class="control-label" for="name">*Name:</label>
                      </div>
                     
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-name"><i class="fa fa-user"></i></span>
                        </div>                   
                        <div className='form-control'>{data.order.user.first_name + data.order.user.last_name}</div>
                      </div>
                   </div>
                    
                   <div class="form-group row">
                      <div class="col-sm-3">
                        <label id="email-label" class="control-label" for="email">Pickup Address</label>
                      </div>
                    
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-address"><i class="fa-solid fa-location-dot"></i></span>
                        </div>
                        <div className='form-control'>{data.product_item ? data.product_item.product.seller_address.address : data.seller_service.seller_address.address}</div>
                      </div>
                   </div>
                   <div class="form-group row">
                      <div class="col-sm-3">
                        <label id="number-label" class="control-label" for="email">vendor Phone Number:</label>
                      </div>
                    
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-Phone"><i class="fa-solid fa-phone"></i></span>
                        </div>
                        <div className='form-control'>{data.seller.user_business.mobile}</div>
                      </div>
                    </div>
                   <div class="form-group row">
                      <div class="col-sm-3">
                        <label id="email-label" class="control-label" for="email">Delivery Address</label>
                      </div>
                    
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-address"><i class="fa-solid fa-location-dot"></i></span>
                        </div>
                        <div className='form-control'>{data.order.user_address.address}</div>
                      </div>
                   </div>
                    
                   <div class="form-group row">
                      <div class="col-sm-3">
                        <label id="number-label" class="control-label" for="email">customer Phone Number:</label>
                      </div>
                    
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-Phone"><i class="fa-solid fa-phone"></i></span>
                        </div>
                        <div className='form-control'>{data.order.user.mobile_phone}</div>
                      </div>
                    </div>
                    
                   <div class="form-group row">    
                      <div class="col-sm-3">
                        <label class="control-label"for="visit-purpose">Type of payment:</label>
                      </div>
                      
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-pay"><i class="fa-solid fa-cash-register"></i></span>
                        </div>
                        <div className='form-control'>Online</div>
                        <div className='form-control'>{data.total_price || data.order_price}</div>
                      </div>     
                   </div>
              
                 <hr/>  
                 
                 <div class="form-group row"> 
                    <div class="col-sm-3">
                      <label class="control-label" for="delivery_status">Delivery Status :</label>
                    </div>
                    <div class="input-group col-sm-9">
                      <>
                        <select 
                            id="delivery_status" 
                            disabled={cannotChangeStatus} 
                            defaultValue={data.order_status} 
                            className="form-control" 
                            onChange={(e) => setNextStatus(e.target.value)} 
                            style={{ width: '130px' }}
                        >
                          {
                            Object.entries(orderStatuses).map(([key, value]) => {
                              // Check if the current role can change the status
                              const canChangeStatus = checkIfRoleCanChangeStatus(key, 'deliver', data.order_status); // Replace 'seller' with dynamic role
                              if (cannotChangeStatus || !canChangeStatus) {
                                return (
                                  <option key={key} value={key} disabled selected={data.order_status === key}>
                                      {value.status}
                                  </option>
                                );
                              }
                              return (
                                <option key={key} value={key} selected={data.order_status === key}>
                                    {value.status}
                                </option>
                              );
                            })
                          }
                        </select>
                      </>
                    </div>  
                 </div>

                 <div class="form-group row"> 
                    <div class="col-sm-3">
                      <label class="control-label" for="delivery_status">Order Images :</label>
                    </div>
                    <div class="input-group col-sm-9">
                      <>
                        <input type='file' id="order_images" onChange={(e) => uploadCancelReplaceReturnImages(e, data.id)} multiple/>
                      </>
                    </div>  
                 </div>

                 <div class="form-group row"> 
                    <div class="col-sm-3">
                      <label class="control-label" for="comment">Comments:</label>
                    </div>
                     
                    <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-mail"><i class="fa fa-comment"></i></span>
                        </div>
                        <textarea class="form-control" rows="5" id="comment"></textarea>
                    </div>  
                 </div>
                   
                 <div class="form-group row">
                   <div class="col-sm-12">
                     <button class="btn btn-default" id="submit" onClick={() => updateOrderStatus(data.id, data.product_id ? 'product' : 'service', data?.product_item?.self_deliver, data?.order?.user?.id, data?.product_item?.product?.seller_address_gst, data?.order_price, data?.no_return)}>Submit</button>
                   </div>
                 </div>
   
           </div> 
                  
         </div>
       </div>
      </>
     )
  } else {
    return (<></>)
  }
}

export default DeliveryDetails