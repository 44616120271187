import React, { useEffect, useState, useMemo } from 'react';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../../../utils/Exports';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import { formatHeaderName } from '../../../utils/formatHeaderName';
import { parseValue } from '../../../utils/Common';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../../utils/Common');

function LogisticReport() {
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const { CSVDownloader } = useCSVDownloader();

    useEffect(() => {
        getReportData();
    }, []);

    const getReportData = async () => {
        var parameters = {
            'seller_id': localStorage.getItem('sellerId'),
            'report_name': 'logistic_reports',
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        };
        let response = await common.apiRequest('post', '/reports/get-reports', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        const keysToExclude = ['order_item_id'];

        const cols = [
          {
              field: 'slNo',
              headerName: 'Sl No.',
              valueGetter: (params) => params.node.rowIndex + 1,
              checkboxSelection: true,
              headerCheckboxSelection: true,
              width: 100
          },
          ...data.keys
              .filter(key => !keysToExclude.includes(key))
              .map(key => ({
                  field: key,
                  headerName: formatHeaderName(key),
                  filter: 'agTextColumnFilter',
                  valueFormatter: formatCellValue(),
                  ...(key.includes('price') || key.includes('value') || key.includes('gst') ? 
                      { width: 130, type: 'numericColumn', filter: 'agNumberColumnFilter' } : {}),
                  ...(key.includes('date') || key.includes('created_at') || key.includes('updated_at') ? 
                      { width: 160 } : {})
              }))
      ];
        setColumnDefs(cols);

        // Set row data
        setRowData(data.reportData);
    }
    const ChangeIntDecimals = [
      'order_price','taxable_value','sigst','scgst','ssgst','sutgst'
     ];
   
      
     const formatCellValue = () => {
      return (params) => {
        const value = params.value;
        const field = params.colDef.field;
        if (value === null || value === undefined) {
          return '';
        }
    
        // Add specific check for GST fields
        if (field === 'gst_of_supplier' || field.includes('gst_number')) {
          return value; // Return GST number as-is
        }
    
        if (ChangeIntDecimals.includes(field)) {
          return parseValue(value, 'float');
        }
    
        if (field.includes('price') || field.includes('value') || 
            field.includes('amount') || field.includes('charges')) {
          const numValue = typeof value === 'string' ? parseFloat(value) : value;
          if (!isNaN(numValue)) {
            return numValue;
          }
        }
    
        if (field.includes('date') || field.includes('created_at') || field.includes('updated_at')) {
          if (value && !isNaN(Date.parse(value))) {
            const date = new Date(value);
            return date.toLocaleDateString();
          }
        }
        return value;
      };
    };
    const exportPDF = () => {
        const doc = new jsPDF('landscape', 'pt', 'a4');
        doc.text("Logistic Report", 40, 40);
        
        const columns = columnDefs.map(col => ({ header: col.headerName, dataKey: col.field }));
        
        doc.autoTable({
            columns: columns,
            body: rowData,
            startY: 50,
        });

        doc.save("logistic_report.pdf");
    };

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
        // editable: true,
    }), []);
    
      const excludeFields = ['order_item_id'];

      const data = rowData.map((row, index) => {
        const newRow = {
            slNo: index + 1 
        };
          Object.keys(row)
              .filter(key => !excludeFields.includes(key))
              .forEach(key => {
                  newRow[formatHeaderName(key)] = row[key];
              });
          return newRow;
      });

    const handleExportCSV = () => {
      exportToCSV(data, 'LogisticReport');
    }
    const handleExportExcel = () => {
      exportToExcel(data, 'LogisticReport');
    }
    
    const handleExportPDF = () => {
      exportToPDF(data, 'LogisticReport');
    }
    return (
        <>
            <div className="row report-margin">
                <div className='col-md-6'>
                    <h3>Logistics Reports</h3>
                </div>
                <div className="col-md-12 ml-2">
                    <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>      
                </div>
                <div className='col-md-6 row ml-2'>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
         <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
         <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="ag-theme-quartz" style={{height: 600, width: '100%'}}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[10, 25, 50]}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default LogisticReport;