import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { Modal, ModalBody, ModalHeader, Input, FormGroup, Button, Label, Form } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import moment from 'moment';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import $ from 'jquery';
import envConfig from '../../config/envConfig';
const common = require('../../utils/Common');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SellerServices = () => {
    let navigate = useNavigate();
    const [servicesData, setServicesData] = useState([]);
    console.log(servicesData)
    const [editservicesData, setEditServicesData] = useState([]);
    const [pincodeModal, setPincodeModal] = useState(false);
    const [pincodes, setPincodes] = useState([]);
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const toggle = () => setModal(!modal);
    const toggleEditModal = () => setEditModal(!editModal);

    const getServiceData = async () => {
        let data = {
            'action': 'read',
            'seller_id': localStorage.getItem('sellerId'),
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        }
        let response = await common.apiRequest('post', '/seller/crud-seller-services', data);
        if (response.data.code === 'success') {
            let filteredService = [];
            if (response.data.data && response.data.data.length > 0) {
                filteredService = response.data.data.filter(service => {
                return service.seller_address && service.seller_address.delete_address === false;
              });
            }
            setServicesData(filteredService);
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const data = servicesData.map(data => ({
        "Service Name": data.service_name,
        "Parent Category": data.parent_category.category_name,
        "Sub Category": data.sub_category.category_name,
        "Service Type": data.service_type,
        "Service Image": data.service_image,
        "Service Description": data.service_description,
        "Price": data.price,
        "Service SKU": data.service_sku,
        "Service By": data.service_by,  
        "Gst Rate": data.gst_rate,
        "Taxable Value": data.taxable_value,
        'Supply from state':data.seller_address.state,
        'Supply from gst no':data.seller_address.gst_no,
        "Logistics Delivery Type": data.logistics_delivery_type,
        "Range In Grams From": data.range_in_grams_from,
        "Range In Grams To": data.range_in_grams_to,
        "zone A": data.zone_a,
        "zone B": data.zone_b,
        "zone C": data.zone_c,
        "zone D": data.zone_d,
        "zone D": data.zone_e,
        "zone F": data.zone_f,
        "Min Delivery Time": data.min_delivery_time,
        "Max Delivery Time": data.max_delivery_time,
        "Additional Grams": data.additional_grams,
        "Additional Price Add In Every Zone": data.additional_price_add_in_every_zone,
        "Hsn Code": data.hsn_code,
        "Operated State Pincodes Prices": data.operated_state_pincodes_prices,
        "Service Date": data.created_at,
    }));

    const uploadServiceImage = async (files, serviceId) => {
        var fileData = new FormData();
        for (const key of Object.keys(files)) {
            fileData.append('service_images', files[key]);
            fileData.append('id', serviceId);
        }
        var response = await common.apiRequest('post', '/seller/update-service-images', fileData);
        common.alert(response.data?.message);
        $('#service_image').val('');
        // window.location.reload(true);
        // window.location.href = "/seller/services";
        getServiceData()
    }
    const handleImageDelete = async (serviceId, imagePath) => {
        console.log("Deleting image:", imagePath, "for service:", serviceId);
        
        let data = {
            service_id: serviceId,
            images_to_delete: [imagePath]
        };

        let response = await common.apiRequest('post', '/seller/delete-service-images', data);
        if (response.data.code === 'success') {
            // Update the UI immediately
            const updatedServices = servicesData.map(service => {
                if (service.id === serviceId) {
                    const currentImages = service.service_image.split(',');
                    const remainingImages = currentImages.filter(img => img !== imagePath);
                    return {
                        ...service,
                        service_image: remainingImages.join(',')
                    };
                }
                return service;
            });
            
            setServicesData(updatedServices);
            common.alert('Image deleted successfully');
        } else {
            common.alert(response.data?.message || 'Failed to delete image', 'error');
        }
    };
        
    useEffect(() => {
        getServiceData();
    }, []);

    const togglePincodeModal = () => {
        setPincodeModal(!pincodeModal);
    }

    const viewPincodes = async (pincodes) => {
        if (pincodes) {
            pincodes = pincodes.split('--');
            pincodes[0] = pincodes[0].replaceAll('-', '');
            pincodes[pincodes.length - 1] = pincodes[pincodes.length - 1].replaceAll('-', '');
            let data = {
                'pincodes': pincodes,
            }
            let response = await common.apiRequest('post', '/seller/get-pincodes', data);
            if (response.data.code === 'success') {
                togglePincodeModal();
                setPincodes(response.data.data);
            } else {
                common.alert(response.data.error, 'error');
            }
        }
    }
    
    const showEditDetails = (Editdata) => {
        setEditServicesData(Editdata)
        toggleEditModal();
    }

    const addDetail = async () => {
        var parameters = {
            'id': editservicesData.id,
            'action': 'update',
            'service_type': $('#service_type').val(),
            'service_description': $('#service_description').val(),
            'price': $('#price').val(),
            'service_sku': $('#service_sku').val(),
            'service_by': $('#service_by').val(),
            'gst_rate': $('#gst_rate').val(),
            'taxable_value': $('#taxable_value').val(),
            'supply_from_state': $('#supply_from_state').val(),
            'supply_gst_no': $('#supply_gst_no').val(),
            'logistics_delivery_type': $('#logistics_delivery_type').val(),
            'range_in_grams_from': parseInt($('#range_in_grams_from').val(), 10),         
            'range_in_grams_to': parseInt($('#range_in_grams_to').val(), 10),
            'zone_a': parseInt($('#zone_a').val(), 10),
            'zone_b': parseInt($('#zone_b').val(), 10),
            'zone_c': parseInt($('#zone_c').val(), 10),
            'zone_d': parseInt($('#zone_d').val(), 10),
            'zone_e': parseInt($('#zone_e').val(), 10),
            'zone_f': parseInt($('#zone_f').val(), 10),
            'min_delivery_time': $('#min_delivery_time').val(),
            'max_delivery_time': $('#max_delivery_time').val(),
            'additional_grams': parseInt($('#additional_grams').val(), 10),        
            'additional_price_add_in_every_zone': parseInt($('#additional_price_add_in_every_zone').val(),10),
            'active': 0
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-services', parameters);
        getServiceData();
        if (response.status == 200) {
            if (response.data.code == "success") {
                common.alert(response.data?.message);
            } else {
                common.alert(response.data.error, 'error');
            }
            toggleEditModal(false)
        }
    }

    const deleteService = async (data) => {
        var parameters = {
            'id':  data.id,
            "service_id": data.seller_service_pincodes.service_id,
            'action': 'delete',
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-services', parameters);
        if (response.status == 200) {
            if (response.data.code == "success") {
                common.alert(response.data?.message);
            } else {
                common.alert(response.data.error, 'error');
            }
            navigate('/seller/services');
        }
        getServiceData()
    }

    const columnDefs = useMemo(() => [
        {
            field: "service_name",
            headerName: "Service Name",
            checkboxSelection: true,
            editable: true,
        },
        { field: "parent_category.category_name", headerName: "Parent Category" },
        { field: "sub_category.category_name", headerName: "Sub Category" },
        { field: "service_type", headerName: "Service Type" ,
         valueFormatter: (params) => {
            if (params.value) {
              return params.value.charAt(0).toUpperCase() + params.value.slice(1);
            }
            return '';
          }},
        {
            field: "service_image",
            headerName: "Service Image",
            cellRenderer: (params) => {
                return params.value ? (
                    <div className='mt-3'>
                    <Zoom>
                        <img src={envConfig.api_url + params.value} style={{height: '100px', width: '100px'}}/>
                    </Zoom>
                        <input type="file" className="mt-3" accept="image/*" onChange={(e) => uploadServiceImage(e.target.files, params.data.id)} />
                                        <button 
                                            className="btn btn-danger btn-sm position-absolute" 
                                            style={{ 
                                                top: '-10px', 
                                                right: '-10px', 
                                                padding: '0px 4px',
                                                fontSize: '12px',
                                                minWidth: '20px',
                                                height: '20px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1000
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleImageDelete(params.data.id,params.value);
                                            }}
                                        >
                                            ×
                                        </button>
                    </div>
                ) : (
                    <input type="file" accept="image/*" onChange={(e) => uploadServiceImage(e.target.files, params.data.id)} />
                );
            }
        },
        { field: "service_description", headerName: "Service Description" },
        { field: "price", headerName: "Service Price" },
        { field: "service_sku", headerName: "Service SKU" },
        { field: "service_by", headerName: "Service By" },
        { field: "gst_rate", headerName: "GST Rate" },
        { field: "taxable_value", headerName: "Taxable Value" },
        { field: "seller_address.state", headerName: "Supply From State" },
        { field: "seller_address.gst_no", headerName: "Supply From GST No" },
        { field: "logistics_delivery_type", headerName: "Logistics Delivery Type" },
        { field: "range_in_grams_from", headerName: "Range In Grams From" },
        { field: "range_in_grams_to", headerName: "Range In Grams To" },
        { field: "zone_a", headerName: "Zone A" },
        { field: "zone_b", headerName: "Zone B" },
        { field: "zone_c", headerName: "Zone C" },
        { field: "zone_d", headerName: "Zone D" },
        { field: "zone_e", headerName: "Zone E" },
        { field: "zone_f", headerName: "Zone F" },
        { field: "min_delivery_time", headerName: "Minimum Delivery Time" },
        { field: "max_delivery_time", headerName: "Maximum Delivery Time" },
        { field: "additional_grams", headerName: "Additional Grams" },
        { field: "additional_price_add_in_every_zone", headerName: "Additional Price Add In Every Zone" },
        { field: "created_at", headerName: "Service Date" },
        {
            field: "active",
            headerName: "Approve",
            cellRenderer: (params) => params.value == "1" ? "Approve" : "Not Approve"
        },
        {
            headerName: "Pincodes",
            cellRenderer: (params) => (
                <button className='btn btn-info btn-sm' onClick={() => viewPincodes(params.data.seller_service_pincodes.length > 0 ? params.data.seller_service_pincodes[0].pincode : null)}>View Pincodes</button>
            )
        },
        {
            headerName: "Edit",
            cellRenderer: (params) => (
                <button className='btn btn-primary btn-sm' onClick={() => showEditDetails(params.data)}>Edit</button>
            )
        },
        {
            headerName: "Delete",
            cellRenderer: (params) => (
                <button className='btn btn-primary btn-sm' onClick={() => deleteService(params.data)}>Delete</button>
            )
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        filter: 'agTextColumnFilter',
        floatingFilter: true,
    }), []);
    const handleExportCSV = () => {
        exportToCSV(data, 'SellerService');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'SellerService');
    }

    const handleExportPDF = () => {
        exportToPDF(data, 'SellerService');
    }
    
    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <h3>Seller Services</h3>
                </div>
                <div className="col-md-12">

                <Form className='row'>
                        <FormGroup className='col-md-4 row ml-2'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getServiceData} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getServiceData} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>
                <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                </div>
            </div>
            <div className='row tables'>
                <div className='col-md-12'>
                    <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                        <AgGridReact
                            rowData={servicesData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[10, 25, 50]}
                            rowHeight={200}
                        />
                    </div>
                </div>
            </div>
            <Modal isOpen={pincodeModal} toggle={togglePincodeModal} size="md">
                <ModalHeader toggle={togglePincodeModal}>Pincodes List</ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <div style={{ wordWrap: 'break-word', maxWidth: '100%' }}>
                        {
                            pincodes.map((pincode) => {
                                return (
                                    <div key={pincode.pincode} style={{ marginBottom: '10px' }}>
                                        {pincode.pincode}
                                    </div>
                                );
                            })
                        }
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={editModal} toggle={toggleEditModal} size="lg">
                <ModalHeader toggle={toggleEditModal}>Edit Service Details</ModalHeader>
                <ModalBody>
                            {Object.keys(editservicesData).length > 0 ?(
                                    <Form>
                                            <FormGroup>
                                                <Label for="newEntry">Service Name</Label>
                                                <Input
                                                    type='text' id="service_name" defaultValue={editservicesData.service_name}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new Service Type</Label>
                                                <Input
                                                    type='text' id="service_type" defaultValue={editservicesData.service_type}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Service Description</Label>
                                                <Input
                                                    type='text' id="service_description" defaultValue={editservicesData.service_description}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new price</Label>
                                                <Input
                                                    type='text' id="price" defaultValue={editservicesData.price}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new Service Sku</Label>
                                                <Input
                                                    type='text' id="service_sku" defaultValue={editservicesData.service_sku}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new Service By</Label>
                                                <Input
                                                    type='text' id="service_by" defaultValue={editservicesData.service_by}
                                                />
                                            </FormGroup>
                                            
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new Gst Rate</Label>
                                                <Input
                                                    type='text' id="gst_rate" defaultValue={editservicesData.gst_rate}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new Taxable Value</Label>
                                                <Input
                                                    type='text' id="taxable_value" defaultValue={editservicesData.taxable_value}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new Supply From State</Label>
                                                <Input
                                                    type='text' id="supply_from_state" defaultValue={editservicesData.supply_from_state}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a New Supply From Gst No</Label>
                                                <Input
                                                    type='text' id="supply_from_gst_no" defaultValue={editservicesData.supply_from_gst_no}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new Logistics Delivery Type</Label>
                                                <Input
                                                    type='text' id="logistics_delivery_type" defaultValue={editservicesData.logistics_delivery_type}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new Range In Grams From</Label>
                                                <Input
                                                    type='text' id="range_in_grams_from" defaultValue={editservicesData.range_in_grams_from}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new range In Grams To</Label>
                                                <Input
                                                    type='text' id="range_in_grams_to" defaultValue={editservicesData.range_in_grams_to}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone a</Label>
                                                <Input
                                                    type='text' id="zone_a" defaultValue={editservicesData.zone_a}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone d</Label>
                                                <Input
                                                    type='text' id="zone_d" defaultValue={editservicesData.zone_d}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone c</Label>
                                                <Input
                                                    type='text' id="zone_c" defaultValue={editservicesData.zone_c}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone d</Label>
                                                <Input
                                                    type='text' id="zone_d" defaultValue={editservicesData.zone_d}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone e</Label>
                                                <Input
                                                    type='text' id="zone_e" defaultValue={editservicesData.zone_e}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone f</Label>
                                                <Input
                                                    type='text' id="zone_f" defaultValue={editservicesData.zone_f}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new min delivery time</Label>
                                                <Input
                                                    type='text' id="min_delivery_time" defaultValue={editservicesData.min_delivery_time}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new max delivery time</Label>
                                                <Input
                                                    type='text' id="max_delivery_time" defaultValue={editservicesData.max_delivery_time}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new additional grams</Label>
                                                <Input
                                                    type='text' id="additional_grams" defaultValue={editservicesData.additional_grams}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new additional price add in every zone</Label>
                                                <Input
                                                    type='text' id="additional_price_add_in_every_zone" defaultValue={editservicesData.additional_price_add_in_every_zone}
                                                />
                                            </FormGroup>
                                            <Button className='submit'  onClick={addDetail}>
                                              Submit
                                          </Button>
                                    </Form>
                                ):""}

                            </ModalBody>
                                 
            </Modal>
        </>
    );
};

export default SellerServices;