import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Outlet, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Home from './components/home/HomeComponent';
import FeedBackView from './components/feedback/FeedBackView';
import Login from './components/LoginComponent';
import SignUp from './components/SignUpComponent';
import NoPage from './components/NoPageComponent';
import Main from './components/MainComponent';
import changeLoginStatusAction from "./actions/changeLoginStatusAction";
import ServiceView from './components/service/ServiceView';
import NavbarView from './components/navbar/NavbarView';
import Footer from './components/footer/Footer';
import FileExport from './components/fileexport';
import CartView from './components/cart/CartView';
import CartCheckoutAddress from './components/cart/CheckoutAddress';
import Payment from './components/cart/Payment';
import FileUpload from './components/seller-portal/FileUpload';
import ForgotPass from './components/ForgetPass';
import ForgotUser from './components/ForgetUserName';
import Business from './components/GoToBusines';
import ProductDescription from './components/mainproductview/MainProductView';
import Orders from './components/yourorders/YourOrdersView';
import MyAccount from './components/useraccount/UserAccountView';
import SHome from './components/seller-portal/home/SHome';
import Sidebar from './components/seller-portal/sidebar/SSidebar';
import SellerProduct from './components/seller-portal/SellerProduct';
import SellerOrders from './components/seller-portal/SellerOrders';
import Address from './components/user_address/Address';
import AddEditAddress from './components/user_address/AddEditAddress';
import TdsReport from './components/seller-portal/reports/TdsReport';
import PaymentReport from './components/seller-portal/reports/PaymentReport';
import BankReport from './components/seller-portal/reports/BankReport';
import ReceiptReport from './components/seller-portal/reports/ReceiptReport';
import VendorServicesDetails from './components/seller-portal/reports/VendorServicesDetails';
import VendorProductsDetails from './components/seller-portal/reports/VendorProductsDetails';
import VendorProfile from './components/seller-portal/reports/VendorProfile';
import CustomerProfile from './components/seller-portal/reports/CustomerProfile';
import VendorReport from './components/seller-portal/reports/VendorReport';
import SRRRReport from './components/seller-portal/reports/SRRRReport';
import LogisticReport from './components/seller-portal/reports/LogisticReport';
// import CMSData from './components/CMSData';
import CMSData from './components/footer/CMSData';
import PersonalView from './components/personal/PersonalView';
import QueryView from './components/query/QueryView';
import UnderProcess from './components/UnderProcess';
import SellerAddServices from './components/seller-portal/SellerAddService';
import SellerServices from './components/seller-portal/SellerServices';
import Filterpage from './components/home/partials/Filterpage';
import ServiceCardView from './components/servicecard/ServiceCardView';
import UserServiceAvliable from './components/useraccount/UserServiceAvliable';
import UserRefeanceDetails from './components/useraccount/UserRefeanceDetails';
import ServiceDetailsView from './components/servicedetails/ServiceDetailsView';
import DeliveryDetails from './components/Delivery/DeliveryDetails';
import DeliveryLogin from './components/Delivery/DeliveryLogin';
import DeliveryNav from './components/Delivery/DeliveryNav';
import DeliveryProduct from './components/Delivery/DeliveryProduct';
import DeliveryHome from './components/Delivery/DeliveryHome';
import AddDeliveryUsers from './components/seller-portal/AddDeliveryUsers';
import Search from './components/Search';
import GenerateInvoice from './components/GenerateInvoice';
import SellerProfile from './components/seller-portal/SellerProfile';
import StockInfo from './components/seller-portal/StockInfo';
import QrCode from './components/QrCode';
import OfflineSales from './components/seller-portal/OfflineSales';
import OfflineOrders from './components/OfflineOrders';
import ChangePassword from './components/personal/ChangePassword';
import SellerInfo from './components/seller-portal/SellerInfo';
import moment from 'moment';
import SellerReplyFeedback from './components/seller-portal/SellerReplyFeedback';
import SellerQr from './components/seller-portal/SellerQr';
import UserDocs from './components/useraccount/UserDocs';
import Seller_Address from './components/seller-portal/seller_address/Seller_Address';
import Seller_AddEditAddress from './components/seller-portal/seller_address/Seller_AddEditAddress';
import SellerChangePass from './components/seller-portal/SellerChangePass';
import SellerReplyQuery from './components/seller-portal/SellerReplyQuery';
import UserWallet from './components/userWallet';
import GenerateReturnInvoice from './components/GenerateReturnInvoice';
import PrivacyPolicy from './components/PrivacyPolicy';
import Broucher from './components/footer/Broucher';
import Amcp from './components/footer/Amcp';
import SellerLogisticsOrders from './components/seller-portal/SellerLogisticsOrders.js';
import RefferUsers from './components/useraccount/RefferUsers';
const common = require('./utils/Common');
const envConfig = require('./config/envConfig');

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  changeLoginStatusAction: (payload) => dispatch(changeLoginStatusAction(payload))
})

const WithNavFoot = (props) => {
  const [banner, setBanners] = props.banners;
  return(
    <>
      <NavbarView logo={props.logo} />
      <Outlet context={[banner, setBanners]}/>
      <Footer cmsData={props.cmsData}/>
    </>
  );
}

const WithFooterOnly = (props) => {
  // Only destructure banners if it exists
  const [banner, setBanners] = props.banners || [null, () => {}];
  return(
    <>
      <Outlet context={[banner, setBanners]}/>
      <Footer cmsData={props.cmsData}/>
    </>
  );
}

const WithoutNavFoot = () => {
  return(
    <Outlet />
  );
}

const WithSellerSideBar = () => {
  return(
    <div className='rows'>
      {/* <div className='col-md-2'> */}
        <Sidebar />
      {/* </div> */}
        <div className='col-md-12'>  {/* Offset to center */}
          <Outlet />
        </div>
    </div>
  );
}

const WithDeliveryNav = () => {
  return(
    <div className='row'>
      <div className='col-md-12'>
        <DeliveryNav />
      </div>
      <div className='col-md-12'>
        <Outlet />
      </div>
    </div>
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo : "",
      banners : "",
      cmsData : []
    };
  }

  componentDidMount() {
    if (envConfig.platform && (envConfig.platform == "dev" || envConfig.platform == "production")) {
      document.addEventListener('contextmenu', this.handleRightClick);
      document.addEventListener('keydown', this.handleKeydown);
    }
    this.getLogoBannerFooterData();
  }

  componentWillUnmount() {
    if (envConfig.platform && (envConfig.platform == "dev" || envConfig.platform == "production")) {
      document.removeEventListener('contextmenu', this.handleRightClick);
      document.removeEventListener('keydown', this.handleKeydown);
    }
  }

  handleRightClick = (event) => {
    event.preventDefault();
  }

  handleKeydown(event) {
    if (event.ctrlKey && event.shiftKey && event.keyCode === 73) {
      event.preventDefault();
    }
  }
  

  getLogoBannerFooterData = async () => {
    let data = {
        "access_token" : localStorage.getItem('token'),
        "web" : 1
    }
    let response = await common.apiRequest('post', '/admin/get-cms-data', data);
    if (response.status == 200) {
      if (response.data.code == 'success') {
        this.setState({
          logo : response.data.logoBannerData.length > 0 ? response.data.logoBannerData[0].logo : "",
          banners : response.data.logoBannerData.length > 0 ? response.data.logoBannerData[0].banners : [],
          cmsData : response.data.cmsData.length > 0 ? response.data.cmsData[0] : []
        });
      }
    }
    else {
      common.alert('Something went wrong', 'error');
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<WithoutNavFoot />}>
            {/* <Route path="/" element={<Main />} />
            <Route path="signup" element={<SignUp cmsData={this.state.cmsData}/>} />
            <Route path="login" element={<Login />} /> */}
            <Route path="forgotpass" element={<ForgotPass />} />
            <Route path="forgetuser" element={<ForgotUser />} />
            <Route path="*" element={<NoPage />} />
            <Route path="FileExport" element={< FileExport/>} />
            <Route path='delivery/login' element={<DeliveryLogin/>}/>
          </Route>

          <Route element={<WithFooterOnly banners={[this.state.banners, this.setBanners]} cmsData={this.state.cmsData}/>}>
            <Route path="signup" element={<SignUp cmsData={this.state.cmsData}/>} />
            <Route path="login" element={<Login />} />
          </Route>

          
          <Route element={<WithDeliveryNav />}>
            <Route path='delivery/home' element={<DeliveryHome/>}/>
            <Route path='delivery/products' element={<DeliveryProduct/>}/>
            <Route path='delivery/product/details' element={<DeliveryDetails/>}/>
          </Route>

          <Route element={<WithNavFoot logo={this.state.logo} cmsData={this.state.cmsData} banners={this.state.banners}/>}>
            <Route path="/" element={<Home />} />
            <Route path='/search' element={<Search />} />
            <Route path="home" element={<Home />} />
            <Route path="product/:product_id/:product_item_id" element={<ProductDescription />} />
            <Route path="service" element={<ServiceView />}/>
            <Route path="cart" element={<CartView/>}/>
            <Route path="cart/checkout/address" element={<CartCheckoutAddress/>}/>
            <Route path="cart/checkout/payment" element={<Payment/>}/>
            <Route path="generate-invoice" element={<GenerateInvoice/>}/>
            <Route path="generate-return-invoice" element={<GenerateReturnInvoice/>}/>

            <Route path="business" element={<Business cmsData={this.state.cmsData}/>}/>
            <Route path="orders/:order_id" element={<Orders/>}/>
            <Route path="my-account" element={<MyAccount/>}/>
            <Route path="my-account/address" element={<Address/>}/>
            <Route path="my-account/add-edit-address" element={<AddEditAddress/>}/>
            <Route path="PersonalView" element={<PersonalView/>}/>
            <Route path="FeedBackView" element={<FeedBackView/>}/>
            <Route path="QueryView" element={<QueryView/>}/>
            <Route path="UnderProcess" element={<UnderProcess/>}/>
            <Route path="Filterpage" element={<Filterpage/>}/>
            <Route path="/footer/cms-data" element={< CMSData/>} />
            <Route path="/service/:category_id" element={< ServiceCardView/>}/>
            <Route path="/service/details/:service_id" element={< ServiceDetailsView/>}/>
            <Route path="/userServiceAvliable" element={<UserServiceAvliable/>}/>
            <Route path="/UserRefeanceDetails" element={<UserRefeanceDetails/>}/>
            <Route path="/RefferUsers" element={<RefferUsers/>}/>
            <Route path="/QrCode" element={<QrCode/>}/>
            <Route path="/offline-orders" element={<OfflineOrders/>}/>
            <Route path="/ChangePassword" element={<ChangePassword/>}/>
            <Route path="/UserDocs" element={<UserDocs/>}/>
            <Route path="/my-account/wallet" element={<UserWallet/>}/>
            <Route path="/reports/tds-report" element={<TdsReport/>}/>
            <Route path="/service/list/:category_id" element={<ServiceCardView />} />
            <Route path="/service/category/:id" element={<ServiceView />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/Broucher" element={<Broucher />} />
            <Route path="/Amcp" element={<Amcp />} />
            <Route element={<WithSellerSideBar />}>
              <Route path="SHome" element={<SHome/>}/>
              <Route path="seller/products" element={<SellerProduct/>}/>
              <Route path="seller/services" element={<SellerServices/>}/>
              <Route path="seller/add-services" element={<SellerAddServices/>}/>
              <Route path="seller/upload" element={<FileUpload/>}/>
              <Route path="seller/orders" element={<SellerOrders/>}/>
              <Route path="seller/logistics-orders" element={<SellerLogisticsOrders/>}/>
              <Route path="seller/reports/tds-report" element={<TdsReport/>}/>
              <Route path="seller/reports/payment-report" element={<PaymentReport/>}/>
              <Route path="seller/reports/bank-report" element={<BankReport/>}/>
              <Route path="seller/reports/receipt-report" element={<ReceiptReport/>}/>
              <Route path="seller/reports/vendor-services-details" element={<VendorServicesDetails/>}/>
              {/* <Route path="seller/reports/vendor-products-details" element={<VendorProductsDetails/>}/> */}
              <Route path="seller/reports/vendor-profile" element={<VendorProfile/>}/>
              {/* <Route path="seller/reports/customer-profile" element={<CustomerProfile/>}/> */}
              <Route path="seller/reports/vendor-report" element={<VendorReport/>}/>
              <Route path="seller/reports/srrr-report" element={<SRRRReport/>}/>
              <Route path="seller/reports/logistic-report" element={<LogisticReport/>}/>
              <Route path="seller/delivery-users" element={<AddDeliveryUsers/>}/>
              {/* <Route path="seller/profile" element={<SellerProfile/>}/> */}
              <Route path="seller/StockInfo" element={<StockInfo/>}/>
              <Route path="seller/offline-sale" element={<OfflineSales/>}/>
              <Route path="seller/SellerInfo" element={<SellerInfo/>}/>
              <Route path="seller/SellerReplyFeedback" element={<SellerReplyFeedback/>}/>
              <Route path="seller/SellerQr" element={<SellerQr/>}/>
              <Route path="seller/Seller_Address" element={<Seller_Address/>}/>
              <Route path="seller/Seller_AddEditAddress" element={<Seller_AddEditAddress/>}/>
              <Route path="seller/SellerChangePass" element={<SellerChangePass/>}/>
              <Route path="seller/SellerReplyQuery" element={<SellerReplyQuery/>}/>

            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
