import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './homepage.css';
import common from '../../../utils/Common';
import moment from 'moment';
import envConfig from '../../../config/envConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setCartItems, addToCartRedux, removeFromCartRedux } from '../../../redux/cartSlice';
const MenuView = () =>  {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartCount = useSelector((state) => state.cart.count);
    const [products, setProducts] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [addedToCart, setAddToCart] = useState({});
    const scrollPositionRef = useRef(0);
    const containerRef = useRef(null);
    const limit = 10;
 
    // Filter products based on search term
    const filteredProducts = products.filter((product) =>
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const addressAddAlert = async () => {
        if (localStorage.getItem("userId") && (!localStorage.getItem("address_alert_date") || localStorage.getItem("address_alert_date") !== moment().format("YYYY-MM-DD"))) {
            let data = { "user_id": localStorage.getItem('userId') };
            let response = await common.apiRequest('post', '/users/get-address', data, 'web', false);
            if (response.status === 200) {
                if (response.data.code === 'success' && response.data.data.length === 0) {
                    common.alert("Please add home address", 'info');
                    localStorage.setItem("address_alert_date", moment().format("YYYY-MM-DD"));
                    window.location.href = '/my-account/address';
                }
            }
        }
    };

    const handleScroll = useCallback(() => {
        if (!containerRef.current || initialLoad || loading || !hasMore) return;
        
        const container = containerRef.current;
        const { scrollTop, scrollHeight, clientHeight } = container;
        const buffer = 200; // Increased buffer to load earlier
        
        // Check if user has scrolled near the bottom
        const isNearBottom = scrollHeight - (scrollTop + clientHeight) < buffer;
        
        if (isNearBottom) {
            setOffset(prevOffset => prevOffset + limit);
        }
    }, [initialLoad, loading, hasMore]);

    const productData = async (isLoadMore = false) => {
        if (loading) return;

        setLoading(true);

        let parameters = {
            'user_id': localStorage.getItem('userId'),
            'limit': limit,
            'offset': offset,
        };

        try {
            let response = await common.apiRequest('post', '/products/get-products', parameters, 'web', offset === 0);
            let data = (response.data && response.data.data) ? response.data.data : [];
            // let filteredProducts = [];

            // if (data.products && data.products.length > 0) {
            //     filteredProducts = data.products.filter(product => {
            //         return product.seller_address && product.seller_address.delete_address === false;
            //     });
            // }

            setProducts(prevProducts => isLoadMore ? [...prevProducts, ...data.products] : data.products);
            setHasMore(data.products && data.products.length === limit);
            setInitialLoad(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching products:', error);
        }
    };

    const initializeCartStatus = useCallback(() => {
        const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        dispatch(setCartItems(cartItems));

        const cartProductIds = cartItems.map(item => item.product_item_id);
        const newAddedToCart = {};
        products.forEach(product => {
            if (product.product_items && product.product_items.length > 0) {
                const productItemId = product.product_items[0].id;
                newAddedToCart[productItemId] = cartProductIds.includes(productItemId);
            }
        });
        setAddToCart(newAddedToCart);
    }, [products]);

    const saveScrollPosition = useCallback(() => {
        if (containerRef.current) {
            scrollPositionRef.current = containerRef.current.scrollTop;
        }
    }, []);

    const restoreScrollPosition = useCallback(() => {
        if (containerRef.current) {
            requestAnimationFrame(() => {
                containerRef.current.scrollTop = scrollPositionRef.current;
            });
        }
    }, []);

    const addToCart = async (productId, productItemId, supplypincode, selfDeliver, sellerId, afterPackItemWeight, sellerAddressId) => {
        if (!localStorage.getItem('token')) {
            navigate('/login');
            return;
        }
        if (loading) return;
        saveScrollPosition();

        var cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
        let newCartItem = {
            'product_id': productId,
            'product_item_id': productItemId,
            'quantity_purchased': 1,
            'user_id': localStorage.getItem('userId'),
            'supply_pincode': supplypincode,
            'service_id': 0,
            'type': 'product',
            'action': 'create',
            'self_deliver': selfDeliver,
            'seller_id': sellerId,
            'seller_address_id': sellerAddressId,
            'after_pack_item_weight': afterPackItemWeight.replace('GRM', '').replace('grm', ''),
            'active': 0
        };

        try {
            let response = await common.apiRequest('post', '/products/add-remove-cart-items', newCartItem);
            let data = (response.data) ? response.data : [];
            newCartItem.cart_id = data.cart_id;
            const cartItemForStorage = {
                ...newCartItem,
                user_id: undefined,
                action: undefined
            };
            delete newCartItem.user_id;
            delete newCartItem.action;
            cartItems.push(newCartItem);
            localStorage.setItem('cartItems', JSON.stringify(cartItems));
            dispatch(addToCartRedux(cartItemForStorage));
            setAddToCart(prev => ({ ...prev, [productItemId]: true }));
            common.alert(data.message, 'success');
        } catch (error) {
            console.error('Error adding to cart:', error);
        } finally {
            restoreScrollPosition();
        }
    };

    const removeFromCart = async (productItemId) => {
        if (loading) return;
        saveScrollPosition();

        var cartItems = JSON.parse(localStorage.getItem('cartItems'));
        if (cartItems) {
            const cartItemToRemove = cartItems.find(o => o.product_item_id === productItemId);
            if (!cartItemToRemove) return;

            try {
                cartItemToRemove.action = "delete";
                let response = await common.apiRequest('post', '/products/add-remove-cart-items', cartItemToRemove);
                let data = (response.data) ? response.data : [];

                const updatedCartItems = cartItems.filter(item => item.product_item_id !== productItemId);
                localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
                dispatch(removeFromCartRedux(productItemId));

                setAddToCart(prev => ({ ...prev, [productItemId]: false }));
                common.alert(data.message, 'success');
            } catch (error) {
                console.error('Error removing from cart:', error);
            } finally {
                restoreScrollPosition();
            }
        }
    };

    // Set up initial data load
    useEffect(() => {
        productData();
        if (offset === 0) {
            addressAddAlert();
        }
        
        // Add resize event listener to handle window size changes
        window.addEventListener('resize', handleScroll);
        return () => {
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    // Load more data when offset changes
    useEffect(() => {
        if (offset > 0) {
            productData(true);
        }
    }, [offset]);

    // Update cart status whenever products change
    useEffect(() => {
        if (containerRef.current) {
            const currentScrollPosition = containerRef.current.scrollTop;
            initializeCartStatus();
            requestAnimationFrame(() => {
                if (containerRef.current) {
                    containerRef.current.scrollTop = currentScrollPosition;
                }
            });
        }
    }, [products, initializeCartStatus]);

    // Make sure scroll handler is called when products are loaded
    useEffect(() => {
        if (!initialLoad && !loading) {
            // Force a check after products load to see if we need more
            handleScroll();
        }
    }, [initialLoad, loading, handleScroll]);

    return (
        <div
            ref={containerRef}
            className='mainproduct'
            style={{
                height: 'calc(100vh - 60px)',
                overflowY: 'auto',
                overflowX: 'hidden',
                position: 'relative',
                WebkitOverflowScrolling: 'touch' // Improved scrolling on iOS
            }}            
            onScroll={handleScroll}
        >
            <div className="container-fluid bg-transparent my-4 p-3" style={{ position: "relative" }}>
                <div className="row g-3">
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map((product) => {
                            if (!product.product_items || product.product_items.length === 0) return null;
                            const productItemId = product.product_items[0].id;
                            return (
                                product.product_items[0].remaining_stock !== 0 && (
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-3" key={product.id} style={{ marginBottom: "30px" }}>
                                        <div className="card h-100 shadow-sm transparent-card">
                                            <Link to={`/product/${product.id}/${product.product_items[0].id}`}>
                                                {product.product_items[0] && product.product_items[0].product_image ? (
                                                    <img src={envConfig.api_url + product.product_items[0].product_image.split(',')[0]} className="card-img-top mt-3" alt="product img" />
                                                ) : (
                                                    <div className="card-img-top" alt="product img"></div>
                                                )}
                                            </Link>
                                            <div className="label-top shadow-sm">
                                                <Link
                                                    className="text-white"
                                                    style={{
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        display: 'block',
                                                        maxWidth: '100px',
                                                    }}
                                                    to={`/product/${product.id}/${product.product_items[0].id}`}
                                                >
                                                    {product.name}
                                                </Link>
                                            </div>
                                            <div className="card-body">
                                                <div className="clearfix mb-3">
                                                    <span className="float-start badge rounded-pill bg-warning">
                                                        Rs. {common.parseValue(product.product_items[0].price, 'float')}
                                                    </span>
                                                </div>
                                                <div className="d-grid gap-2 my-4">
                                                    {!addedToCart[productItemId] ? (
                                                        <button
                                                            className='btn btn-warning bold-btn'
                                                            onClick={() => addToCart(
                                                                product.id,
                                                                productItemId,
                                                                product.seller_address.pincode,
                                                                product.product_items[0].self_deliver,
                                                                product.seller_id,
                                                                product.product_items[0].after_pack_item_weight,
                                                                product.seller_address_gst
                                                            )}
                                                        >
                                                            Add to Cart
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className='btn btn-danger bold-btn'
                                                            onClick={() => removeFromCart(productItemId)}
                                                        >
                                                            Remove
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            );
                        })
                    ) : !loading && !initialLoad ? (
                        <div className="col-12 text-center p-5">
                            <h4>No products found</h4>
                        </div>
                    ) : null}
                </div>
            </div>
            {loading && initialLoad && (
                <div className="text-center p-4">
                    Loading products...
                </div>
            )}
            {!hasMore && products.length > 0 && (
                <div className="text-center p-4">
                    No more products to load
                </div>
            )}
        </div>
    );
};

export default MenuView;


