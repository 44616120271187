import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
ModuleRegistry.registerModules([ClientSideRowModelModule]);
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

const StockInfo = () => {
    const [stockData, setStockData] = useState([]);
    const [gridApi, setGridApi] = useState(null);

    useEffect(() => {
        getStockInfo();
    }, []);

    const getStockInfo = async () => {
        try {
            const parameters = {
                action: 'read',
                'seller_id': localStorage.getItem('sellerId'),
            };   
            let response = await common.apiRequest('post', '/seller/left-Stock', parameters);
            let data = (response.data && response.data.data) ? response.data.data : [];
            console.log('Fetched data:', data); // Debug log
            setStockData(data);
        } catch (error) {
            console.error('Error fetching stock data:', error);
        }
    };

    const addRemoveStock = async (productId, stockQuantity, remainingStock, type) => {
        const inputValue = document.getElementById(`add_stock_${productId}`).value;
        if (!inputValue || parseInt(inputValue) < 0) {
            common.alert("Please enter valid stock quantity", "error");
            return;
        } 
        if (type === "remove" && parseInt(remainingStock) < parseInt(inputValue)) {
            common.alert("Stock to remove is greater than available stock", "error");
            return;
        }
        const parameters = {
            action: 'update',
            id: productId,
            qty_in_stock: type === "add" ? parseInt(stockQuantity) + parseInt(inputValue) : parseInt(stockQuantity) - parseInt(inputValue)
        };
        let response = await common.apiRequest('post', '/seller/left-Stock', parameters);
        if (response.data.code === "success") {
            common.alert(response.data.message, 'success');
        } else {
            common.alert(response.data.error, 'error');
        }
        document.getElementById(`add_stock_${productId}`).value = '';
        getStockInfo();
    };

    const StockManagementRenderer = (props) => {
        return (
            <div>
                <input
                    type="number"
                    className="form-control mb-2"
                    id={`add_stock_${props.data.id}`}
                    placeholder="Add/Remove Stock"
                />
                <button
                    className="btn btn-success me-2"
                    onClick={() => addRemoveStock(props.data.id, props.data.qty_in_stock, props.data.remaining_stock, 'add')}
                >
                    ADD
                </button>
                <button
                    className="btn btn-danger"
                    onClick={() => addRemoveStock(props.data.id, props.data.qty_in_stock, props.data.remaining_stock, 'remove')}
                >
                    REMOVE
                </button>
            </div>
        );
    };


    const ImageRenderer = (props) => (
        <div className="w-full h-auto aspect-square flex items-center justify-center p-1">
        <Zoom>
        <img
            src={props.value ? envConfig.api_url + props.value : ''}
            className="w-full h-full object-contain rounded"
            alt="Product"
            style={{ height: '150px', width: '150px', marginRight: '5px' }}
        />
        </Zoom>
        </div>

    );

    const columnDefs = useMemo(() => [
        { 
            field: 'product.name', 
            headerName: 'Product',
            valueGetter: (params) => params.data.product?.name || '',
            minWidth: 150,
            flex: 2
        },
        { 
            field: 'product_sku', 
            headerName: 'SKU',
            minWidth: 120,
            flex: 1
        },
        {
            field: 'product_image',
            headerName: 'Image',
            cellRenderer: ImageRenderer,
            filter: false,
            minWidth: 120,
            flex: 1
        },
        { 
            field: 'price', 
            headerName: 'Price',
            minWidth: 100,
            flex: 1
        },
        { 
            field: 'item_weight', 
            headerName: 'Weight',
            minWidth: 100,
            flex: 1
        },
        { 
            field: 'hsn_code', 
            headerName: 'HSN',
            minWidth: 100,
            flex: 1
        },
        { 
            field: 'qty_in_stock', 
            headerName: 'Stock Qty',
            minWidth: 110,
            flex: 1
        },
        { 
            field: 'remaining_stock', 
            headerName: 'Remaining',
            minWidth: 110,
            flex: 1
        },
        { 
            field: 'total_sales', 
            headerName: 'Sales',
            minWidth: 100,
            flex: 1
        },
        {
            headerName: 'Manage Stock',
            cellRenderer: StockManagementRenderer,
            filter: false,
            sortable: false,
            minWidth: 200,
            flex: 2
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true
    }), []);

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const handleExportCSV = () => {
        if (gridApi) {
            gridApi.exportDataAsCsv();
        }
    };

    return (
        <div className="flex flex-col p-4 w-full">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
                <h2 className="text-2xl font-bold">Stock Info</h2>
                <div className="flex flex-wrap gap-2">
                    <button 
                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded" 
                        onClick={handleExportCSV}
                    >
                        Export CSV
                    </button>
                </div>
            </div>

            <div 
                className="w-full ag-theme-quartz" 
                style={{ height: '600px' }}
            >
                <AgGridReact
                    rowData={stockData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                    onGridReady={onGridReady}
                    rowHeight={210}
                    domLayout="normal"
                    enableCellTextSelection={true}
                    suppressMovableColumns={true}
                />
            </div>
        </div>
    );
};

export default StockInfo;