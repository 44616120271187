import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, NavLink, useLocation } from 'react-router-dom';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { addToCartServiceRedux,removeFromCartServiceRedux} from '../../redux/cartSlice';
import { useDispatch, useSelector } from 'react-redux';

const envConfig = require('../../config/envConfig');

const common = require('../../utils/Common');

function ServiceCardView() {
    const [serviceData, setServiceData] = useState([]);
    const dispatch = useDispatch();
    const [inCart, setInCart] = useState([]);
    const attributes = useParams();
    const location = useLocation();
    let navigate = useNavigate();

    const fetchData = async () => {
        let data = {
            action: "read",
            source: "web",
            user_id: localStorage.getItem('userId'),
        }
        if (attributes.category_id == 0) {
            data.search_string = location.state ? location.state : "";
        } else {
            data.category_id = attributes.category_id;
        }
        try {
            let response = await common.apiRequest('post', '/seller/crud-seller-services', data);
            if (response.data.code == "success") {
                const data = response.data.data
                let filteredService = [];
                if (response.data.data && response.data.data.length > 0) {
                filteredService = response.data.data.filter(service => {
                    return service.seller_address && service.seller_address.delete_address === false;
                });
                }     
                setServiceData(filteredService);

            } else {
                common.alert(response.data.error, 'error');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchData();
        checkCart();
    }, []);

    const addToCart = async (serviceId, sellerId, sellerAddressId) => {
        if (!localStorage.getItem('token')) {
            navigate('/login');
            return;
        }
        let cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
        let newCartItem = {
            'product_id': 0,
            'product_item_id': 0,
            'quantity_purchased': 1,
            'user_id': localStorage.getItem('userId'),
            'supply_pincode': 0,
            'service_id': serviceId,
            'type': 'service',
            'seller_id': sellerId,
            'after_pack_item_weight': 0,
            'seller_address_id': sellerAddressId,
            'action': 'create',
            'active': 0
        };

        let response = await common.apiRequest('post', '/products/add-remove-cart-items', newCartItem);
        let data = (response.data) ? response.data : [];
        newCartItem.cart_id = data.cart_id;
        delete newCartItem.user_id;
        delete newCartItem.action;
        cartItems.push(newCartItem);
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
        dispatch( addToCartServiceRedux(newCartItem));

        // Create a new array and update the state
        setInCart((prevInCart) => [...prevInCart, serviceId]);
        common.alert(data.message, 'success');
    };


    const removeFromCart = async (serviceId) => {
        let cartItems = JSON.parse(localStorage.getItem('cartItems'));
        if (cartItems) {
            const cartItemToRemove = cartItems.find(o => o.service_id == serviceId);
            cartItemToRemove.action = "delete";
            let response = await common.apiRequest('post', '/products/add-remove-cart-items', cartItemToRemove);
            let data = (response.data) ? response.data : [];

            const updatedCartItems = cartItems.filter(item => item.service_id != serviceId);
            localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
            dispatch(removeFromCartServiceRedux(serviceId));

            // Filter out the removed service ID from the inCart state
            setInCart((prevInCart) => prevInCart.filter(id => id !== serviceId));
            common.alert(data.message, 'success');
        }
    };


    const checkCart = () => {
        var inCartItems = [];
        var cartItems = JSON.parse(localStorage.getItem('cartItems'));
        cartItems = cartItems.filter(o => {
            if (o.service_id != 0) {
                inCartItems.push(o.service_id);
            }
        })
        setInCart(inCartItems);
    }
    const movePage = (path, data) => {
        navigate(path, { state: data });
    }
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="breadcrumbs d-flex flex-row align-items-center">
                            <ul>
                                <li>
                                    <Link
                                        to="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const previousPath = location.state?.previousPath || '/service';
                                            navigate(previousPath);
                                        }}
                                    >
                                        Back to Categories
                                    </Link>
                                </li>
                                <li><i className="fa fa-angle-right" aria-hidden="true"></i>service</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row service_card_top">
                    <div className="col-md-8">
                        <h3>Our Services Providers</h3>
                        <hr />
                    </div>
                </div>

                {/* <!-- cart cards start --> */}
                <div className="mainservice">
                    <div className="container-fluid bg-transparent my-4 p-3" style={{ position: "relative", maxWidth: "1400px" }}>
                        <div className="row g-3">
                            {serviceData.length > 0 ? serviceData.map((data) => {
                                return (
                                    <div className="col-6 col-sm-6 col-md-4 col-lg-3" key={data.id} style={{ marginBottom: "30px" }}>
                                        <div className="card h-100 shadow-sm transparent-card">
                                            <Link to={`/service/details/${data.id}`}>
                                                {data.service_image && (
                                                    <img src={envConfig.api_url + data.service_image} className="card-img-top" alt="service img" />
                                                )}
                                            </Link>
                                                <Link className="text-white" to={`/service/details/${data.id}`}>
                                                <div className="label-top shadow-sm">

                                                    {data.service_name}
                                                    </div>

                                                </Link>
                                            <div className="card-body">
                                                <div className="clearfix mb-3">
                                                    <span className="float-start badge rounded-pill bg-warning">Rs. {data.price}</span>
                                                    <span className="float-end"><i className="fas fa-star productlist1"> 4.5</i></span>
                                                </div>
                                                <div className="d-grid gap-2 my-4">
                                                    {!inCart.includes(data.id) ? (
                                                        <button className="btn btn-warning bold-btn" onClick={() => addToCart(data.id, data.seller_id, data.seller_address_gst)}>
                                                            Add to Cart
                                                        </button>
                                                    ) : (
                                                        <button className="btn btn-danger bold-btn" onClick={() => removeFromCart(data.id)}>
                                                            Remove
                                                        </button>
                                                    )}
                                                </div>
                                                <p className="text-muted">{data.service_name}</p>
                                                <p className="text-muted">About:-{data.service_description}</p>
                                                <p className="text-muted">By {data.service_by}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <h3><center>No Services Found</center></h3>}
                        </div>
                    </div>
                </div>


            </div>
            {/* <!-- service card  end--> */}

        </React.Fragment>
    )
}
export default ServiceCardView;