import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import './yourorder.css'
import { Input, FormGroup, Button, Label, Form } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import { parseValue } from '../../utils/Common';
import moment from 'moment';

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

function YourOrdersView() {
    const navigate = useNavigate();
    const attributes = useParams();
    const handleClose = () => setShow(false);
    const handleCloses = () => setShows(false);
    const [orders, setOrders] = useState([]);
    console.log(orders);
    const [show, setShow] = useState(false);
    const [shows, setShows] = useState(false);
    const [orderId, setOrderId] = useState(false);
    const [sellerId, setSellerId] = useState(false);
    const [showCancel, setShowCancel] = useState([]);
    const [showReturn, setShowReturn] = useState([]);
    const [showReplace, setShowReplace] = useState([]);
    const [showReasons, setShowReasons] = useState(0);
    const [updateStatus, setUpdateStatus] = useState([]);
    const [showOtherReasonBox, setShowOtherReasonBox] = useState(0);
    const [imagesToUpload, setImagesToUpload] = useState([]);
    const feedbackType = [
        "Order", "Service", "Logistics"
    ]
    const [orderProgressModal, setOrderProgressModal] = useState(false);
    const [orderItemsData, setOrderItemsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 10;
    const observer = useRef();
    const lastOrderElementRef = useRef(null);
    

    useEffect(() => {
        getOrders();
    }, []);
    useEffect(() => {
        if (loading) return;
        
        // Disconnect previous observer
        if (observer.current) {
            observer.current.disconnect();
        }

        // Create new intersection observer
        observer.current = new IntersectionObserver(entries => {
            // If the last element is visible and we have more data to load
            if (entries[0].isIntersecting && hasMore) {
                loadMoreOrders();
            }
        }, { threshold: 0.5 });

        // Observe the last element
        if (lastOrderElementRef.current) {
            observer.current.observe(lastOrderElementRef.current);
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [orders, loading, hasMore]);
    // const getOrders = async (filterOrderId = null) => {
    //     let data = {
    //         "user_id": localStorage.getItem('userId'),
    //         "order_id": attributes.order_id != "null" && filterOrderId != 0 ? attributes.order_id : filterOrderId,
    //         'fromDate': $('#Date-from').val(),
    //         'toDate': $('#Date-to').val(),
    //     }
    //     let response = await common.apiRequest('post', '/orders/get-orders', data);
    //     if (response && response.data && response.data.code == "success") {
    //         let orders = response.data.data ? response.data.data : [];
    //         setOrders(orders);
    //         let config = await common.getSystemConfig('show_order_status_buttons');
    //         if (config) {
    //             setShowCancel(config.show_cancel ?? []);
    //             setShowReplace(config.show_replace ?? []);
    //             setShowReturn(config.show_return ?? []);
    //         }
    //     }
    // };

    const getOrders = async (filterOrderId = null, resetList = true) => {
        try {
            setLoading(true);
            
            // If resetting the list, reset offset
            if (resetList) {
                setOffset(0);
                setHasMore(true);
            }
            
            let data = {
                "user_id": localStorage.getItem('userId'),
                "order_id": attributes.order_id != "null" && filterOrderId != 0 ? attributes.order_id : filterOrderId,
                'fromDate': $('#Date-from').val(),
                'toDate': $('#Date-to').val(),
                'limit': limit,
                'offset': resetList ? 0 : offset
            };
            
            let response = await common.apiRequest('post', '/orders/get-orders', data, 'web', offset === 0);
            
            if (response && response.data && response.data.code == "success") {
                let newOrders = response.data.data ? response.data.data : [];
                
                // Check if we have more data to load
                if (newOrders.length < limit) {
                    setHasMore(false);
                }
                
                // Update orders list
                if (resetList) {
                    setOrders(newOrders);
                } else {
                    setOrders(prevOrders => [...prevOrders, ...newOrders]);
                }
                
                // Get system config
                let config = await common.getSystemConfig('show_order_status_buttons');
                if (config) {
                    setShowCancel(config.show_cancel ?? []);
                    setShowReplace(config.show_replace ?? []);
                    setShowReturn(config.show_return ?? []);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching orders:", error);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };
    const loadMoreOrders = () => {
        if (!loading && hasMore) {
            // Increase offset by limit for next page
            setOffset(prevOffset => prevOffset + limit);
            
            // Fetch more orders with new offset
            getOrders(null, false);
        }
    };

    const showReasonModal = async (orderId, orderStatus, type, selfDeliver, sellerAddressId) => {
        let reasons = await common.getSystemConfig('return_replace_cancel_resons');
        let reason = [];
        if (type == "cancel") {
            reason = reasons['cancel_reasons'];
        } else if (type == "replace") {
            reason = reasons['replace_reasons'];
        } else if (type == "return") {
            reason = reasons['return_reasons'];
        } else {
            return;
        }
        setShowReasons(!showReasons);
        setUpdateStatus({
            'order_id': orderId,
            'order_status': orderStatus,
            'reasons': reason,
            'type': type,
            'self_deliver': selfDeliver,
            'seller_address_id': sellerAddressId
        });
    }

    const changeOrderStatusOperations = async (orderId, orderStatus, type, selfDeliver, sellerAddressId) => {
        let reason = $('#cancel_return_replace_reason').val();
        if (!reason) {
            common.alert("Please select one reason", "error");
            return;
        }
        if (type != "cancel" && (!imagesToUpload || imagesToUpload.length == 0)) {
            common.alert("Please upload images of item", "error");
            return;
        }
        if (reason == "other") {
            if (!$('#other_reason').val()) {
                common.alert("Please put other reason", "error");
                return;
            }
            reason = $('#other_reason').val();
        }
        let column = type + "_reason";
        let data = {
            "order_id": orderId,
            "order_status": orderStatus,
            [column]: reason,
            "self_deliver": Number(selfDeliver),
            "seller_address_id": Number(sellerAddressId)
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code == "success") {
            await common.uploadOrderImages(imagesToUpload, orderId, 'buyer', 'user_' + type, 0);
            common.alert(response.data.message.replaceAll("_", " "));
            getOrders();
            showOrderProgressModal([]);
            setShowReasons(!showReasons);
        }
        setImagesToUpload([]);
    }

    const generateInvoice = async (orderId, sellerIds) => {
        let response = await common.getInvoiceData(sellerIds, orderId, ['seller_product', 'logistics']);
        navigate(
            '/generate-invoice',
            {
                state: {
                    invoiceData: response
                }
            }
        )
    }
    const generateReturnReplaceCancelInvoice = async (orderId, sellerIds, type, invoiceType) => {
        let response = await common.getInvoiceData(sellerIds, orderId, type, 1);
        navigate(
            '/generate-return-invoice',
            {
                state: {
                    invoiceData: response,
                    invoiceType: invoiceType
                }
            }
        )
    }

    const updateNoReturn = async (e, orderId) => {
        if (e.target.value == -1) {
            common.alert('Please select one option', 'error');
            return;
        }
        let data = {
            'no_return': parseInt(e.target.value),
            'id': orderId
        }
        let response = await common.apiRequest('post', '/orders/update-no-return', data);
        if (response.data.code == "success") {
            common.alert(response.data.message.replaceAll("_", " "));
            getOrders();
            showOrderProgressModal([]);
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const setCancelReturnReplaceReason = async (e) => {
        if (e.target.value == "other") {
            setShowOtherReasonBox(1);
        } else {
            setShowOtherReasonBox(0);
        }
    }

    const handleShow = (order_items_id, seller_id) => {
        setShow(true);
        setOrderId(order_items_id)
        setSellerId(seller_id)
    }
    const handleShowQuery = (order_items_id, seller_id) => {
        setShows(true);
        setOrderId(order_items_id)
        setSellerId(seller_id)

    }

    const showOrderProgressModal = (orderItemsData) => {
        setOrderProgressModal(!orderProgressModal);
        setOrderItemsData(orderItemsData);
    }

    const sendFeedBack = async () => {
        var parameters = {
            'user_id': localStorage.getItem('userId'),
            'user_feedback': $('#feedback').val(),
            'action': 'create',
            'order_id': orderId,
            'seller_id': sellerId,
            'type': $('#feedback_type').val(),
        };
        try {
            let response = await common.apiRequest('post', '/users/user-feedback', parameters);
            if (response.data.code === "success") {
                common.alert(response.data.message);
                setShow(false)

            } else {
                common.alert(response.data.error, 'error');
            }
        } catch (error) {
            common.alert('Error fetching data:', error);
        }
    };

    const sendQuery = async () => {
        var parameters = {
            'user_id': localStorage.getItem('userId'),
            'user_query': $('#query').val(),
            'action': 'create',
            'order_id': orderId,
            'seller_id': sellerId,
            'type': $('#Query_type').val(),
        };
        try {
            let response = await common.apiRequest('post', '/users/user-query', parameters);
            if (response.data.code === "success") {
                common.alert(response.data.message);
                setShows(false)
            } else {
                common.alert(response.data.error, 'error');
            }
        } catch (error) {
            common.alert('Error fetching data:', error);
        }
    };

    const filterOrder = () => {
        let orderId = $('#filter_order_id').val();
        if (orderId.length == 0) {
            getOrders(0);
        } else if (orderId.length >= 16) {
            getOrders(orderId);
        }
    }

    const uploadCancelReplaceReturnImages = async (event) => {
        const files = event?.target?.files;
        if (files.length > 0) {
            setImagesToUpload(files);
        } else {
            setImagesToUpload([]);
        }
    }

    
    
function safeGet(obj, path, defaultValue = 0) {
    if (!obj) return defaultValue;
    
    const keys = path.split('.');
    let current = obj;
    
    for (const key of keys) {
        if (current === null || current === undefined) return defaultValue;
        current = current[key];
    }
    
    return current !== null && current !== undefined ? current : defaultValue;
}

const data = orders.flatMap((item, index) => {
    
    if (!item.order_items || !Array.isArray(item.order_items)) {
        // console.error("Invalid order_items for item:", item);
        return [];
    }
    
    return item.order_items.map((orderItem) => {
        if (!orderItem) {
            // console.error("Invalid orderItem in order:", item.order_id);
            return null;
        }
        
        const isService = safeGet(orderItem, 'service_id', 0) > 0;
        const isOffline = safeGet(orderItem, 'offline_sale_id', 0) > 0;
        
        const invoice = item.invoices && Array.isArray(item.invoices) 
            ? item.invoices.find(inv => 
                inv && inv.invoice_type === "seller_product" && 
                inv.seller_id === orderItem.seller_id
            ) 
            : null;

        const logisticsInvoice = item.invoices && Array.isArray(item.invoices)
            ? item.invoices.find(inv => 
                inv && inv.seller_id === orderItem.logistics_vendor_id &&
                inv.invoice_no && inv.invoice_no.startsWith('LG')
            )
            : null;

        const returnInvoice = orderItem.order_invoices && Array.isArray(orderItem.order_invoices)
            ? orderItem.order_invoices.find(inv => 
                inv && inv.seller_id === orderItem.seller_id && 
                inv.invoice_type === "return_order"
            )
            : null;
            
        const cancleInvoice = orderItem.order_invoices && Array.isArray(orderItem.order_invoices)
            ? orderItem.order_invoices.find(inv => 
                inv && inv.seller_id === orderItem.seller_id && 
                inv.invoice_type === "cancel_order"
            )
            : null;

        const latestTracking = orderItem.order_trackings && 
                              Array.isArray(orderItem.order_trackings) && 
                              orderItem.order_trackings.length > 0
            ? orderItem.order_trackings[0]
            : null;
            
        const deliveryStatus = latestTracking 
            ? `${latestTracking.status} - ${latestTracking.remarks}` 
            : "No tracking data";

        let itemDescription = "No description available";
        if (isOffline) {
            itemDescription = safeGet(orderItem, 'offline_sale_product_datum.product_description', "No offline description");
        } else if (isService) {
            itemDescription = safeGet(orderItem, 'seller_service.service_description', "No service description");
        } else {
            itemDescription = safeGet(orderItem, 'product_item.product.description', "No product description");
        }

        const taxDetails = isOffline ? {
            Igst_Rate: parseValue(safeGet(orderItem, 'offline_sale_product_datum.igst_rate', 0), 'float'),
            IGST: parseValue(safeGet(orderItem, 'offline_sale_product_datum.igst_amount', 0), 'float'),
            Cgst_Rate: parseValue(safeGet(orderItem, 'offline_sale_product_datum.cgst_rate', 0), 'float'),
            CGST: parseValue(safeGet(orderItem, 'offline_sale_product_datum.cgst_amount', 0), 'float'),
            Sgst_Rate: parseValue(safeGet(orderItem, 'offline_sale_product_datum.sgst_utgst_rate', 0), 'float'),
            SGST: parseValue(safeGet(orderItem, 'offline_sale_product_datum.sgst_utgst_amount', 0), 'float'),
        } : {
            Igst_Rate: parseValue(safeGet(orderItem, 'igst_percent', 0), 'float'),
            IGST: parseValue(safeGet(orderItem, 'igst', 0), 'float'),
            Cgst_Rate: parseValue(safeGet(orderItem, 'cgst_percent', 0), 'float'),
            CGST: parseValue(safeGet(orderItem, 'cgst', 0), 'float'),
            Sgst_Rate: parseValue(safeGet(orderItem, 'sgst_percent', 0), 'float'),
            SGST: parseValue(safeGet(orderItem, 'sgst', 0), 'float'),
        };

       // First, find the logistics order that contains this order item's ID
            const relevantLogisticsOrder = item.logistics_orders && Array.isArray(item.logistics_orders)
            ? item.logistics_orders.find(lo => {
                const orderItemIds = String(lo.order_item_id || "").split(',')
                    .map(id => id.trim())
                    .filter(Boolean);
                return orderItemIds.includes(String(orderItem.id));
            })
            : null;

            // Then find the logistics invoice that matches this logistics order
            const logistics = item.invoices && Array.isArray(item.invoices) && relevantLogisticsOrder
            ? item.invoices.find(inv => 
                inv && 
                inv.invoice_type === "logistics" && 
                inv.logistics_order_id === relevantLogisticsOrder.id
            )
            : null;
            
        
            const logisticsOrders = (item.logistics_orders && Array.isArray(item.logistics_orders)) 
            ? item.logistics_orders 
            : [];
        
        // Find the logistics order that contains this order item's ID
        const logisticsOrder = logisticsOrders.find(lo => {
            const orderItemIds = String(lo.order_item_id || "").split(',')
                .map(id => id.trim())
                .filter(Boolean);
            return orderItemIds.includes(String(orderItem.id));
        }) || {};
        
        let logistOrderItemtId = safeGet(logisticsOrder, 'order_item_id', "0");
        const allOrderItemIds = Array.isArray(item.order_items) 
            ? item.order_items.map(item => safeGet(item, 'id', 0)) 
            : [];

        const availableIds = String(logistOrderItemtId).split(',')
            .map(id => {
                const trimmed = id.trim();
                return isNaN(parseInt(trimmed)) ? 0 : parseInt(trimmed);
            })
            .filter(id => id !== 0);

        const availableIdCount = availableIds.length || 1; 
        
        const pricePerItem = safeGet(logisticsOrder, 'order_price', 0) / availableIdCount;
        const taxableAmount = safeGet(logisticsOrder, 'taxable_value', 0) / availableIdCount;
        const igst = safeGet(logisticsOrder, 'igst', 0) / availableIdCount;
        const cgst = safeGet(logisticsOrder, 'cgst', 0) / availableIdCount;
        const sgst = safeGet(logisticsOrder, 'sgst', 0) / availableIdCount;
        const utgst = safeGet(logisticsOrder, 'utgst', 0) / availableIdCount;

        const logisticsDetails = {
            "Logistics Invoice No": safeGet(logistics, 'invoice_no', "N/A"),
            "Shipment ID": safeGet(orderItem, 'shipment_id', "N/A"),
            "Tracking No": safeGet(orderItem, 'tracking_no', "N/A"),
            "Logistics GST Rate": safeGet(logisticsOrder, 'gst_rate', 0),
            "Logistics price": parseValue(pricePerItem, 'float'),
            "Logistics Taxable Value": parseValue(taxableAmount, 'float'),
            "Logistics IGST %": safeGet(logisticsOrder, 'igst_percent', 0),
            "Logistics IGST": parseValue(igst, 'float'),
            "Logistics CGST %": safeGet(logisticsOrder, 'cgst_percent', 0),
            "Logistics CGST": parseValue(cgst, 'float'),
            "Logistics SGST %": safeGet(logisticsOrder, 'sgst_percent', 0),
            "Logistics SGST": parseValue(sgst, 'float'),
            "Logistics UTGST %": safeGet(logisticsOrder, 'utgst_percent', 0),
            "Logistics UTGST": parseValue(typeof utgst === 'object' ? safeGet(utgst, 'utgst', 0) : utgst, 'float')
        };

        // console.log(logisticsDetails, "logistics order");

        const logisticsType = item.logistics_orders && Array.isArray(item.logistics_orders)
            ? item.logistics_orders.find(logistics => logistics && logistics.id === orderItem.logistics_order_id)?.type
            : null;
        
        const allLogisticsTypes = item.logistics_orders && Array.isArray(item.logistics_orders)
            ? item.logistics_orders.map(logistics => logistics?.type).filter(Boolean)
            : [];
            
        
        const allTypes = allLogisticsTypes;
        
        
        const returnLogistics = orderItem.logistics_orders && Array.isArray(orderItem.logistics_orders)
        ? orderItem.logistics_orders.some(logistics => logistics && logistics.type === 'return')
        : false;
    
        const cancelLogistics = orderItem.logistics_orders && Array.isArray(orderItem.logistics_orders)
        ? orderItem.logistics_orders.some(logistics => logistics && logistics.type === 'cancel')
        : false;

        

    
    let returnLogisticsDetails = {};
    let cancelLogisticsDetails = {};
    let cancelLogisticsOrder = null;

    if (returnLogistics && !cancelLogistics) {
        const returnLogisticsOrder = orderItem.logistics_orders.find(lg => lg && lg.type === 'return') || {};
        returnLogisticsDetails = {
            "Return Shipment ID": safeGet(orderItem, 'shipment_id', "N/A"),
            "Return Tracking No": safeGet(orderItem, 'tracking_no', "N/A"),
            "Return Logistics GST Rate": safeGet(returnLogisticsOrder, 'gst_rate', 0),
            "Return Logistics price": -parseValue(pricePerItem, 'float'),
            "Return Logistics Taxable Value": -parseValue(taxableAmount, 'float'),
            "Return Logistics IGST %": safeGet(returnLogisticsOrder, 'igst_percent', 0),
            "Return Logistics IGST": -parseValue(igst, 'float'),
            "Return Logistics CGST %": safeGet(returnLogisticsOrder, 'cgst_percent', 0),
            "Return Logistics CGST": -parseValue(cgst, 'float'),
            "Return Logistics SGST %": safeGet(returnLogisticsOrder, 'sgst_percent', 0),
            "Return Logistics SGST": -parseValue(sgst, 'float'),
            "Return Logistics UTGST %": safeGet(returnLogisticsOrder, 'utgst_percent', 0),
            "Return Logistics UTGST": -parseValue(typeof utgst === 'object' ? safeGet(utgst, 'utgst', 0) : utgst, 'float')
        };
    }  
    else if (cancelLogistics) {
        cancelLogisticsOrder = orderItem.logistics_orders.find(lg => lg && lg.type === 'cancel') || {};
        cancelLogisticsDetails = {
            "Cancel Shipment ID": safeGet(orderItem, 'shipment_id', "N/A"),
            "Cancel Tracking No": safeGet(orderItem, 'tracking_no', "N/A"),
            "Cancel Logistics GST Rate": safeGet(cancelLogisticsOrder, 'gst_rate', 0),
            "Cancel Logistics price": -parseValue(pricePerItem, 'float'),
            "Cancel Logistics Taxable Value": -parseValue(taxableAmount, 'float'),
            "Cancel Logistics IGST %": safeGet(cancelLogisticsOrder, 'igst_percent', 0),
            "Cancel Logistics IGST": -parseValue(igst, 'float'),
            "Cancel Logistics CGST %": safeGet(cancelLogisticsOrder, 'cgst_percent', 0),
            "Cancel Logistics CGST": -parseValue(cgst, 'float'),
            "Cancel Logistics SGST %": safeGet(cancelLogisticsOrder, 'sgst_percent', 0),
            "Cancel Logistics SGST": -parseValue(sgst, 'float'),
            "Cancel Logistics UTGST %": safeGet(cancelLogisticsOrder, 'utgst_percent', 0),
            "Cancel Logistics UTGST": -parseValue(typeof utgst === 'object' ? safeGet(utgst, 'utgst', 0) : utgst, 'float')
        };
    }
    
    const replaceLogistics = item.logistics_orders && Array.isArray(item.logistics_orders)
        ? item.logistics_orders.find(logistics => logistics && logistics.type === 'replace' && logistics.id === orderItem.replace_logistics_order_id)
        : null;
    
    let replaceLogisticsDetails = {};
    if (replaceLogistics) {
        replaceLogisticsDetails = {
            "Replace Shipment ID": safeGet(orderItem, 'shipment_id', "N/A"),
            "Replace Tracking No": safeGet(orderItem, 'tracking_no', "N/A"),
            "Replace Logistics GST Rate": safeGet(replaceLogistics, 'gst_rate', 0),
            "Replace Logistics price": parseValue(pricePerItem, 'float'),
            "Replace Logistics Taxable Value": parseValue(taxableAmount, 'float'),
            "Replace Logistics IGST %": safeGet(replaceLogistics, 'igst_percent', 0),
            "Replace Logistics IGST": parseValue(igst, 'float'),
            "Replace Logistics CGST %": safeGet(replaceLogistics, 'cgst_percent', 0),
            "Replace Logistics CGST": parseValue(cgst, 'float'),
            "Replace Logistics SGST %": safeGet(replaceLogistics, 'sgst_percent', 0),
            "Replace Logistics SGST": parseValue(sgst, 'float'),
            "Replace Logistics UTGST %": safeGet(replaceLogistics, 'utgst_percent', 0),
            "Replace Logistics UTGST": parseValue(typeof utgst === 'object' ? safeGet(utgst, 'utgst', 0) : utgst, 'float')
        };
    }
    
      

        const returnDetails = {
            "Return Note No": safeGet(orderItem, 'rnote_no', "N/A"),
            "Return Note Type": safeGet(orderItem, 'rnote_type', "N/A"),
            "Return Supply Type": safeGet(orderItem, 'rnote_supply_type', "N/A"),
            "Return Note Date": safeGet(orderItem, 'rnote_date', "N/A"),
            "Return Note Value": parseValue(safeGet(orderItem, 'rnote_value', 0), 'float'),
            "Return GST Rate": safeGet(orderItem, 'rgst_rate', 0),
            "Return Taxable Value": parseValue(safeGet(orderItem, 'rtaxable_value', 0), 'float'),
            "Return IGST %": safeGet(orderItem, 'rigst_percent', 0),
            "Return IGST": parseValue(safeGet(orderItem, 'rigst', 0), 'float'),
            "Return CGST %": safeGet(orderItem, 'rcgst_percent', 0),
            "Return CGST": parseValue(safeGet(orderItem, 'rcgst', 0), 'float'),
            "Return SGST %": safeGet(orderItem, 'rsgst_percent', 0),
            "Return SGST": parseValue(safeGet(orderItem, 'rsgst', 0), 'float'),
            "Return UTGST %": safeGet(orderItem, 'rutgst_percent', 0),
            "Return UTGST": parseValue(safeGet(orderItem, 'rutgst', 0), 'float'),
            "Return IRN": safeGet(orderItem, 'rirn', "N/A"),
            "Return IRN Date": safeGet(orderItem, 'rirn_date', "N/A")
        };
        
        const cancleDetails = {
            "cancel Note No": safeGet(orderItem, 'cancel_note_no', "N/A"),
            "cancel Note Type": safeGet(orderItem, 'cancel_note_type', "N/A"),
            "cancel Supply Type": safeGet(orderItem, 'cancel_note_supply_type', "N/A"),
            "cancel Note Date": safeGet(orderItem, 'cancel_note_date', "N/A"),
            "cancel Note Value": -parseValue(safeGet(orderItem, 'cancel_note_value', 0), 'float'),
            "cancel GST Rate": safeGet(orderItem, 'cancel_gst_rate', 0),
            "cancel Taxable Value": parseValue(safeGet(orderItem, 'cancel_taxable_value', 0), 'float'),
            "cancel IGST %": safeGet(orderItem, 'cancel_igst_percent', 0),
            "cancel IGST": parseValue(safeGet(orderItem, 'cancel_igst', 0), 'float'),
            "cancel CGST %": safeGet(orderItem, 'cancel_cgst_percent', 0),
            "cancel CGST": parseValue(safeGet(orderItem, 'cancel_cgst', 0), 'float'),
            "cancel SGST %": safeGet(orderItem, 'cancel_sgst_percent', 0),
            "cancel SGST": parseValue(safeGet(orderItem, 'cancel_sgst', 0), 'float'),
            "cancel UTGST %": safeGet(orderItem, 'cancel_utgst_percent', 0),
            "cancel UTGST": parseValue(safeGet(orderItem, 'cancel_utgst', 0), 'float'),
            "cancel IRN": safeGet(orderItem, 'cancel_irn', "N/A"),
            "cancel IRN Date": safeGet(orderItem, 'cancel_irn_date', "N/A")
        };

        return {
            "S.no": parseValue(index + 1),
            "Order date": safeGet(item, 'updated_at', "N/A"),
            "Order ID": safeGet(item, 'order_id', "N/A"),
            "Invoice no": safeGet(invoice, 'invoice_no', "N/A"),
            "Item description": itemDescription,
            "Order amount": parseValue(safeGet(orderItem, 'order_price', 0), 'float'),
            "Taxable amount": parseValue(isOffline ? 
                safeGet(orderItem, 'offline_sale_product_datum.taxable_supply', 0) : 
                safeGet(orderItem, 'taxable_amount', 0), 'float'),
            ...taxDetails,
            "Total amount paid": parseValue(safeGet(orderItem, 'order_price', 0)),
            "Payment date": safeGet(invoice, 'created_at', "N/A"),
            "Mode of payment": isOffline ? "offline" : "online",
            "Payment ID": safeGet(item, 'payment_detail.razporpay_order_id', "N/A"),
            "Delivery status": safeGet(orderItem, 'order_status', "N/A"),
            "order_quantity": safeGet(orderItem, 'order_quantity', 0),
            "item_price": safeGet(orderItem, 'product_item.price', 0),
            ...returnDetails,
            ...returnLogisticsDetails,
            ...replaceLogisticsDetails,
            ...cancelLogisticsDetails,
            ...cancleDetails
        };
    }).filter(Boolean); 
});

    const handleExportCSV = () => {
        exportToCSV(data, 'your orders');
    }

    const handleExportExcel = () => {
        exportToExcel(data, 'your orders');
    }

    const handleExportPDF = () => {
        exportToPDF(data, 'your orders');
    }

    return (
        <React.Fragment>

            <div className="container-fluid">
                <div className="row">
                    <ol className="col-12 breadcrumb bg-transparent">
                        <li className="breadcrumb-item"><Link to="./" className="text-dark">Home</Link></li>
                        <li className="breadcrumb-item "><Link to="/my-account" className="text-dark">Your Account</Link></li>
                        <li className="breadcrumb-item active"><Link to="#" className="text-dark">Your Order</Link></li>
                    </ol>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4>Your Order</h4>
                    </div>
                    <div className="col-md-8">
                        <form className="form-inline float-right">
                            <div className="input-group">
                                <input type="text" className="form-control" name="your order name" id="filter_order_id" defaultValue={attributes.order_id != "null" ? attributes.order_id : null} placeholder="Order Search" onChange={filterOrder} />
                            </div>
                        </form>
                    </div>
                </div>


                <div className="row userodernav1">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <Link className="nav-link active" id="Orders-tab" data-toggle="tab" to="#Orders" role="tab" aria-controls="Orders" aria-selected="true">Orders</Link>
                            </li>
                            <div className='col-md-6 row'>
                                <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                                <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                                <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                            </div>
                            <div className='col-md-12'>
                                <Form className='row'>
                                    <FormGroup className='col-md-4 row ml-2'>
                                        <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                        <input
                                            type='date'
                                            defaultValue={moment().startOf('month').format('YYYY-MM-DD')}
                                            onChange={() => setTimeout(getOrders, 0)}
                                            className="form-control col-md-5"
                                            id="Date-from"
                                            name="Date-from"
                                        />
                                    </FormGroup>
                                    <FormGroup className='col-md-4 row'>
                                        <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                                        <input
                                            type='date'
                                            defaultValue={moment().endOf('month').format('YYYY-MM-DD')}
                                            onChange={() => setTimeout(getOrders, 0)}
                                            className="form-control col-md-5"
                                            id="Date-to"
                                            name="Date-to"
                                        />
                                    </FormGroup>
                                </Form>
                            </div>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="Orders" role="tabpanel" aria-labelledby="Orders-tab">
                                    {
                                        orders.length == 0 ?
                                        <div className="card userordercard1">
                                            <div className="card-header">
                                                <div className="row">
                                                    <h3>No orders yet</h3>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        orders.map((order,index) => {
                                            var logisticsPrice = order.logistics_orders ? order.invoices.filter(invoice => invoice.invoice_type == 'logistics').reduce((sum, invoice) => sum + (Number(invoice.price) || 0), 0) : 0;
                                            var sellerIds = [...new Set(order.logistics_orders.map(order => order.seller_id))];;
                                            var totalPrice = logisticsPrice;
                                            var walletAmount = 0;
                                            if (order.order_items.length > 0) {
                                                order.order_items.map((item) => {
                                                    totalPrice = totalPrice + parseFloat(item.order_price);
                                                    walletAmount = walletAmount + parseInt(item.wallet_amount);
                                                    if (!sellerIds.includes(parseInt(item.seller_id))) {
                                                        sellerIds.push(parseInt(item.seller_id));
                                                    }
                                                });
                                            }
                                            const isLastElement = index === orders.length - 1;

                                            return (
                                                <div className="card userordercard1"
                                                key={order.order_id}
                                                ref={isLastElement ? lastOrderElementRef : null}
                                                >
                                                    <div className="card-header">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <p className="text-uppercase text-muted userordercardheader1">order time</p>
                                                                <p className="text-uppercase text-muted userordercardheader1">{new Date(order.created_at).toString()}</p>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <p className="text-uppercase text-muted userordercardheader1">Order Price</p>
                                                                <p className="text-uppercase text-muted userordercardheader1">{parseValue(Number(totalPrice) - Number(logisticsPrice), 'float')}</p><br />
                                                                <p className="text-uppercase text-muted userordercardheader1">Used From Wallet</p>
                                                                <p className="text-uppercase text-muted userordercardheader1">{parseValue(walletAmount, 'float')}</p><br />
                                                                <p className="text-uppercase text-muted userordercardheader1">Logistics Price</p>
                                                                <p className="text-uppercase text-muted userordercardheader1">{parseValue(logisticsPrice, 'float')}</p><br />
                                                                <p className="text-uppercase text-muted userordercardheader1">Total Price</p>
                                                                <p className="text-uppercase text-muted userordercardheader1">{parseValue(totalPrice, 'float')}</p>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <p className="text-uppercase text-muted userordercardheader1 float-right">Order No - {order.order_id}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                {
                                                                    order.order_items.map((orderItems) => {
                                                                        return (
                                                                            <div>
                                                                                <div className='row' style={{ cursor: 'pointer' }}>
                                                                                    <div className="col-md-4">
                                                                                        {orderItems.product_item ? (
                                                                                            <Link
                                                                                                className="text-white"
                                                                                                to={`/product/${orderItems.product_item.product?.id}/${orderItems.product_item.id}`}
                                                                                            >
                                                                                                <img
                                                                                                    className="userorderpageimg1"
                                                                                                    src={
                                                                                                        orderItems.product_item.product_image ? envConfig.api_url + orderItems.product_item.product_image.split(',')[0] :
                                                                                                            envConfig.api_url + "/images/offline_shopping.jpeg"
                                                                                                    }
                                                                                                    alt="product"
                                                                                                />
                                                                                            </Link>
                                                                                        ) : orderItems.seller_service ? (
                                                                                            <Link
                                                                                                className="text-white"
                                                                                                to={`/service/details/${orderItems.seller_service.service_category}`}
                                                                                            >
                                                                                                <img
                                                                                                    className="userorderpageimg1"
                                                                                                    src={envConfig.api_url + orderItems.seller_service.service_image}
                                                                                                    alt="service"
                                                                                                />
                                                                                            </Link>
                                                                                        ) : (
                                                                                            <img
                                                                                                className="userorderpageimg1"
                                                                                                src={envConfig.api_url + "/images/offline_shopping.jpeg"}
                                                                                                alt="offline"
                                                                                            />
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="col-md-8">
                                                                                        <p className="text-muted userordercardheader1">Product Name: {orderItems.product_item?.product?.name || orderItems.seller_service?.service_name || orderItems?.offline_sale_product_datum?.product_description}</p>
                                                                                        <p className="text-muted userordercardheader1">Quantity: {orderItems.order_quantity}</p>
                                                                                        <p className="text-danger userordercardheader1 ">Total Price: <i className="fas fa-rupee-sign">{orderItems.order_price}</i></p>
                                                                                    </div>

                                                                                </div>
                                                                                <button onClick={() => handleShow(orderItems.id, orderItems.seller_id)} className='btn btn-outline-success w-50 btn-sm usercardbtn1'>Give feedback</button>
                                                                                <button onClick={() => handleShowQuery(orderItems.id, orderItems.seller_id)} className='btn btn-outline-danger w-50 btn-sm usercardbtn1'>Any Query?</button>
                                                                                <button onClick={() => showOrderProgressModal(orderItems)} className='btn btn-outline-warning w-50 btn-sm usercardbtn1'>Tracking & Update</button>
                                                                                {orderItems.order_returned_on ?
                                                                                    <button
                                                                                        onClick={() => generateReturnReplaceCancelInvoice(orderItems.id, order.user_id, ['return_order', 'return_order_logistics_user'], 'Return')}
                                                                                        className='btn btn-outline-warning w-50 btn-sm usercardbtn1'
                                                                                    >
                                                                                        Return Invoice
                                                                                    </button> : null
                                                                                }
                                                                                {/* {
                                                                                    orderItems.logistics_vendor_id && orderItems.order_replaced_on ?
                                                                                        <button
                                                                                            onClick={() => generateReturnReplaceCancelInvoice(orderItems.order_id, sellerIds, ['replace_order_seller', 'replace_order_logistics'], 'Replace')}
                                                                                            className='btn btn-outline-warning w-50 btn-sm usercardbtn1'
                                                                                        >
                                                                                            Replace Invoice
                                                                                        </button>
                                                                                        : null
                                                                                } */}
                                                                                {
                                                                                    orderItems.order_cancelled_on ?
                                                                                        <button
                                                                                            onClick={() => generateReturnReplaceCancelInvoice(orderItems.id, order.user_id, ['cancel_order', 'cancel_order_logistics_user'], 'Cancel')}
                                                                                            className='btn btn-outline-warning w-50 btn-sm usercardbtn1'
                                                                                        >
                                                                                            Cancel Invoice
                                                                                        </button>
                                                                                        : null
                                                                                }
                                                                            </div>


                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="col-md-6 d-flex flex-column">
                                                                <button className="btn btn-outline-success w-50 btn-sm usercardbtn1" onClick={() => generateInvoice(order.id, sellerIds)}>Invoice Generate</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                        {loading && (
                                                        <div className="loading-indicator text-center my-3">
                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                        )}
                            </div>
                            <div className="tab-pane fade" id="Buy Again" role="tabpanel" aria-labelledby="Buy Again-tab">Seller Score is calculated by TKGHUB based on customer feedback and seller's performance...</div>
                            <div className="tab-pane fade" id="Open Order" role="tabpanel" aria-labelledby="Open Order-tab">Seller Score is calculated by TKGHUB based on customer feedback and seller's performance...</div>
                            <div className="tab-pane fade" id="Cancelled Order" role="tabpanel" aria-labelledby="Cancelled Order-tab">Seller Score is calculated by TKGHUB based on customer feedback and seller's performance...</div>
                        </div>
                    </div>
                </div>


                <Modal show={show} onHide={handleClose} >
                    <Modal.Header closeButton >
                        <Modal.Title>Give your feedback here</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <Label for="feedback_type">Select type</Label>
                            <select id="feedback_type">
                                <option value="0">Select</option>
                                {
                                    feedbackType.map((type) => {
                                        return (
                                            <>
                                                <option>{type}</option>
                                            </>
                                        )
                                    })
                                }
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <Label for="newEntry">Enter a feedback</Label>
                            <Input type='text' id="feedback" />
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='submit' onClick={sendFeedBack}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={shows} onHide={handleCloses} >
                    <Modal.Header closeButton >
                        <Modal.Title>Right your Query</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <Label for="Query_type">Select type</Label>
                            <select id="Query_type">
                                <option value="0">Select</option>
                                {
                                    feedbackType.map((type) => {
                                        return (
                                            <>
                                                <option>{type}</option>
                                            </>
                                        )
                                    })
                                }
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <Label for="newEntry">Enter Query </Label>
                            <Input type='text' id="query" />
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='submit' onClick={sendQuery}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={orderProgressModal} onHide={() => showOrderProgressModal([])} size='lg'>
                    <Modal.Header closeButton >
                        <Modal.Title>Order Progress</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className="col-md-6 d-flex flex-column">
                                <div class="order-track">
                                    {
                                        orderItemsData.order_trackings ? orderItemsData.order_trackings.map((track) => {
                                            if (track.status == 'cancel_requested') {
                                                return (
                                                    <div class="order-track-step">
                                                        <div class="order-track-status">
                                                            <span class="order-track-status-dot"></span>
                                                            <span class="order-track-status-line"></span>
                                                        </div>
                                                        <div class="order-track-text">
                                                            <p class="order-track-text-stat">{track.remarks.replaceAll("_", " ")}</p>
                                                            <span class="order-track-text-sub">{track.created_at}</span>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div class="order-track-step">
                                                        <div class="order-track-status">
                                                            <span class="order-track-status-dot"></span>
                                                            <span class="order-track-status-line"></span>
                                                        </div>
                                                        <div class="order-track-text">
                                                            <p class="order-track-text-stat">{track.remarks.replaceAll("_", " ")}</p>
                                                            <span class="order-track-text-sub">{track.created_at}</span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }) : ""
                                    }
                                </div>
                            </div>
                            <div className="col-md-6 d-flex flex-column">
                                {
                                    showCancel.includes(orderItemsData.order_status) && orderItemsData.no_return != 1 ?
                                        <button className="btn btn-outline-danger w-50 btn-sm usercardbtn1" onClick={() => showReasonModal(orderItemsData.id, 'cancelled', 'cancel', orderItemsData?.product_item?.self_deliver, orderItemsData?.product_item?.product?.seller_address_gst)}>Cancel Order</button>
                                        : ''
                                }
                                {
                                    showReturn.includes(orderItemsData.order_status) && orderItemsData.service_id == 0 && orderItemsData.offline_sale_id == 0 && orderItemsData.no_return != 1 && !orderItemsData.order_returned_on ?
                                        <button className="btn btn-outline-danger w-50 btn-sm usercardbtn1" onClick={() => showReasonModal(orderItemsData.id, 'return_requested', 'return',  orderItemsData?.product_item?.self_deliver, orderItemsData?.product_item?.product?.seller_address_gst)}>Return Order</button>
                                        : ''
                                }
                                {
                                    showReplace.includes(orderItemsData.order_status) && orderItemsData.service_id == 0 && orderItemsData.offline_sale_id == 0 && orderItemsData.no_return != 1 && !orderItemsData.order_replaced_on ?
                                        <button className="btn btn-outline-warning w-50 btn-sm usercardbtn1" onClick={() => showReasonModal(orderItemsData.id, 'replace_requested', 'replace',  orderItemsData?.product_item?.self_deliver, orderItemsData?.product_item?.product?.seller_address_gst)}>Replace Order</button>
                                        : ""
                                }
                                {
                                    orderItemsData.order_status == 'delivered' || orderItemsData.order_status == 'replaced' ?
                                        <>
                                            <br />
                                            <label>Satisfied with the item ? No Return ?</label>
                                            <select className='form-control' onChange={(e) => updateNoReturn(e, orderItemsData.id)} defaultValue={orderItemsData.no_return} disabled={orderItemsData.no_return != -1 ? true : false}>
                                                <option value={-1}>Please Select One Option</option>
                                                <option value={1}>Yes</option>
                                            </select></> : ""
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={showReasons} onHide={() => setShowReasons(!showReasons)}>
                    <Modal.Header closeButton >
                        <Modal.Title>Update Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <select id="cancel_return_replace_reason" className='form-control' onClick={(e) => setCancelReturnReplaceReason(e)}>
                            <option value="">Select {updateStatus.type} Reason</option>
                            {
                                updateStatus && updateStatus.reasons && updateStatus.reasons.map((value) => {
                                    return (
                                        <>
                                            <option value={value}>{value}</option>
                                        </>
                                    )
                                })
                            }
                            <option value="other">Other</option>
                        </select>
                        <br />
                        {
                            showOtherReasonBox ?
                                <input type='text' placeholder="Enter Reason" className='form-control' id="other_reason" />
                                : null
                            }
                            <br/>
                            <input type='file' id="cancel_return_replace_photos" onChange={(e) => uploadCancelReplaceReturnImages(e)} multiple/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='button' onClick={() => changeOrderStatusOperations(updateStatus.order_id, updateStatus.order_status, updateStatus.type, updateStatus.self_deliver, updateStatus.seller_address_id)}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal>
            </div>
        </React.Fragment>
    )
}
export default YourOrdersView;