import React, { useState } from 'react';
import { Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Menu } from 'lucide-react';

import './ssidebar.css'; 

const Sidebar = () => {
  const [expanded, setExpanded] = useState(false);
  const [reportsExpanded, setReportsExpanded] = useState(false);

  const toggleSidebar = () => setExpanded(!expanded);
  const toggleReports = () => setReportsExpanded(!reportsExpanded);

  const handleLinkClick = () => {
    setExpanded(false); 
  };

  return (
    <>
      <Button
        variant="link"
        className="toggle-button fixed-toggle"
        onClick={toggleSidebar}
      >
        <Menu size={30} />
      </Button>

      <div className={`sidebar ${expanded ? 'expanded' : 'collapsed'}`}>
        <div className="sidebarWrapper">
          <Nav className="flex-column w-100">
            <Nav.Link as={Link} to="/SHome" onClick={handleLinkClick}>
              <i className="fas fa-chart-line mr-2"></i>
              Dashboard
            </Nav.Link>
            <Nav.Link as={Link} to="seller/products" onClick={handleLinkClick}>
              <i className="fas fa-box mr-2"></i>
              Products
            </Nav.Link>
            <Nav.Link as={Link} to="seller/StockInfo" onClick={handleLinkClick}>
              <i className="fas fa-warehouse mr-2"></i>
              Stock Info
            </Nav.Link>
            <Nav.Link as={Link} to="seller/offline-sale" onClick={handleLinkClick}>
              <i className="fas fa-store mr-2"></i>
              Offline Sales
            </Nav.Link>
            <Nav.Link as={Link} to="seller/orders" onClick={handleLinkClick}>
              <i className="fas fa-shopping-cart mr-2"></i>
              Orders
            </Nav.Link>
            <Nav.Link as={Link} to="seller/logistics-orders" onClick={handleLinkClick}>
              <i className="fas fa-shopping-cart mr-2"></i>
              Logistics Orders
            </Nav.Link>
            <Nav.Link as={Link} to="seller/add-services" onClick={handleLinkClick}>
              <i className="fas fa-plus-circle mr-2"></i>
              Add Services
            </Nav.Link>
            <Nav.Link as={Link} to="seller/services" onClick={handleLinkClick}>
              <i className="fas fa-concierge-bell mr-2"></i>
              Services
            </Nav.Link>
            <Nav.Link as={Link} to="seller/delivery-users" onClick={handleLinkClick}>
              <i className="fas fa-truck mr-2"></i>
              Delivery Users
            </Nav.Link>
            <Nav.Link as={Link} to="seller/Seller_Address" onClick={handleLinkClick}>
              <i className="fas fa-map-marker-alt mr-2"></i>
              Seller Address
            </Nav.Link>
            <Nav.Link as={Link} to="seller/SellerInfo" onClick={handleLinkClick}>
              <i className="fas fa-info-circle mr-2"></i>
              Seller Info
            </Nav.Link>
            <Nav.Link as={Link} to="seller/SellerReplyFeedback" onClick={handleLinkClick}>
              <i className="fas fa-comment-alt mr-2"></i>
              Seller Feedback Reply
            </Nav.Link>
            <Nav.Link as={Link} to="seller/SellerReplyQuery" onClick={handleLinkClick}>
              <i className="fas fa-question-circle mr-2"></i>
              Seller Query Reply
            </Nav.Link>
            <Nav.Link as={Link} to="seller/SellerQr" onClick={handleLinkClick}>
              <i className="fas fa-qrcode mr-2"></i>
              Seller QR Code
            </Nav.Link>

            <Nav.Item>
              <Button
                variant="link"
                className="nav-link"
                onClick={toggleReports}
                aria-controls="reports-nav"
                aria-expanded={reportsExpanded}
              >
                <i className="fas fa-file-alt mr-2"></i>
                Reports
                <i className={`fas fa-caret-${reportsExpanded ? 'up' : 'down'} ml-2`}></i>
              </Button>
              <Nav className={`flex-column ${reportsExpanded ? '' : 'collapse'}`} id="reports-nav">
                <Nav.Link as={Link} to="seller/reports/payment-report" onClick={handleLinkClick}>
                  Payment Report
                </Nav.Link>
                <Nav.Link as={Link} to="seller/reports/bank-report" onClick={handleLinkClick}>
                  Bank Report
                </Nav.Link>
                <Nav.Link as={Link} to="seller/reports/receipt-report" onClick={handleLinkClick}>
                  Receipt Report
                </Nav.Link>
                {/* <Nav.Link as={Link} to="seller/reports/vendor-services-details" onClick={handleLinkClick}>
                  Service Report
                </Nav.Link> */}
                <Nav.Link as={Link} to="seller/reports/vendor-report" onClick={handleLinkClick}>
                  Vendor Report
                </Nav.Link>
                {/* <Nav.Link as={Link} to="seller/reports/logistic-report" onClick={handleLinkClick}>
                  Logistics Report
                </Nav.Link> */}
              </Nav>
            </Nav.Item>
          </Nav>
        </div>
      </div>

      {expanded && <div className="overlay" onClick={toggleSidebar}></div>}
    </>
  );
};

export default Sidebar;
