import React, { useEffect, useState } from 'react';

const TOOL_VERSION = 1.1; // Current version of the tool that you want to check against

const AutoReload = () => {

  useEffect(() => {
    // Get the stored version from localStorage
    const storedVersion = parseFloat(localStorage.getItem('toolVersion'));

    // If the stored version is less than the current version, force reload
    if (storedVersion && storedVersion < TOOL_VERSION) {
      console.log(`Stored version (${storedVersion}) is less than current version (${TOOL_VERSION}). Reloading...`);
      localStorage.setItem('toolVersion', TOOL_VERSION);
      window.location.href = window.location.href;
    } else if (!storedVersion) {
      // If no version is stored, set the current version in localStorage
      console.log('No version in localStorage. Setting current version and reload.');
      localStorage.setItem('toolVersion', TOOL_VERSION);
      window.location.href = window.location.href;
    } else {
      console.log('Tool version is up-to-date.');
    }
  }, []);

  return null; // This component doesn't render anything
};

export default AutoReload;
