// src/redux/cartSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    count: 0
  },
  reducers: {
    setCartItems: (state, action) => {
      state.items = action.payload;
      state.count = action.payload.length;
    },
    addToCartRedux: (state, action) => {
      state.items.push(action.payload);
      state.count = state.items.length;
    },
    removeFromCartRedux: (state, action) => {
      state.items = state.items.filter(item => item.product_item_id !== action.payload);
      state.count = state.items.length;
    },
    addToCartServiceRedux: (state, action) => {
      state.items.push(action.payload);
      state.count = state.items.length;
    },
    removeFromCartServiceRedux: (state, action) => {
      state.items = state.items.filter(item => item.service_id !== action.payload);
      state.count = state.items.length;
    }
  }
});

export const { setCartItems, addToCartRedux, removeFromCartRedux,addToCartServiceRedux,removeFromCartServiceRedux } = cartSlice.actions;
// Export the reducer as default
export default cartSlice.reducer;