import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import './service.css';
import $ from 'jquery';
import ServiceCardView from '../servicecard/ServiceCardView';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

function ServiceView() {
    const [categories, setCategories] = useState([]);
    const [mainCategories, setMainCategories] = useState([]);
    const [isSubcategory, setIsSubcategory] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const fetchCategories = async (parentId = 0) => {
        const data = {
            action: 'read',
            source: 'web',
            parent_id: parentId,
        };
        try {
            let response = await common.apiRequest('post', '/admin/crud-seller-service-category', data);
            if (response.data.code === 'success') {
                if (parentId === 0) {
                    setMainCategories(response.data.data);
                    setIsSubcategory(false);
                } else {
                    setCategories(response.data.data);
                    setIsSubcategory(true);
                }
                // setIsSubcategory(parentId !== 0);
            } else {
                common.alert(response.data.error, 'error');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const searchService = () => {
        let searchString = $('#service_search').val().trim();
        if (searchString.length === 0) {
            common.alert('Enter Something To Search', 'error');
            return;
        }
        navigate('/service/0', { state: searchString });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchService();
        }
    };

    const handleCategoryClick = (categoryId) => {
        if (!isSubcategory) {
            fetchCategories(categoryId);
            navigate(`/service/category/${categoryId}`);
        }
    };

    const handleBackClick = () => {
        fetchCategories(0);
        navigate('/service');
    };


    useEffect(() => {
        if (id) {
            fetchCategories(parseInt(id));
        } else {
            fetchCategories(0);
        }
    }, [id, location.pathname]);

    return (
        <React.Fragment>
            <div className="container-fluid px-0">
                <div className="col-12 servicep1">
                    <div className="col-md-12 text-box">
                        <div className="form-inline justify-content-center">
                            <input
                                type="text"
                                className="form-control"
                                size="70"
                                id="service_search"
                                placeholder="Search For Services"
                                onKeyDown={handleKeyDown}
                            />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-success" onClick={searchService}>
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
    <div className="d-flex justify-content-center">
        <div className="card homescard1 shadow p-3 mb-5 bg-white rounded">
            <div className="card-body">
                {isSubcategory && (
                    <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
                        Back to Main Categories
                    </button>
                )}

                <div className="row d-flex justify-content-center">
                    {!isSubcategory &&
                        mainCategories.map((category) => (
                            <div className="grid_item" key={category.id}>
                                <div className="btn" onClick={() => handleCategoryClick(category.id)}>
                                    <div className="card cardhs1" style={{ border: 'none' }}>
                                        <div className="card-body">
                                            <div className="card-title service-back-title text-center">
                                                <img
                                                    src={envConfig.api_url + category.service_categories_image}
                                                    className="img1"
                                                    alt={"img"}
                                                />
                                                <h5 className="text-wrap">{category.category_name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    {isSubcategory &&
                        categories.map((subcategory) => (
                            subcategory.seller_service && (
                            <div className="grid_item" key={subcategory.id}>
                                <Link
                                    to={`/service/list/${subcategory.id}`}
                                    className="btn"
                                    state={{ previousPath: `/service/category/${id}` }}
                                >
                                    <div className="btn" onClick={() => handleCategoryClick(subcategory.id)}>
                                        <div className="card cardhs1" style={{ border: 'none' }}>
                                            <div className="card-body service-back-title text-center">
                                                <div className="card-title">
                                                    <h5 className="text-wrap">{subcategory.category_name}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            )
                        )).filter(Boolean)
                        }
                </div>
            </div>
        </div>
    </div>
</div>

            </div>
        </React.Fragment>
    );
}

export default ServiceView;
