import React, { Component } from 'react';
const axios = require('axios');
const envConfig = require('../config/envConfig');

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.logErrorToServer(error, errorInfo);

    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  logErrorToServer(error, errorInfo) {
    // Construct the error object
    const errorData = {
      message: error.toString(),
      stack: error.stack,
      componentStack: errorInfo.componentStack
    };

    // Send the error data to your server
    fetch(envConfig.api_url + '/admin/log-js-error', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(errorData),
    });
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <h1 style={{ fontSize: '2rem', marginBottom: '1rem' }}>Oops! Something went wrong.</h1>
            <p>We're sorry for the inconvenience. Please try again later.</p>
            <button onClick={() => window.location.reload()} style={{ padding: '10px 20px', cursor: 'pointer' }}>
              Refresh Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children; // Render children if no error occurred
  }
}

export default ErrorBoundary;
